import React, { useState } from 'react';
import _, { capitalize, isEmpty } from 'lodash';
import userService from '../user.service.js';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import medsIcon from '../images/drugs.png';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  paper: {
    background: '#FFFFFF',
    width: '460px',
    padding: '24px 32px 24px 32px',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      padding: '14px 16px 14px 16px',
      width: 'auto',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 800,
  },
  closeIcon: {
    cursor: 'pointer',
  },

  mainContainer: {
    marginTop: '20px',
    width: '100%',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '20px',
    },
  },
  mainText: {
    fontSize: '24px',
    fontWeight: 800,
    marginBottom: '8px',
    textAlign: 'center',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '18px',
    },
  },
  explanationText: {
    width: '450px',
    fontSize: '16px',
    textAlign: 'center',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      width: 'auto',
    },
  },
  imageContainer: {
    width: '180px',
    marginBottom: '30px',
    marginTop: '30px',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      marginBottom: '15px',
      marginTop: '15px',
    },
  },
  image: {
    width: '100%',
  },

  pinInput: {
    background: '#FFFFFF',
    border: '1px solid #C2C8D6',
    boxSizing: 'border-box',
    height: '50px',
    boxShadow: 'inset 0px 2px 2px rgba(108, 131, 145, 0.19)',
    borderRadius: '2px',
    marginTop: '8px',
    width: '468px',
    fontSize: '16px',
    paddingInline: '10px',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  userInfoPartRadio: {
    marginTop: '8px',
  },
  userInfoPart: {
    marginTop: '20px',
  },

  errorMessage: {
    marginTop: '15px',
    marginBottom: '8px',
    color: '#fff',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    background: '#e0848c',
    borderRadius: '10px',
  },

  footer: {
    marginTop: '40px',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '20px',
    },
  },
  saveButton: {
    marginLeft: '8px',
  },

  grayText: {
    color: '#61697D',
  },
  weekDays: {
    paddingInline: '15px',
    paddingBlock: '10px',
    borderRadius: '50%',
    cursor: 'pointer',
    marginLeft: '10px',
    border: '0.5px solid grey',
  },
  red: {
    color: 'red',
  },
  addTimeButton: {
    padding: '10px',
    background: '#6F42C1',
    borderRadius: '20px',
    marginTop: '10px',
    width: '70px',
    color: '#fff',
    cursor: 'pointer',
  },
  startTime: {
    display: 'flex',
  },
  timeInput: {
    background: '#FFFFFF',
    border: '1px solid #C2C8D6',
    boxSizing: 'border-box',
    height: '50px',
    boxShadow: 'inset 0px 2px 2px rgba(108, 131, 145, 0.19)',
    borderRadius: '2px',
    marginTop: '8px',
    width: '250px',
    fontSize: '16px',
    paddingInline: '10px',
    marginLeft: '10px',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
});

const useStyles = makeStyles(styles);

const DAY_FREQUENCY = Array.from({ length: 50 }, (_, index) => index + 1);

const WEEK_DAYS = [
  { name: 'Sun' },
  { name: 'Mon' },
  { name: 'Tue' },
  { name: 'Wed' },
  { name: 'Thur' },
  { name: 'Fri' },
  { name: 'Sat' },
];

function AddMedication({ refresh, closeModal, setShowMedicationTable }) {
  const { t } = useTranslation();

  const MEDICATION_TYPE = [
    { name: t('capsule'), unit: 'capsule' },
    { name: t('tablet'), unit: 'tablet' },
    { name: t('liquid'), unit: 'ml' },
    { name: t('topical'), unit: 'application' },
    { name: t('cream'), unit: 'application' },
    { name: t('device'), unit: 'device' },
    { name: t('drops'), unit: 'drop' },
    { name: t('foam'), unit: 'application' },
    { name: t('gel'), unit: 'application' },
    { name: t('inhaler'), unit: 'inhalation' },
    { name: t('injection'), unit: 'injection' },
    { name: t('lotion'), unit: 'application' },
    { name: t('oinment'), unit: 'application' },
    { name: t('patch'), unit: 'patch' },
    { name: t('powder'), unit: 'scoop' },
    { name: t('spray'), unit: 'spray' },
    { name: t('suppository'), unit: 'unit' },
  ];

  const MEDICATION_FREQUENCY = [
    { name: t('at_regular_interval'), value: 1 },
    { name: t('on_specific_day_of_week'), value: 2 },
  ];

  const classes = useStyles();
  const [state, setState] = useState({
    name: null,
    type: null,
    frequency: null,
    dayFrequency: 1,
    weekDayFrequency: [],
    startDate: null,
    timeFrequency: [{ time: '06:00', quantity: 1 }],
    errorMessage: '',
    unit: '',
    endDate: null,
  });
  const [loader, setLoader] = useState(false);

  function getTimeZoneOffset() {
    const now = new Date();
    const timeZoneOffsetMinutes = now.getTimezoneOffset();
    const offsetHours = timeZoneOffsetMinutes / -60;

    const offsetSign = offsetHours >= 0 ? '+' : '-';
    const offsetString = Math.abs(offsetHours).toString().padStart(2, '0');

    return `GMT${offsetSign}${offsetString}`;
  }

  const AddMedications = async (reqData) => {
    const authData = userService.getAuthData();
    setLoader(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/medications`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: authData.accessToken,
        },
        body: JSON.stringify(reqData),
      });
      if (res.status !== 200) {
        throw new Error('Something went wrong!');
      }

      setState((prev) => {
        return { ...prev, errorMessage: '' };
      });

      refresh();
      closeModal();
      return await res.json();
    } catch (err) {
      setState({
        errorMessage: err.message || 'Something went wrong!',
      });
      console.log(err);
    }
    setLoader(false);
  };

  const handleSetWeekDays = (name) => {
    if (state.weekDayFrequency.includes(name)) {
      setState((prev) => {
        return {
          ...prev,
          weekDayFrequency: prev.weekDayFrequency.filter((item) => item !== name),
        };
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          weekDayFrequency: [...prev.weekDayFrequency, name],
        };
      });
    }
  };

  const handleDeleteStartTime = (index) => {
    setState((prev) => {
      return { ...prev, timeFrequency: prev.timeFrequency.filter((_, idx) => idx !== index) };
    });
  };

  const handleAddMeds = async (e) => {
    e.preventDefault();
    let { name, type, frequency, dayFrequency, weekDayFrequency, startDate, timeFrequency, endDate } = state;

    const nameCond = isEmpty(name);
    const typeCond = isEmpty(type);
    const frequencyCond = isEmpty(frequency);
    const subFrequencyCond = frequency === '1' ? isEmpty(dayFrequency) : isEmpty(weekDayFrequency);
    const startDateCond = isEmpty(startDate);
    const endDateCond = isEmpty(endDate);
    const startTimeCond = isEmpty(timeFrequency);

    if (nameCond || typeCond || frequencyCond || subFrequencyCond || startDateCond || startTimeCond || endDateCond) {
      setState((prev) => {
        return { ...prev, errorMessage: 'Fill all the fields' };
      });
    } else {
      await AddMedications({
        name,
        type,
        frequency: parseInt(frequency),
        dayFrequency: parseInt(dayFrequency),
        weekDayFrequency,
        startDate,
        timeFrequency,
        timezone: getTimeZoneOffset(),
        endDate,
      });
      console.log('state====>', {
        name,
        type,
        frequency,
        dayFrequency,
        weekDayFrequency,
        startDate,
        timeFrequency,
        timezone: getTimeZoneOffset(),
        endDate,
      });
    }
  };

  return (
    <div className={classes.paper}>
      <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
        <Grid item className={classes.title}>
          {t('add_a_new_medication')}
        </Grid>
        <Grid item className={classes.closeIcon} onClick={closeModal}>
          <CloseIcon />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.mainContainer}
      >
        <Grid item container justify="center">
          <img className={classes.imageContainer} src={medsIcon} alt="Cane front panel" />
        </Grid>
        <Grid item className={classes.userInfoPart}>
          <div className={classes.pinText}>
            {t('medication_name')} <span className={classes.red}>*</span>
          </div>
          <input
            className={classes.pinInput}
            onChange={(event) =>
              setState((prev) => {
                return { ...prev, name: event.target.value };
              })
            }
            placeholder={t('medication_name')}
          />
        </Grid>
        <Grid item className={classes.userInfoPart}>
          <div className={classes.pinText}>
            {t('medication_type')} <span className={classes.red}>*</span>
          </div>
          <select
            className={classes.pinInput}
            onChange={(event) => {
              setState((prev) => {
                return {
                  ...prev,
                  type: event.target.value,
                  unit: MEDICATION_TYPE.find(({ name }) => name === event.target.value)?.unit || '',
                };
              });
            }}
          >
            <option value="">-- {t('select_type')} --</option>
            {MEDICATION_TYPE.map(({ name }) => (
              <option value={name}>{capitalize(name)}</option>
            ))}
          </select>
        </Grid>
        <Grid item className={classes.userInfoPart}>
          <div className={classes.pinText}>
            {t('frequency')} <span className={classes.red}>*</span>
          </div>
          <select
            className={classes.pinInput}
            onChange={(event) =>
              setState((prev) => {
                return { ...prev, frequency: event.target.value };
              })
            }
          >
            <option value="">-- {t('select_frequency')} --</option>
            {MEDICATION_FREQUENCY.map(({ name, value }) => (
              <option value={value}>{capitalize(name)}</option>
            ))}
          </select>
        </Grid>

        {state?.frequency === '1' && (
          <Grid item className={classes.userInfoPart}>
            <div className={classes.pinText}>{t('every')}</div>
            <select
              className={classes.pinInput}
              onChange={(event) =>
                setState((prev) => {
                  return { ...prev, dayFrequency: event.target.value };
                })
              }
            >
              {DAY_FREQUENCY.map((item) => (
                <option value={item}>
                  {item === 1 ? t('day') : item === 2 ? t('other_day') : `${item} ${t('days')}`}
                </option>
              ))}
            </select>
          </Grid>
        )}

        {state?.frequency === '2' && (
          <Grid item style={{ marginTop: '20px' }}>
            <div className={classes.pinText}>
              {t('choose_days')} <span className={classes.red}>*</span>
            </div>

            <div style={{ marginTop: '20px' }}>
              {WEEK_DAYS.map(({ name }) => (
                <span
                  className={classes.weekDays}
                  style={{
                    color: state.weekDayFrequency.includes(name) ? '#fff' : 'black',
                    background: state.weekDayFrequency.includes(name) ? '#0C6EFD' : '#fff',
                  }}
                  onClick={() => handleSetWeekDays(name)}
                >
                  {name.charAt(0)}
                </span>
              ))}
            </div>
          </Grid>
        )}
      </Grid>

      {state.frequency && (
        <Grid item className={classes.userInfoPart}>
          <div className={classes.pinText}>
            {t('start_date')} <span className={classes.red}>*</span>
          </div>
          <input
            className={classes.pinInput}
            onChange={(event) =>
              setState((prev) => {
                return { ...prev, startDate: event.target.value };
              })
            }
            type="date"
            min={moment().format('YYYY-MM-DD')}
            value={state.startDate}
          />
        </Grid>
      )}

      {state.frequency && (
        <Grid item className={classes.userInfoPart}>
          <div className={classes.pinText}>
            {t('end_date')} <span className={classes.red}>*</span>
          </div>
          <input
            className={classes.pinInput}
            onChange={(event) =>
              setState((prev) => {
                return { ...prev, endDate: event.target.value };
              })
            }
            type="date"
            min={moment(state.startDate).format('YYYY-MM-DD')}
            value={state.endDate}
          />
        </Grid>
      )}
      {state.frequency && (
        <Grid item className={classes.userInfoPart}>
          <div className={classes.pinText}>
            {t('time_of_the_day')} <span className={classes.red}>*</span>
          </div>
          {state?.timeFrequency.map(({ time, quantity }, idx) => (
            <div className={classes.startTime}>
              <div>
                <input
                  className={classes.timeInput}
                  onChange={(e) =>
                    setState((prev) => {
                      const updatedStartTime = [...prev.timeFrequency];
                      updatedStartTime[idx].time = e.target.value;
                      return {
                        ...prev,
                        timeFrequency: updatedStartTime,
                      };
                    })
                  }
                  type="time"
                  value={time}
                />
              </div>
              <div>
                <input
                  type={'number'}
                  className={classes.timeInput}
                  value={quantity}
                  style={{ width: '70px' }}
                  onChange={(e) =>
                    setState((prev) => {
                      const updatedStartTime = [...prev.timeFrequency];
                      updatedStartTime[idx].quantity = e.target.value;
                      return {
                        ...prev,
                        timeFrequency: updatedStartTime,
                      };
                    })
                  }
                />
                <span style={{ marginLeft: '10px', fontSize: '18px' }}>{state?.unit || t('no_unit')}</span>
              </div>
              {idx !== 0 && (
                <div style={{ alignItems: 'center', display: 'flex', padding: '10px' }}>
                  <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDeleteStartTime(idx)} />
                </div>
              )}
            </div>
          ))}
          <div
            className={classes.addTimeButton}
            onClick={() =>
              setState((prev) => {
                return { ...prev, timeFrequency: [...prev.timeFrequency, { time: '', quantity: 1 }] };
              })
            }
          >
            {t('add_time')}
          </div>
        </Grid>
      )}

      {state.errorMessage && (
        <Grid item direction="column" justify="flex-start" alignItems="center" className={classes.errorMessage}>
          {state.errorMessage}
        </Grid>
      )}
      <Grid container item direction="row" justify="flex-end" alignItems="center" className={classes.footer}>
        <Grid item>
          <Button fullWidth variant="outlined" className={classes.cancelButton} onClick={closeModal}>
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item style={{ marginRight: '8px' }}>
          <Button
            fullWidth
            variant="contained"
            className={classes.saveButton}
            color="secondary"
            onClick={setShowMedicationTable}
          >
            {t('show_medication')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            className={classes.saveButton}
            disabled={loader}
            onClick={handleAddMeds}
          >
            {t('add')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddMedication;

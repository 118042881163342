import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import Device from '../Main/Device';
import styles from '../Main/main.styles.js';
import AddDevice from '../Main/AddDevice';
import { Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import LinearChart from './components/gaitSpeed/barChart';
import { useHistory } from 'react-router-dom';
import App from './components/gaitSpeed/spineChart';
import BarChart from './components/gaitSpeed/barChart';
import LoadRate from './components/loadRate/loadRate';
import { useParams } from 'react-router-dom';
import userService from '../user.service';
import UserInfo from '../Main/UserInfo';
import { capitalize } from 'lodash';

const useStyles = makeStyles(styles);
const DeviceDetail = () => {
  const history = useHistory();
  const { deviceId } = useParams();
  function refresh() {
    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/userInfo`, {
      method: 'GET',
      headers: {
        authorization: authData.accessToken + '~123123',
      },
    })
      .then((res) => res.json())
      .then(({ data: userData }) => {
        if (!userData.error) {
          userService.updateCurrentUser(userData);
          user = userData;

          setState((prevState) => {
            return { ...prevState, stateUpdates: (state.stateUpdates += 1) };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function logout() {
    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: authData.accessToken,
      },
    })
      .then((res) => {
        if (res.status !== 204) {
          throw new Error('Logout failed');
        }
      })
      .then(() => {
        userService.clearUserData();
        history.push('/login');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let authData = userService.getAuthData();
  let currentUser = userService.getCurrentUser();
  let user = null;
  if (!currentUser) {
    history.push('/login');
  } else {
    user = currentUser;
  }

  const classes = useStyles();
  function getDevice(deviceId) {
    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/devices/${deviceId}/getDeviceInfo`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('auth'),
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Couldn't get device");
        }

        return res.json();
      })
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getLastLocation(deviceId) {
    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/devices/${deviceId}/getLastLocation`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('auth'),
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Couldn't get device");
        }

        return res.json();
      })
      .then((data) => {
        console.log('response:');
        console.log(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [device, setDevice] = React.useState({});
  const [state, setState] = useState({
    stateUpdates: 0,
    showOnboarding: !localStorage['notFirstVisit'],
  });
  const [lastLocation, setLastLocation] = React.useState([]);
  React.useEffect(() => {
    if (user) {
      console.log('user at ', user);
      getDevice(deviceId)
        .then((result) => setDevice({ ...result.results, caregivers: user.caregivers }))
        .catch((error) => console.log('Error', error));
      getLastLocation(deviceId)
        .then((result) => setLastLocation(result.results))
        .catch((exception) => {
          console.log('Error in getting last location', exception);
        });
    }
  }, []);
  function closeModal() {
    setState({ openAddDevice: false });
  }
  return (
    <>
      <Grid container>
        <UserInfo user={user} logout={logout} refresh={refresh} />
        <Grid
          container
          item
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.deviceList}
          xs={12}
          sm={12}
          md={12}
          lg={8}
        >
          <Grid
            container
            item
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.deviceListHeader}
          >
            <Grid
              container
              item
              direction="column"
              justify="center"
              alignItems="flex-start"
              className={classes.deviceHeaderTextContainer}
              xs={8}
            >
              <Grid item className={classes.deviceListGreetings}>
                Hello, {capitalize(user?.userInfo?.firstName)}!
              </Grid>
              <Grid className={classes.deviceListMessage}>Here you can see all of your devices and add new ones</Grid>
            </Grid>
            <Grid item className={classes.addNewCaneContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.addNewCaneButton}
                onClick={() => setState({ openAddDevice: true })}
              >
                <AddIcon className={classes.addIcon} />
                <span className={classes.addNewCaneText}>{' Add a new cane'}</span>
              </Button>
            </Grid>
          </Grid>
          <Dialog
            className={classes.modal}
            open={state.openAddDevice}
            // fullWidth={true}
            maxWidth={false}
            // onClose={handleClose}
          >
            <AddDevice user={user} closeModal={closeModal} refresh={refresh}></AddDevice>
          </Dialog>
          <Grid container item direction="row" justify="center" alignItems="flex-start">
            <Grid xs={12} item>
              <Device device={device} user={user} relation={'my'} refresh={refresh} lastLocation={lastLocation} />
            </Grid>
            {/* <Grid item xs={6}>
              <App />
            </Grid>

            <Grid item xs={6}>
              <BarChart />
            </Grid>
            <Grid item xs={12}>
              <LoadRate />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default DeviceDetail;

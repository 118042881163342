import { Grid, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../EditUser/editUser.styles';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import userService from '../../user.service';
import axios from 'axios';

const useStyles = makeStyles(styles);

function ReferalCodeModal({ closeModal, user }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [referalCode, setReferalCode] = useState('');
  const [loading, setLoading] = useState(false);

  const getReferalCode = useCallback(async () => {
    setLoading(true);
    try {
      const { data: result } = await axios.get(
        process.env.REACT_APP_EXPRESS_URL + `/api/users/${user?.id}/getReferalCode`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: localStorage.getItem('auth'),
          },
        },
      );
      console.log(result);
      setReferalCode(result?.data || '');
    } catch (exception) {
      console.log('Exception===>', exception);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    getReferalCode();
  }, [getReferalCode]);

  return (
    <div className={classes.paper}>
      <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
        <Grid item className={classes.title}>
          {t('referral_code')}
        </Grid>
        <Grid item className={classes.closeIcon} onClick={closeModal}>
          <CloseIcon />
        </Grid>
      </Grid>
      <p style={{ fontSize: '14px', fontWeight: '500' }}>Give $40 off on CAN GO and get one week free membership</p>
      <span style={{ fontSize: '10px' }}>Click the box to copy text</span>
      <Grid
        container
        item
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.referalCodeStyle}
        onClick={() => {
          navigator.clipboard.writeText(referalCode);
          toast('Text copied to clipboard!');
        }}
      >
        {loading ? <h3>Loading...</h3> : <h3 style={{ color: '#008060' }}>{referalCode}</h3>}
      </Grid>
    </div>
  );
}

export default ReferalCodeModal;

import { makeStyles, Select } from '@material-ui/core';
import { isEmpty, range, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import styles from '../../EditDevice/editDevice.styles';
import mainStyles from '../main.styles';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactGoogleAutocomplete from 'react-google-autocomplete';

const useStyles = makeStyles(styles);
const useMainStyles = makeStyles(mainStyles);

const genders = ['male', 'female', 'other'];
const canReasons = ['Fall Prevention', 'Manage Chronic Condition', 'Recovery or Rehab'];

const MissingInputsModal = ({ closeModal, user }) => {
  const [addressError, setAddressError] = React.useState('');

  const classes = useStyles();
  const mainClass = useMainStyles();

  const [missingData, setMissingData] = useState({
    birthdate: '',
    gender: '',
    canReason: '',
    weight: '',
    height: '',
    homeAddress: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (user) {
      setMissingData({
        birthdate: moment(user?.userInfo?.birthdate).format('yyyy-MM-DD') || '',
        gender: user?.userInfo?.gender || '',
        canReason: user?.canReason || '',
        homeAddress: user?.homeAddress || '',
        weight: user?.userInfo?.weight || '',
        height: user?.userInfo?.height || '',
      });
    }
  }, [user]);

  const handleClickSubmit = () => {
    const { birthdate, gender, canReason, homeAddress } = missingData;
    if (isEmpty(birthdate) || isEmpty(gender) || isEmpty(canReason) || isEmpty(homeAddress)) {
      setError('Fill all the fields!');
    } else {
      updateMissingData(missingData);
      setError('');
    }
  };

  const updateMissingData = async (missingData) => {
    const { birthdate, gender, canReason, weight, height, homeAddress, homeAddressDetails } = missingData;
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_EXPRESS_URL}/api/users/${user?.id}/updateMissingData`,
        {
          gender,
          birthdate,
          canReason,
          homeAddress,
          weight,
          height,
          homeAddressDetails,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: localStorage.getItem('auth'),
          },
        },
      );
      toast.success(res?.data?.results || 'Updated');
      closeModal();
    } catch (error) {
      setError(error?.response?.statusText);
    }
  };

  const handleSetAddress = (address) => {
    const { formatted_address, address_components: addressComponent } = address;
    setAddressError('');
    const isValidAddress = addressComponent[0].types.includes('street_number');
    if (isValidAddress) {
      const processedAddressObj = {};
      for (let item of addressComponent) {
        processedAddressObj[item.types[0]] = item.long_name;
      }

      setMissingData((prev) => {
        return { ...prev, homeAddress: formatted_address, homeAddressDetails: processedAddressObj };
      });
    } else {
      setAddressError('Not a residential address!');
    }
  };

  return (
    <div style={{ paddingInline: '25px', paddingBlock: '20px' }}>
      <h2>Complete your profile</h2>
      {(!user?.userInfo?.birthdate || !user?.userInfo?.age) && (
        <div style={{ marginTop: '20px' }}>
          <div className={classes.deviceInfoTitle}>Birth Date</div>
          <input
            className={classes.deviceInfoInput}
            type="date"
            value={missingData.birthdate}
            onChange={(e) =>
              setMissingData((prev) => {
                return { ...prev, birthdate: e.target.value };
              })
            }
          />
        </div>
      )}
      {!user?.userInfo?.gender && (
        <div style={{ marginTop: '20px' }}>
          <div className={classes.deviceInfoTitle}>Gender</div>
          <select
            className={classes.deviceInfoInput}
            onChange={(e) =>
              setMissingData((prev) => {
                return { ...prev, gender: e.target.value };
              })
            }
          >
            <option>---select---</option>
            {genders.map((gender) => (
              <option value={gender} selected={missingData.gender === gender}>
                {gender}
              </option>
            ))}
          </select>
        </div>
      )}
      {!user?.canReason && (
        <div style={{ marginTop: '20px' }}>
          <div className={classes.deviceInfoTitle}>What is the main reason for using a cane?</div>
          <select
            className={classes.deviceInfoInput}
            onChange={(e) =>
              setMissingData((prev) => {
                return { ...prev, canReason: e.target.value };
              })
            }
          >
            <option>---select---</option>
            {canReasons.map((reason) => (
              <option value={reason} selected={missingData.canReason === reason}>
                {reason}
              </option>
            ))}
          </select>
        </div>
      )}
      {isEmpty(user?.userInfo?.height) && (
        <div style={{ marginTop: '20px' }}>
          <div className={classes.deviceInfoTitle}>Weight</div>
          <Select
            native
            fullWidth
            value={missingData.weight}
            onChange={(event) => {
              const value = event.target.value;
              return setMissingData((prevState) => {
                return { ...prevState, weight: value };
              });
            }}
            inputProps={{
              name: 'weight',
            }}
          >
            <option value="" />
            {range(50, 500).map((value) => (
              <option key={value} value={`${value} lb`}>
                {value} lb
              </option>
            ))}
          </Select>
        </div>
      )}
      {isEmpty(user?.userInfo?.weight) && (
        <div style={{ marginTop: '20px' }}>
          <div className={classes.deviceInfoTitle}>Height</div>
          <Select
            native
            fullWidth
            value={missingData.height}
            onChange={(event) => {
              const value = event.target.value;
              return setMissingData((prev) => {
                return { ...prev, height: value };
              });
            }}
            inputProps={{
              name: 'height',
            }}
          >
            <option value="" />

            {map([3, 4, 5, 6], (feet) => {
              return range(0, 12).map((value) => (
                <option key={value} value={`${feet}' ${value}"`}>{`${feet}' ${value}"`}</option>
              ));
            })}
          </Select>
        </div>
      )}
      {!user?.homeAddress && (
        <div style={{ marginTop: '20px' }}>
          <div className={classes.deviceInfoTitle}>Home Address</div>
          <ReactGoogleAutocomplete
            options={{
              types: ['geocode'],
              componentRestrictions: { country: 'us' },
            }}
            className={classes.googleInput}
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            onPlaceSelected={handleSetAddress}
          />
          <p style={{ fontSize: '14px', color: 'red', marginTop: '8px' }}>{addressError}</p>
        </div>
      )}
      <div>
        <Button className={mainClass.missingDataModalCancelButton} onClick={() => closeModal()}>
          <span style={{ fontSize: '15px' }}>{'Cancel'}</span>
        </Button>
        <Button className={mainClass.missingDataModalSubmitButton} onClick={handleClickSubmit}>
          <span style={{ fontSize: '15px' }}>{'Submit'}</span>
        </Button>
      </div>
      {error && (
        <div className={mainClass.missingDataError}>
          <p style={{ textAlign: 'center' }}>{error}</p>
        </div>
      )}
    </div>
  );
};

export default MissingInputsModal;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './signUp.styles';
import PanelWithImage from './PanelWithImage';
import logo from '../images/logo.png';
import SignUpWithoutDevice from './SignUpWithoutDevice';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(styles);

function CompanionSignUp(props) {
  const classes = useStyles();
  const history = useHistory();

  const handleGoBack = (e) => {
    e.preventDefault();
    history.push('/sign-up');
  };

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <PanelWithImage />
      <Grid item className={classes.rightPanelContainer} xs={12} md={6}>
        <div className={classes.rightPanel}>
          <div className={classes.rightPanel}>
            <div className={classes.header}>
              <div>
                <img className={classes.logo} src={logo} alt="CAN logo" />
              </div>
            </div>
            <div>
              <SignUpWithoutDevice handleGoBack={handleGoBack} props={props} />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default CompanionSignUp;

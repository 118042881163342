import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import styles from './signUp.styles.js';
import caneImage from '../images/cane_front_panel_call.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

function FinalMessage({ email, type }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <form className={classes.form} noValidate>
      {type === 'companion' ? (
        <Grid container>
          <Typography component="h1" className={classes.mainText} variant="h4">
            {t('congratulation_message')}
          </Typography>
        </Grid>
      ) : (
        <Grid container>
          <Typography component="h1" className={classes.mainText} variant="h4">
            {t('congratulation_message')}
          </Typography>
          <Typography component="h1" variant="subtitle1" className={classes.explanationText}>
            {t('congratulation_message_para')}
          </Typography>
          <Grid container justify="center">
            <img className={classes.caneFrontPanelImage} src={caneImage} alt="Cane front panel" />
          </Grid>
        </Grid>
      )}
    </form>
  );
}

export default FinalMessage;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import 'react-phone-number-input/style.css';
import styles from './signUp.styles.js';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(styles);

const SignUpSelect = ({ onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selected, setSelected] = React.useState(null);
  return (
    <form
      className={classes.form}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        this.checkPIN();
      }}
    >
      <Typography className={classes.mainText} component="h1" variant="h4">
        Select the Signup method in the following options
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            onChange={(e) => setSelected(e.target.value)}
          >
            <FormControlLabel value="1" control={<Radio />} label="Signup with device" />
            <FormControlLabel value="2" control={<Radio />} label="Signup without device" />
          </RadioGroup>
        </FormControl>
      </div>

      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isEmpty(selected)}
        onClick={() => onChange(selected)}
      >
        {t('next')}
      </Button>
      <Grid container justify="flex-end" className={classes.bottomLinks}>
        <Grid item>
          <Link href="/#/login" variant="body2">
            {t('already_have_account')}
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default SignUpSelect;

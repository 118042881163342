import artboardImage from '../images/Artboard_2.png';
import colors from '../shared-colors';

export default (theme) => ({
  '@global': {
    body: {
      overflow: 'hidden',
    },
  },
  leftPanelContainer: {
    overflow: 'auto',
    // minWidth: '450px',
    background: colors.tan,
    height: '100vh',
    justifyContent: 'center',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
    // paddingTop: theme.spacing(8),
    // paddingLeft: theme.spacing(8),
    // paddingRight: theme.spacing(8),
    // paddingBottom: '100%',
    // display: 'inline-flex',
    // flexDirection: 'column',
    // alignItems: 'center',
  },
  leftPanel: {
    margin: 'auto',
    // maxWidth: '450px',
    textAlign: 'center',
    height: '100%',
  },
  // not used right now
  // leftPanelText: {
  //     fontSize: '32px',
  //     color: '#8F7256',
  // },
  logo: {
    width: '84px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0.5),
  },
  fromControlWidth: {
    width: '100%',
  },
  rememberMe: {
    float: 'left',
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    backgroundColor: 'white',
    borderRadius: '5px',
  },
  inputPIN: {
    fontSize: '24px',
  },
  rightPanelContainer: {
    overflow: 'auto',
    // minWidth: '450px',
    height: '100vh',
    backgroundColor: '#F9FCFD',
    paddingTop: '5%',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    ['@media (max-width:400px)']: {
      paddingTop: '100px',
      paddingBottom: '80px',
    },
  },
  rightPanel: {
    margin: 'auto',
    maxWidth: '450px',
    textAlign: 'center',
    // maxWidth: '450px',
    // paddingBottom: '100%',
    // display: 'inline-flex',
  },
  deviceImageContainer: {
    width: '100%',
  },
  deviceImage: {
    backgroundImage: `url(${artboardImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    // backgroundPosition: '50% -15%',
    width: '100%',
    paddingTop: '40%',
    // minHeight: '150px',
    // maxHeight: '400px',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    height: theme.spacing(15),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mainText: {
    marginBottom: '15px',
  },
  stepText: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#2F3C98',
    marginTop: '-15px',
  },
  explanationText: {
    marginBottom: '15px',
  },
  emergencyContactExplanationText: {
    marginBottom: '20px',
  },
  phoneNumberInput: {
    border: 'none',
    font: 'inherit',
    '&:focus': {
      outline: 'none',
    },
  },
  errorMessageContainer: {
    alignText: 'center',
  },
  errorMessage: {
    marginTop: '15px',
    color: theme.palette.error.main,
  },
  firstLink: {
    cursor: 'pointer',
  },
  lastLink: {
    marginLeft: 'auto',
  },
  caneFrontPanelImage: {
    height: '250px',
    marginBottom: '20px',
  },
  scrollBehavior: {
    'scroll-behavior': 'smooth',
  },
  goBackButton: {
    fontWeight: '500',
    fontSize: '16px',
    color: colors.grayText,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  bottomLinks: {
    textAlign: 'left',
    marginBottom: '35px',
  },
  termsAndConditionsFooter: {
    marginBottom: '30px',
    fontSize: '14px',
  },
  checkBoxContainer: {
    width: '100%',
  },
  grayText: {
    color: colors.grayText,
  },
  modalWidth: {
    width: '30%',
    ['@media (max-width:900px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '70%',
    },
  },
});

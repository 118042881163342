import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import userService from '../user.service.js';
import { makeStyles } from '@material-ui/core/styles';
import styles from './editDevice.styles.js';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CaregiverInvitation from './CaregiverInvitation';
import CanCompanionTCModal from './CanCompanionTCModal';
import SaveChanges from '../shared-components/SaveChanges';
import brownCaneImage from '../images/Brown-cane-card.png';
import blackCaneImage from '../images/Black-cane-card.png';
import { FormLabel } from '@material-ui/core';
import axios from 'axios';
import CaregiverDP from '../Main/components/CaregiverDP.jsx';
import EditCaregivers from './EditCaregivers.jsx';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import DoneAllIcon from '@mui/icons-material/DoneAll';

import { toast } from 'react-toastify';

const useStyles = makeStyles(styles);

function updateDeviceInfo({ deviceId, deviceName, imageUrl }) {
  const authData = userService.getAuthData();

  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/renameDevice`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: authData.accessToken,
    },
    body: JSON.stringify({
      deviceId,
      deviceName,
      imageUrl,
    }),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('Error on device update');
      }

      return res.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

function updateGoal(newGoal, device) {
  const authData = userService.getAuthData();
  const deviceId = device?.id;
  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/updateGoal/${deviceId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: authData.accessToken,
    },
    body: JSON.stringify({ daily: newGoal }),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('Error on goal update');
      }

      device.goal = newGoal;

      return res.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

function updateEmergencyContact(caregiverId, phoneNumber, user, emergencyContactInfo, isUpdate = false, deviceId) {
  const authData = userService.getAuthData();

  if (emergencyContactInfo) {
    return axios
      .put(
        `${process.env.REACT_APP_EXPRESS_URL}/api/users/update-emergency-contact`,
        JSON.stringify({
          userId: authData.currentUserId,
          emergencyContactInfo,
          isUpdate,
          deviceId,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: authData.accessToken,
          },
        },
      )
      .then((res) => {
        if (res.status !== 204) {
          throw new Error('Error on update emergency Contact');
        }
        //  user.emergencyContact = caregiverId || phoneNumber;

        return res.data;
      })
      .catch((err) => {
        return err?.response?.data?.message || 'Something went wrong!';
      });
  }

  return fetch(`${process.env.REACT_APP_API_URL}/api/users/${authData.currentUserId}/updateEmergencyContact`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: authData.accessToken,
    },
    body: JSON.stringify({
      caregiverId: caregiverId || '',
      phonenumber: phoneNumber || '',
    }),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('Error on update emergency Contact');
      }

      user.emergencyContact = caregiverId || phoneNumber;

      return res.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

function updateEmergencyConfig(emergencyConfig) {
  const authData = userService.getAuthData();

  return fetch(`${process.env.REACT_APP_API_URL}/api/users/${authData.currentUserId}/updateReportsConfig`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: authData.accessToken,
    },
    body: JSON.stringify(emergencyConfig),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('Error on update emergency Contact');
      }

      return res.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

function updateDropAlert(deviceId, state, refresh) {
  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/devices/${deviceId}/updateDropAlert`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('auth'),
    },
    body: JSON.stringify({ state }),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Couldn't get device");
      }
      refresh();
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

function updateAutoPilot(deviceId, state, refresh) {
  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/devices/${deviceId}/updateAutoPilot`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('auth'),
    },
    body: JSON.stringify({ state }),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Couldn't update device");
      }
      refresh();
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

function updateCANassistant(deviceId, state, canCompanionName, companionVoice, refresh) {
  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/devices/${deviceId}/CAN-assistant`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('auth'),
    },
    body: JSON.stringify({ state, canCompanionName, companionVoice }),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Couldn't update device");
      }
      refresh();
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

function getfallConfig(deviceId) {
  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/devices/${deviceId}/getDeviceInfo`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('auth'),
    },
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Couldn't get device");
      }

      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}

const updateWhitelist = (deviceId, state, refresh) => {
  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/devices/${deviceId}/updatewhitelist`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('auth'),
    },
    body: JSON.stringify({ state }),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Couldn't update device");
      }
      refresh();
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
};

function EditDevice({ user, device, deviceOwner, caregivers, relation, closeModal, refresh, userPortalSettings }) {
  const { t } = useTranslation();
  const [deviceInfo, setDeviceInfo] = React.useState([]);
  const [deviceAlert, setDeviceAlert] = React.useState(device?.config?.dropAlert || false);
  const [autoPilot, setAutoPilot] = React.useState(device?.config?.autoPilot?.state || false);
  const [canAssistant, setAssistant] = React.useState(device?.config?.canAssistant || false);
  const [canCompanionName, setCanCompanionName] = React.useState(device?.companionName || '');
  const [whitelist, setWhitelist] = React.useState(device?.config?.whitelistEnable || false);

  const [canCompanionTC, setCanCompanionTC] = React.useState(device?.companionTC || '');
  const [companionVoice, setCompanionVoice] = React.useState(device?.companionVoice || 'male');
  const [showTCModal, setShowTCModal] = React.useState(false);
  const [devicePhone, setDevicePhone] = React.useState(device?.deviceInfo?.phoneno || '');

  useEffect(() => {
    if (device.id) {
      getfallConfig(device.id).then((result) => {
        setDeviceAlert(result?.results?.config?.dropAlert || false);
        setAutoPilot(result?.results?.config?.autoPilot?.state || false);
        setAssistant(result?.results?.config?.CANassistant || false);
        setDeviceInfo(result?.results);
        setWhitelist(result?.results?.config?.whitelistEnable || false);
        setCanCompanionName(result?.results?.companionName || '');
        setCanCompanionTC(result?.results?.companionTC || '');
        setCompanionVoice(result?.results?.companionVoice || 'male');
        setDevicePhone(result?.results?.deviceInfo?.phoneno || '');
      });
    }
  }, []);
  const classes = useStyles();
  let reportsConfig = user.reportsConfig || { sms: {}, email: {} };
  let userIsEmergencyContact = false;

  // const newCaregiver = {};

  let caneImage = device.image;
  let wardDevice = {};

  if (relation === 'caregiver') {
    wardDevice = _.find(user.caregiverTo, (item) => item.device === device.id);
    userIsEmergencyContact = deviceOwner.emergencyContact === user.username;
    caneImage = wardDevice && wardDevice.imageUrl;

    reportsConfig = wardDevice.reportsConfig || { sms: {}, email: {} };
  }
  const [state, setState] = useState({
    dropAlert: deviceAlert?.config?.dropAlert,
    emergencyContact: user?.emergencyContactInfo?.phoneNumber,
    openAddCaregiver: false,
    openDeleteCaregiver: false,
    openUnpairCane: false,
    openCaregiverInfo: false,
    showSaveChanges: false,
    caregiverErrorMessage: null,
    caregiverToRemove: null,
    deviceName: device.name,
    deviceGoal: device.goal || 30,
    smsDaily: reportsConfig.sms.day || false,
    smsWeekly: reportsConfig.sms.week || false,
    smsMonthly: reportsConfig.sms.month || false,
    emailDaily: reportsConfig.email.day || false,
    emailWeekly: reportsConfig.email.week || false,
    emailMonthly: reportsConfig.email.month || false,
    updateCounter: 0,
    newCaregiverEmail: {},
    newCaregiverConfirmationEmail: {},
    isNewCaregiver: false,
    openEditCaregiver: false,
    caregiverToEdit: null,
  });

  let isSameCaregiver =
    (state.caregiverToRemove && (state.caregiverToRemove.userId || state.caregiverToRemove.phoneNumber)) ===
    user.emergencyContact;

  if (!caneImage) {
    caneImage = relation === 'my' ? brownCaneImage : blackCaneImage;
  }

  function closeSaveChanges() {
    setState((prevState) => {
      return { ...prevState, showSaveChanges: false };
    });
  }

  function checkChanges() {
    if (
      state.deviceName !== device.name ||
      state.deviceGoal !== device.goal ||
      state.emergencyContact !== user?.emergencyContactInfo?.phoneNumber ||
      state.smsDaily !== reportsConfig.sms.day ||
      state.smsWeekly !== reportsConfig.sms.week ||
      state.smsMonthly !== reportsConfig.sms.month ||
      state.emailDaily !== reportsConfig.email.day ||
      state.emailWeekly !== reportsConfig.email.week ||
      state.emailMonthly !== reportsConfig.email.month
    ) {
      setState((prevState) => {
        return { ...prevState, showSaveChanges: true };
      });
    } else {
      closeModal();
    }
  }

  // Doesn't used for now
  // function uploadImage(image) {
  //     const authData = userService.getAuthData();
  //     let getUrl = null;
  //     let postUrl = null;

  //     return fetch(`${process.env.REACT_APP_API_URL}/api/users/${authData.currentUserId}/getSignedImageURL`, {
  //             method: 'GET',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 'authorization': authData.accessToken,
  //             },
  //         })
  //             .then((res) => {
  //                 if (res.status !== 200) {
  //                     throw new Error('Incorrect email or password');
  //                 }

  //                 return res.json();
  //             })
  //             .then((data) => {
  //                 getUrl = data.status.getURL;
  //                 postUrl = data.status.postURL;

  //                 return fetch(postUrl, {
  //                     method: 'PUT',
  //                     headers: {
  //                         'Content-Type': 'image/jpeg',
  //                     },
  //                     body: image
  //                 })
  //             })
  //             .then((res) => updateDeviceInfo({ imageUrl: getUrl, deviceId: device.id }))
  //             .then((res) => {
  //                 device.image = getUrl;
  //                 if (relation === 'caregiver') {
  //                     wardDevice.imageUrl = getUrl;
  //                 }

  //                 setState(prevState => {
  //                     return {...prevState, updateCounter: state.updateCounter += 1 };
  //                 })
  //             })
  //             .catch((err) => {
  //                 console.log(err);
  //             });
  // }

  function sendCaregiverInvitation(email, deviceId, firstName, lastName, phoneNumber, relation) {
    const authData = userService.getAuthData();

    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/sendCaregiverInvitation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: authData.accessToken,
      },
      body: JSON.stringify({
        email,
        deviceId,
        firstName: firstName || '',
        lastName: lastName || '',
        phoneNumber,
        relation,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Error on sending caregiver invitation');
        }

        // return closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function removeFromCaregivers(caregiverId, deviceId, userId) {
    const authData = userService.getAuthData();

    if (!userId) {
      userId = authData.currentUserId;
    }

    return fetch(`${process.env.REACT_APP_API_URL}/api/users/${userId}/removeFromCaregivers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: authData.accessToken,
      },
      body: JSON.stringify({
        userId: caregiverId,
        deviceId,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Error on removing caregiver');
        }

        refresh();

        return res.json();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function unpairCane(userId, deviceId) {
    const authData = userService.getAuthData();

    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/unPairDevice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: authData.accessToken,
      },
      body: JSON.stringify({
        deviceId,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Error on unpairing device');
        }

        refresh();

        return res.json();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function saveChanges() {
    const promisees = [];

    if (device.name !== state.deviceName) {
      promisees.push(
        updateDeviceInfo({
          deviceId: device.id,
          deviceName: state.deviceName,
        }),
      );
    }

    if (device.goal !== state.deviceGoal) {
      promisees.push(updateGoal(state.deviceGoal, device));
    }

    if (user?.emergencyContactInfo?.phoneNumber !== state.emergencyContact) {
      const index = user.userCaregivers.findIndex((caregiver) => caregiver.phoneNumber == state.emergencyContact);

      if (index > -1 && state.emergencyContact && !state.isNewCaregiver)
        promisees.push(
          updateEmergencyContact(state.emergencyContact, null, user, user.userCaregivers[index], true, device.id),
        );
    }

    if (
      state.smsDaily !== reportsConfig.sms.day ||
      state.smsWeekly !== reportsConfig.sms.week ||
      state.smsMonthly !== reportsConfig.sms.month ||
      state.emailDaily !== reportsConfig.email.day ||
      state.emailWeekly !== reportsConfig.email.week ||
      state.emailMonthly !== reportsConfig.email.month
    ) {
      let reportsConfig = {};

      if (relation === 'my') {
        reportsConfig = {
          sms: {
            day: state.smsDaily,
            week: state.smsWeekly,
            month: state.smsMonthly,
          },
          email: {
            day: state.emailDaily,
            week: state.emailWeekly,
            month: state.emailMonthly,
          },
        };
      } else {
        reportsConfig = {
          caregiverTo: [
            {
              user: deviceOwner && deviceOwner.userId,
              device: device.id,
              sms: {
                day: state.smsDaily,
                week: state.smsWeekly,
                month: state.smsMonthly,
              },
              email: {
                day: state.emailDaily,
                week: state.emailWeekly,
                month: state.emailMonthly,
              },
            },
          ],
        };
      }

      promisees.push(updateEmergencyConfig(reportsConfig));
    }

    return Promise.all(promisees).then(() => {
      if (device.name !== state.deviceName) {
        device.name = state.deviceName;
      }

      if (device.goal !== state.deviceGoal) {
        device.goal = state.deviceGoal;
      }

      if (user.emergencyContact !== state.emergencyContact) {
        user.emergencyContact = state.emergencyContact;
      }

      if (
        state.smsDaily !== reportsConfig.sms.day ||
        state.smsWeekly !== reportsConfig.sms.week ||
        state.smsMonthly !== reportsConfig.sms.month ||
        state.emailDaily !== reportsConfig.email.day ||
        state.emailWeekly !== reportsConfig.email.week ||
        state.emailMonthly !== reportsConfig.email.month
      ) {
        reportsConfig.sms.day = state.smsDaily;
        reportsConfig.sms.week = state.smsWeekly;
        reportsConfig.sms.month = state.smsMonthly;
        reportsConfig.email.day = state.emailDaily;
        reportsConfig.email.week = state.emailWeekly;
        reportsConfig.email.month = state.emailMonthly;
      }

      updateDropAlert(device.id, deviceAlert, refresh);
      updateAutoPilot(device.id, autoPilot, refresh);
      updateCANassistant(device.id, canAssistant, canCompanionName, companionVoice, refresh);
      updateWhitelist(device.id, whitelist, refresh);
      refresh();
      closeModal();
    });
  }

  const handleCompanionNameChange = (e) => {
    const { value } = e.target;
    const reg = new RegExp('^[A-Za-z]{0,12}$');
    if (!reg.test(value)) {
      return false;
    } else {
      setCanCompanionName(value.trim());
    }
  };

  const handleChangeCanCompanion = () => {
    if (_.isEmpty(canCompanionTC.toString())) {
      canAssistant ? setAssistant(false) : setShowTCModal(true);
    } else {
      setAssistant((prev) => !prev);
    }
  };

  const handleAcceptCanCompanionTC = (isCancelled) => {
    if (isCancelled) {
      setShowTCModal(false);
    } else {
      setShowTCModal(false);
      setAssistant(true);
    }
  };

  const handleEditCaregivers = async (caregiverToEdit) => {
    const authData = userService.getAuthData();
    caregiverToEdit = { ...caregiverToEdit, updatedAt: moment().unix() };
    try {
      await axios.post(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${user?.id}/editCaregiver`, caregiverToEdit, {
        headers: {
          'Content-Type': 'application/json',
          authorization: authData.accessToken,
        },
      });
      setState((prevState) => {
        return {
          ...prevState,
          openEditCaregiver: false,
          caregiverToRemove: null,
        };
      });
      refresh().then(() => setState((prevState) => prevState));
    } catch (error) {
      console.log('error?.response?.data?.message===>', error?.response?.data?.message);
    }
  };

  const handleAssignAccessToCaregiver = async (caregiver, deviceId, devicePin) => {
    const authData = userService.getAuthData();

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_EXPRESS_URL}/api/users/${user?.id}/assign-caregiver`,
        {
          caregiver,
          deviceId,
          devicePin,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: authData.accessToken,
          },
        },
      );
      toast.success(data?.message || 'Something went wrong!');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Something went wrong!');
    } finally {
      refresh().then((result) => {
        setState((prevState) => prevState);
      });
    }
  };

  const removeCaregiversDuplicates = (data = [], pin = '') => {
    const seen = new Map();

    return data
      .filter((item) => {
        const identifier = `${item.email}-${item.phoneNumber}`;

        if (seen.has(identifier)) {
          if (item.device.pin === pin) {
            seen.set(identifier, item);
          }
          return false;
        }

        seen.set(identifier, item);
        return true;
      })
      .map((item) => seen.get(`${item.email}-${item.phoneNumber}`));
  };

  return (
    <div className={classes.paper}>
      <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
        <Grid item className={classes.title}>
          {t('cane_settings')}
        </Grid>
        <Grid item className={classes.closeIcon} onClick={checkChanges}>
          <CloseIcon />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={classes.userInfoContainer}
      >
        {/*
                <Grid   
                    item 
                    className={classes.caneImagePanel}
                >
                    <div className={classes.profileImageText}>
                        Device image
                    </div>
                    <div 
                        style={{
                            backgroundImage: `url(${caneImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize:'contain',
                            backgroundPosition: '50%',
                        }}
                        className={classes.caneImage}
                    >
                    </div>

                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        component="label"
                        className={classes.uploadPhotoButton}
                        onClick={() => console.log('Device IMAGE')}
                    >
                        Upload a Photo
                        <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={(event) => uploadImage(event.target.files[0])}
                        />
                    </Button>
                </Grid>
                */}
        <Grid item className={classes.deviceInfoPanel}>
          {/* <Grid style={{ gap: 60 }} container item direction="row"> */}
          {/* <Grid item xs={5}> */}
          <div className={classes.deviceInfoPartTitle}>{t('device_name')}</div>

          <input
            defaultValue={state.deviceName}
            className={classes.deviceInfoInput}
            onChange={(event) => {
              const { value } = event.target;
              setState((prevState) => {
                return { ...prevState, deviceName: value };
              });
            }}
          />
          <div className={`${classes.deviceInfoExplanationText} ${classes.grayText}`}>{t('device_name_para')}</div>
          {/* </Grid> */}

          {/* </Grid> */}
          <div className={classes.deviceInfoPart}></div>
          {relation === 'my' && (
            <>
              <div className={classes.deviceInfoPart}>
                <div className={classes.deviceInfoTitle}>{t('daily_acitivity_goal')}</div>
                <input
                  defaultValue={state.deviceGoal}
                  className={classes.deviceInfoInput}
                  onChange={(event) => {
                    const { value } = event.target;
                    setState((prevState) => {
                      return {
                        ...prevState,
                        deviceGoal: value,
                      };
                    });
                  }}
                />
                <div className={`${classes.deviceInfoExplanationText} ${classes.grayText}`}>
                  {t('daily_acitivity_goal_para')}
                </div>
              </div>
              <div>
                {t('device_phone')}: <span style={{ color: 'blue', fontWeight: '600' }}>{devicePhone}</span>
              </div>
            </>
          )}
          {relation === 'my' && (
            <div className={classes.deviceInfoSection}>
              <div className={classes.deviceInfoSectionTitle}>
                {t('your_caregivers')}
                <InfoIcon
                  className={classes.infoIcon}
                  onClick={() =>
                    setState((prevState) => {
                      return {
                        ...prevState,
                        openCaregiverInfo: true,
                      };
                    })
                  }
                />
              </div>
              <Divider className={classes.divider} />
              <RadioGroup
                aria-label="gender"
                name="gender"
                className={classes.deviceInfoRadio}
                value={state.emergencyContact}
                onChange={(event) => {
                  setState((prevState) => {
                    return {
                      ...prevState,
                      emergencyContact: event.target.value,
                    };
                  });
                }}
              >
                {_.map(removeCaregiversDuplicates(user.userCaregivers, deviceInfo?.pin), (caregiver) => {
                  if (
                    caregiver?.device?.pin == deviceInfo?.pin ||
                    caregiver?.device?.pin == device?.pin ||
                    caregiver?.device?.deviceId === deviceInfo?._id ||
                    caregiver?.device?._id === deviceInfo?._id
                  ) {
                    return (
                      <div className={classes.caregiverContainer}>
                        {caregiver?.imageUrl ? (
                          <img src={caregiver.imageUrl} alt="Caregiver" className={classes.caregiverImage} />
                        ) : (
                          <CaregiverDP name={caregiver.firstName} classes={classes} />
                        )}

                        <div className={classes.caregiverName}>
                          {caregiver.firstName} {caregiver.lastName}{' '}
                          {caregiver?.relation ? `(${caregiver?.relation})` : ''}
                        </div>
                        <div className={classes.caregiverPhoneNumber}>{caregiver.phoneNumber}</div>
                        <FormControlLabel
                          value={caregiver.phoneNumber || caregiver.userId}
                          control={<Radio color="primary" />}
                          label={t('emergency_contact')}
                          className={classes.radioButton}
                        />
                        <div
                          className={classes.editCaregiverButton}
                          onClick={(event) => {
                            setState((prevState) => {
                              return {
                                ...prevState,
                                openEditCaregiver: true,
                                caregiverToEdit: caregiver,
                              };
                            });
                          }}
                        >
                          <EditIcon className={classes.editIcon} />
                          {t('edit_caregiver')}
                        </div>
                        <div
                          className={classes.deleteCaregiverButton}
                          onClick={(event) => {
                            setState((prevState) => {
                              return {
                                ...prevState,
                                openDeleteCaregiver: true,
                                caregiverToRemove: caregiver,
                              };
                            });
                          }}
                        >
                          <DeleteIcon className={classes.deleteIcon} />
                          {t('delete_caregiver')}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className={classes.caregiverContainer}>
                        {caregiver?.imageUrl ? (
                          <img
                            src={caregiver.imageUrl}
                            alt="Caregiver"
                            className={classes.caregiverImage}
                            style={{ opacity: '0.4' }}
                          />
                        ) : (
                          <CaregiverDP name={caregiver.firstName} classes={classes} style={{ opacity: '0.4' }} />
                        )}

                        <div className={classes.caregiverName} style={{ opacity: '0.4' }}>
                          {caregiver.firstName} {caregiver.lastName}{' '}
                          {caregiver?.relation ? `(${caregiver?.relation})` : ''}
                        </div>
                        <div className={classes.caregiverPhoneNumber} style={{ opacity: '0.4' }}>
                          {caregiver.phoneNumber}
                        </div>
                        <FormControlLabel
                          value={caregiver.phoneNumber || caregiver.userId}
                          control={<Radio color="primary" />}
                          label={t('emergency_contact')}
                          className={classes.radioButton}
                          disabled={true}
                        />
                        <Button
                          variant="outlined"
                          className={classes.assignCaregiverButton}
                          onClick={() => handleAssignAccessToCaregiver(caregiver, deviceInfo?._id, deviceInfo?.pin)}
                        >
                          {t('grant_access')}
                        </Button>
                      </div>
                    );
                  }
                })}
              </RadioGroup>
              {!state.openAddCaregiver && (
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.addNewCaregiverButton}
                  onClick={() =>
                    setState((prevState) => {
                      return {
                        ...prevState,
                        openAddCaregiver: true,
                      };
                    })
                  }
                >
                  {t('add_a_new_caregiver')}
                </Button>
              )}
              {state.openAddCaregiver && (
                <CaregiverInvitation
                  sendCaregiverInvitation={sendCaregiverInvitation}
                  updateEmergencyContact={updateEmergencyContact}
                  device={device}
                  deviceInfo={deviceInfo}
                  setNewState={setState}
                  user={user}
                  refresh={() => {
                    return refresh().then(() => setState((prevState) => prevState));
                  }}
                  close={() =>
                    setState((prevState) => {
                      return {
                        ...prevState,
                        openAddCaregiver: false,
                      };
                    })
                  }
                />
              )}

              {state.openEditCaregiver && (
                <EditCaregivers
                  caregiverToEdit={state.caregiverToEdit}
                  close={() =>
                    setState((prevState) => {
                      return {
                        ...prevState,
                        openEditCaregiver: false,
                        caregiverToRemove: null,
                      };
                    })
                  }
                  handleEditCaregivers={handleEditCaregivers}
                />
              )}
            </div>
          )}

          <div className={classes.deviceInfoSection}>
            <div className={classes.deviceInfoSectionTitle}>{t('configurations')}</div>
            {/* <Divider className={classes.divider} />
            <div className={classes.deviceInfoTitle}>Reports configurations</div>
            <div className={`${classes.deviceInfoExplanationText} ${classes.grayText}`}>
              Select how the reports will be sent to you and how often will you receive them.
            </div>

            <Grid
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={classes.configsContainer}
            >
              <Grid
                item
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                className={classes.configSwitches}
              >
                <Grid item className={classes.configSwitchesTitle}>
                  Method
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.smsDaily || state.smsWeekly || state.smsMonthly}
                        onChange={() => {
                          const status = state.smsDaily || state.smsWeekly || state.smsMonthly;
                          setState((prevState) => {
                            return {
                              ...prevState,
                              smsDaily: !status,
                              smsWeekly: !status,
                              smsMonthly: !status,
                            };
                          });
                        }}
                        color="primary"
                      />
                    }
                    label="SMS"
                  />
                </Grid>
                <Grid item hidden>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.emailDaily || state.emailWeekly || state.emailMonthly}
                        onChange={() => {
                          const status = state.emailDaily || state.emailWeekly || state.emailMonthly;
                          setState((prevState) => {
                            return {
                              ...prevState,
                              emailDaily: !status,
                              emailWeekly: !status,
                              emailMonthly: !status,
                            };
                          });
                        }}
                        color="primary"
                      />
                    }
                    label="Email"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                className={classes.configCheckboxes}
              >
                <Grid item>Frequency</Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.smsDaily}
                        onChange={() =>
                          setState((prevState) => {
                            return {
                              ...prevState,
                              smsDaily: !state.smsDaily,
                            };
                          })
                        }
                        value="smsDaily"
                        color="primary"
                      />
                    }
                    label="Daily"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.smsWeekly}
                        onChange={() =>
                          setState((prevState) => {
                            return {
                              ...prevState,
                              smsWeekly: !state.smsWeekly,
                            };
                          })
                        }
                        value="smsWeekly"
                        color="primary"
                      />
                    }
                    label="Weekly"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.smsMonthly}
                        onChange={() =>
                          setState((prevState) => {
                            return {
                              ...prevState,
                              smsMonthly: !state.smsMonthly,
                            };
                          })
                        }
                        value="smsMonthly"
                        color="primary"
                      />
                    }
                    label="Monthly"
                  />
                </Grid>
                <Grid item hidden>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.emailDaily}
                        onChange={() =>
                          setState((prevState) => {
                            return {
                              ...prevState,
                              emailDaily: !state.emailDaily,
                            };
                          })
                        }
                        value="emailDaily"
                        color="primary"
                      />
                    }
                    label="Daily"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.emailWeekly}
                        onChange={() => {
                          return setState((prevState) => {
                            return {
                              ...prevState,
                              emailWeekly: !state.emailWeekly,
                            };
                          });
                        }}
                        value="emailWeekly"
                        color="primary"
                      />
                    }
                    label="Weekly"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.emailMonthly}
                        onChange={() =>
                          setState((prevState) => {
                            return {
                              ...prevState,
                              emailMonthly: !state.emailMonthly,
                            };
                          })
                        }
                        value="emailMonthly"
                        color="primary"
                      />
                    }
                    label="Monthly"
                  />
                </Grid>
              </Grid>
            </Grid> */}
            {(userPortalSettings?.canCompanion === 2 ||
              (userPortalSettings?.canCompanion === 1 && user?.settings?.canCompanion)) && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.deviceInfoTitle}>
                  CAN Companion (Beta) {deviceInfo?.configSyncStatus?.CANassistant && <DoneAllIcon color="primary" />}
                </div>
                <div className={`${classes.deviceInfoExplanationText} ${classes.grayText}`}>
                  {t('can_companion_para')},{' '}
                  <a href="https://www.cancompanion.ai/" target={'_blank'} rel="noreferrer">
                    {t('learn_more')}.
                  </a>{' '}
                  {t('a_caring_conversational_voice')}:
                  <ul>
                    <li>{t('talk_to_it_anytime')}.</li>
                    <li>{t('ask_it_anything')}.</li>
                    <li>{t('it_can_do_things_for_you')}.</li>
                  </ul>
                </div>
                <Grid
                  item
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  className={classes.configSwitches}
                  style={{ marginTop: '20px' }}
                >
                  <Grid item>
                    <FormControlLabel
                      control={<Switch checked={canAssistant} onChange={handleChangeCanCompanion} color="primary" />}
                    />
                  </Grid>
                  {canAssistant && (
                    <>
                      <div className={classes.deviceInfoPart} style={{ marginTop: '20px' }}>
                        <div className={classes.deviceInfoTitle}>{t('name_your_companion')}</div>
                        <input
                          defaultValue={canCompanionName}
                          className={classes.deviceInfoInput}
                          placeholder="Enter your companion name"
                          onChange={handleCompanionNameChange}
                          value={canCompanionName}
                        />
                      </div>
                      <div
                        className={`${classes.deviceInfoExplanationText} ${classes.grayText}`}
                        style={{ marginTop: '-15px' }}
                      >
                        {t('name_your_companion_para')}
                      </div>
                      <div style={{ marginTop: '15px' }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">{t('voice')}</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            checked={companionVoice === 'male'}
                            control={<Radio color="primary" />}
                            label={t('male')}
                            onChange={() => setCompanionVoice('male')}
                          />
                          <FormControlLabel
                            checked={companionVoice === 'female'}
                            control={<Radio color="primary" />}
                            label={t('female')}
                            onChange={() => setCompanionVoice('female')}
                          />
                        </RadioGroup>
                      </div>
                    </>
                  )}
                </Grid>
              </>
            )}

            {(userPortalSettings?.fallDetection === 2 ||
              (userPortalSettings?.fallDetection === 1 && user?.settings?.fallDetection)) && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.deviceInfoTitle}>
                  {t('fall_detection_config')}{' '}
                  {deviceInfo?.configSyncStatus?.dropAlert && <DoneAllIcon color="primary" />}
                </div>
                <div className={`${classes.deviceInfoExplanationText} ${classes.grayText}`}>
                  {t('fall_detection_config_para')}
                </div>

                <Grid
                  item
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  className={classes.configSwitches}
                  style={{ marginTop: '20px' }}
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch checked={deviceAlert} onChange={() => setDeviceAlert(!deviceAlert)} color="primary" />
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {/* <Divider className={classes.divider} />
            <div className={classes.deviceInfoTitle}>Auto Check-In (Beta)</div>
            <div className={`${classes.deviceInfoExplanationText} ${classes.grayText}`}>
              Your CAN Go will learn about your activity pattern for a few weeks, and then notify your caregivers to
              check on you if your daily activities deviate from your activity patterns.
            </div>
            <Grid
              item
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              className={classes.configSwitches}
              style={{ marginTop: '20px' }}
            >
              <Grid item>
                <FormControlLabel
                  control={<Switch checked={autoPilot} onChange={() => setAutoPilot(!autoPilot)} color="primary" />}
                />
              </Grid>
            </Grid> */}

            <Divider className={classes.divider} />
            <div className={classes.deviceInfoTitle}>
              {t('whitelist')} {deviceInfo?.configSyncStatus?.whitelistEnable && <DoneAllIcon color="primary" />}
            </div>
            <div className={`${classes.deviceInfoExplanationText} ${classes.grayText}`}>{t('whitelist_para')}</div>
            <Grid
              item
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              className={classes.configSwitches}
              style={{ marginTop: '20px' }}
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={whitelist}
                      onChange={() => setWhitelist((prevState) => !prevState)}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.footer}>
        <Grid item>
          <Button
            fullWidth
            variant="outlined"
            className={classes.redButton}
            onClick={() =>
              setState((prevState) => {
                return { ...prevState, openUnpairCane: true };
              })
            }
          >
            {relation === 'my' ? t('unpair_cane') : t('delete_from_caregivers')}
          </Button>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          className={classes.saveAndCancelButtons}
        >
          <Grid item>
            <Button fullWidth variant="outlined" onClick={checkChanges}>
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button fullWidth color="primary" variant="contained" className={classes.saveButton} onClick={saveChanges}>
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog className={classes.modal} open={state.openDeleteCaregiver} fullWidth={false} maxWidth={false}>
        <div className={classes.deleteCaregiverPaper}>
          <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
            <Grid item className={classes.title}>
              {!isSameCaregiver ? t('delete_caregiver') : t('this_is_emergency_contact')}
            </Grid>
            <Grid
              item
              className={classes.closeIcon}
              onClick={() =>
                setState((prevState) => {
                  return {
                    ...prevState,
                    openDeleteCaregiver: false,
                  };
                })
              }
            >
              X
            </Grid>
          </Grid>
          <div className={classes.userInfoContainer}>
            {!isSameCaregiver
              ? `Are you sure you want to delete your caregiver? ${console.log(
                  'state.caregiverToRemove',
                  state.caregiverToRemove,
                )}`
              : 'You can not delete the caregiver as long as they are your emergency contacts. Add or select new emergency contact first.'}
          </div>
          <Grid container item direction="row" justify="flex-end" alignItems="center" className={classes.footer}>
            {!isSameCaregiver && (
              <Grid item>
                <Button
                  fullWidth
                  variant="outlined"
                  className={classes.redButton}
                  onClick={async () => {
                    let result = await fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/delete-caregiver`, {
                      method: 'DELETE',
                      headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('auth'),
                      },
                      body: JSON.stringify({
                        userId: user.id,
                        caregiver: {
                          email: state.caregiverToRemove.email,
                          firstName: state.caregiverToRemove.email.firstName,
                          lastName: state.caregiverToRemove.lastName,
                          phoneNumber: state.caregiverToRemove.phoneNumber,
                          device: { pin: deviceInfo?.pin || '', deviceId: deviceInfo?._id || '' },
                        },
                      }),
                    }).then((result) => {
                      return result.json();
                    });

                    if (result?.results) {
                      removeFromCaregivers(result.results._id, device.id).then((result) => {
                        return setState((prevState) => {
                          return {
                            ...prevState,
                            openDeleteCaregiver: false,
                          };
                        });
                      });
                    }

                    refresh().then((result) => {
                      setState((prevState) => {
                        return {
                          ...prevState,
                          openDeleteCaregiver: false,
                        };
                      });
                    });
                  }}
                >
                  {t('delete')}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.cancelButton}
                onClick={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      openDeleteCaregiver: false,
                    };
                  })
                }
              >
                {!isSameCaregiver ? t('cancel') : t('got_it')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog className={classes.modal} open={state.openUnpairCane} fullWidth={false} maxWidth={false}>
        <div className={classes.deleteCaregiverPaper}>
          <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
            <Grid item className={classes.title}>
              {!userIsEmergencyContact ? t('unpair_cane') : t('you_are_emergency_contact')}
            </Grid>
            <Grid
              item
              className={classes.closeIcon}
              onClick={() =>
                setState((prevState) => {
                  return {
                    ...prevState,
                    openUnpairCane: false,
                  };
                })
              }
            >
              X
            </Grid>
          </Grid>
          <div className={classes.userInfoContainer}>
            {!userIsEmergencyContact ? t('are_you_sure_you_want_to_unpair_cane') : t('you_cannot_delete_caregiver')}
          </div>
          <Grid container item direction="row" justify="flex-end" alignItems="center" className={classes.footer}>
            {!userIsEmergencyContact && (
              <Grid item>
                <Button
                  fullWidth
                  variant="outlined"
                  className={classes.redButton}
                  onClick={() => {
                    let action = () => unpairCane(user.id, device.id);

                    if (relation === 'caregiver') {
                      action = () => removeFromCaregivers(user.id, device.id, deviceOwner.userId);
                    }

                    return action().then(() => {
                      closeModal();
                    });
                  }}
                >
                  {t('unpair')}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.cancelButton}
                onClick={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      openUnpairCane: false,
                    };
                  })
                }
              >
                {!userIsEmergencyContact ? t('cancel') : t('got_it')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog className={classes.modal} open={state.openCaregiverInfo} fullWidth={false} maxWidth={false}>
        <div className={classes.deleteCaregiverPaper}>
          <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
            <Grid item className={classes.title}>
              {t('caregivers_and_emergency_contacts')}
            </Grid>
            <Grid
              item
              className={classes.closeIcon}
              onClick={() =>
                setState((prevState) => {
                  return {
                    ...prevState,
                    openCaregiverInfo: false,
                  };
                })
              }
            >
              X
            </Grid>
          </Grid>
          <div className={classes.caregiverInfoTextPart}>{t('caregivers_and_emergency_contacts_para1')}</div>
          <div className={classes.caregiverInfoTextPart}>{t('caregivers_and_emergency_contacts_para2')}</div>
          <div className={classes.caregiverInfoTextPart}>{t('caregivers_and_emergency_contacts_para3')}</div>
          <Grid container item direction="row" justify="flex-end" alignItems="center" className={classes.footer}>
            <Grid item>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.cancelButton}
                onClick={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      openCaregiverInfo: false,
                    };
                  })
                }
              >
                {t('got_it')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        className={classes.modal}
        open={state.showSaveChanges}
        // fullWidth={true}
        maxWidth={false}
        // onClose={handleClose}
      >
        <SaveChanges action={saveChanges} closeModal={closeSaveChanges} closeParentModal={closeModal}></SaveChanges>
      </Dialog>

      <Dialog
        className={classes.modal}
        open={showTCModal}
        // fullWidth={true}
        maxWidth={false}
      >
        <CanCompanionTCModal closeModal={handleAcceptCanCompanionTC} key={1} />
      </Dialog>
    </div>
  );
}

export default EditDevice;

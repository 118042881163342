import { isEmpty } from 'lodash';
import React from 'react';

const CaregiverDP = ({ name, classes, style }) => {
  return (
    <div
      className={classes.deviceCardUserImage || classes.caregiverImage}
      style={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FF5B00',
        userSelect: 'none',
      }}
    >
      <h3 style={{ color: '#fff' }}>{!isEmpty(name) ? name.charAt(0) : 'CG'}</h3>
    </div>
  );
};

export default CaregiverDP;

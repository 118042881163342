import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CreditCardInfo from './creditCardInfo';
const promise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const StripeCardForm = ({
  signUp,
  state,
  setState,
  goToNextStep,
  goToPreviousStep,
  isEdit,
  editCard,
  loading,
  addPaymentFromDashboard = false,
  cancelAddpaymentForm,
  companionUser,
}) => {
  return (
    <Elements stripe={promise}>
      <CreditCardInfo
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        setState={setState}
        state={state}
        isEdit={isEdit}
        signUp={signUp}
        editCard={editCard}
        loading={loading}
        addPaymentFromDashboard={addPaymentFromDashboard}
        cancelAddpaymentForm={cancelAddpaymentForm}
        companionUser={companionUser}
      />
    </Elements>
  );
};

export default StripeCardForm;

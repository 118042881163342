import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import userService from '../user.service.js';
import styles from './main.styles.js';
import UserInfo from './UserInfo';
import DeviceList from './DeviceList';
import Onboarding from './Onboarding';
import { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isEmpty } from 'lodash';
import MissingInputsModal from './components/MissingInputsModal.jsx';
import CompanionScreen from './CompanionScreen.jsx';

const useStyles = makeStyles(styles);

function Main(props) {
  const classes = useStyles();

  let user = {};
  const [state, setState] = useState({
    stateUpdates: 0,
    showOnboarding: !localStorage['notFirstVisit'],
    missingData: false,
  });
  const [userPortalSettings, setUserPortalSettings] = useState(null);

  async function spiroLogin() {
    try {
      const result = await axios.post(
        process.env.REACT_APP_SPIRO_URL + '/login',
        {
          apiKey: process.env.REACT_APP_SPIRO_KEY,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'ocp-apim-subscription-key': process.env.REACT_APP_SPIRO_OCM,
          },
        },
      );
      if (result?.status === 200) {
        return localStorage.setItem('spiroToken', result.data.tokenData.token);
      }
      // return toast('Something went wrong while fetching videos');
      return;
    } catch (exception) {
      // return toast('Something went wrong while fetching videos');
      return;
    }
  }

  function logout() {
    localStorage.setItem('showMissingData', 1);
    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: authData.accessToken,
      },
    })
      .then((res) => {
        if (res.status !== 204) {
          throw new Error('Logout failed');
        }
      })
      .then(() => {
        userService.clearUserData();
        props.history.push('/login');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function refresh() {
    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/userInfo`, {
      method: 'GET',
      headers: {
        authorization: authData.accessToken + '~123123',
      },
    })
      .then((res) => res.json())
      .then(({ data: userData }) => {
        if (!userData.error) {
          userService.updateCurrentUser(userData);
          user = userData;
          setState((prevState) => {
            return { ...prevState, stateUpdates: (state.stateUpdates += 1) };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function closeOnboarding() {
    setState((prevState) => {
      return { ...prevState, showOnboarding: false };
    });
  }

  let authData = userService.getAuthData();
  let currentUser = userService.getCurrentUser();

  if (!currentUser) {
    props.history.push('/login');
  } else {
    user = currentUser;
  }

  React.useEffect(() => {
    spiroLogin();
    getPortalSettings();
  }, []);

  React.useEffect(() => {
    const missingDataLoc = localStorage['showMissingData'] || 1;
    if (user && parseInt(missingDataLoc) === 1) {
      if (
        (isEmpty(toString(user?.userInfo?.age)) ||
          isNaN(user?.userInfo?.age) ||
          isEmpty(user?.userInfo?.birthdate) ||
          isEmpty(user?.userInfo?.gender) ||
          isEmpty(user?.canReason) ||
          isEmpty(user?.userInfo?.weight) ||
          isEmpty(user?.userInfo?.height) ||
          isEmpty(user?.homeAddress)) &&
        !isEmpty(user?.userTo)
      ) {
        setState((prev) => {
          return { ...prev, missingData: true };
        });
      }
    }
  }, [user]);

  const handleCloseModal = () => {
    localStorage.setItem('showMissingData', 0);
    setState((prev) => {
      return { ...prev, missingData: false };
    });
  };

  const getPortalSettings = async () => {
    try {
      const result = await axios.get(process.env.REACT_APP_EXPRESS_URL + '/api/users/getUserPortalSettings', {
        headers: {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('auth'),
        },
      });
      setUserPortalSettings(result?.data?.results || null);
    } catch (exception) {
      console.log('Exception', exception);
    }
  };

  const USER_TYPE = {
    device_user: 'DEVICE_USER',
    companion_user: 'COMPANION_USER',
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {user?.userType === USER_TYPE.companion_user ? (
        <Grid container>
          <UserInfo user={user} logout={logout} refresh={refresh} />

          <CompanionScreen user={user} />

          <Dialog className={classes.modal} open={state.showOnboarding} maxWidth={false}>
            <Onboarding closeModal={closeOnboarding}></Onboarding>
          </Dialog>
        </Grid>
      ) : (
        <Grid container>
          <UserInfo user={user} logout={logout} refresh={refresh} />

          <DeviceList user={user} refresh={refresh} userPortalSettings={userPortalSettings} />

          <Dialog
            className={classes.modal}
            open={state.showOnboarding}
            // fullWidth={true}
            maxWidth={false}
          >
            <Onboarding closeModal={closeOnboarding}></Onboarding>
          </Dialog>

          <Dialog className={classes.modal} open={state.missingData} fullWidth={true}>
            <MissingInputsModal closeModal={handleCloseModal} user={user} />
          </Dialog>
        </Grid>
      )}
    </div>
  );
}

export default Main;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './editDevice.styles.js';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import AddYourselfAsEmergencyContact from '../shared-components/AddYourselfAsEmergencyContact';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// a comment
const useStyles = makeStyles(styles);

let newCaregiverEmail = null;
let newCaregiverConfirmationEmail = null;
let newCaregiverPhoneNumber = null;
let newCaregiverFirstName = null;
let newCaregiverLastName = null;
let newCaregiverRelation = null;

function CaregiverInvitation({
  sendCaregiverInvitation,
  updateEmergencyContact,
  close,
  device,
  deviceInfo,
  user,
  refresh,
  setNewState,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, setState] = useState({
    openSetAsEmergencyContact: false,
    showAddYourselfAsEmergencyContact: false,
    caregiverErrorMessage: null,
    updateCounter: 0,
    newCaregiverEmail: newCaregiverEmail,
    newCaregiverConfirmationEmail: newCaregiverConfirmationEmail,
  });

  const relations = [
    t('father'),
    t('mother'),
    t('sister'),
    t('brother'),
    t('spouse'),
    t('son'),
    t('daughter'),
    t('friend'),
    t('professional_caregiver'),
  ];

  return (
    <div>
      <div className={classes.newCaregiver}>
        <div className={classes.caregiverErrorMessage}>{state.caregiverErrorMessage}</div>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Grid item className={classes.deviceInfoPart}>
            <div className={classes.deviceInfoPartTitle}>{t('caregiver_first_name')}</div>
            <input
              className={classes.deviceInfoInput}
              defaultValue={newCaregiverFirstName}
              onChange={(event) => (newCaregiverFirstName = event.target.value)}
            />
          </Grid>
          <Grid item className={classes.deviceInfoPart}>
            <div className={classes.deviceInfoPartTitle}>{t('caregiver_last_name')}</div>
            <input
              className={classes.deviceInfoInput}
              defaultValue={newCaregiverLastName}
              onChange={(event) => (newCaregiverLastName = event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Grid item className={classes.deviceInfoPart}>
            <div className={classes.deviceInfoPartTitle}>{t('caregiver_email')}</div>
            <input
              className={classes.deviceInfoInput}
              defaultValue={newCaregiverEmail}
              onChange={({ target: { value } }) => (newCaregiverEmail = value)}
            />
          </Grid>
          <Grid item className={classes.deviceInfoPart}>
            <div className={classes.deviceInfoPartTitle}>{t('confirm_email')}</div>
            <input
              className={classes.deviceInfoInput}
              defaultValue={newCaregiverConfirmationEmail}
              onChange={({ target: { value } }) => (newCaregiverConfirmationEmail = value)}
            />
          </Grid>
        </Grid>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Grid item className={classes.deviceInfoPart}>
            <div>{t('relation_to_caregiver')}</div>
            <select className={classes.deviceInfoInput} onChange={(e) => (newCaregiverRelation = e.target.value)}>
              <option value="">-- {t('select_relation')} --</option>
              {relations.map((relation) => (
                <option value={relation}>{relation}</option>
              ))}
            </select>
          </Grid>
          <Grid item className={classes.deviceInfoPart}>
            <div className={classes.deviceInfoPartTitle}>{t('caregiver_phone_number')}</div>
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="US"
              // flagUrl="https://flag.pk/flags/4x3/{xx}.svg"
              addInternationalOption={true}
              limitMaxLength={true}
              className={classes.newCaregiverPhoneNumberParent}
              value={newCaregiverPhoneNumber}
              numberInputProps={{
                className: `${classes.deviceInfoInput} ${classes.newCaregiverPhoneNumberChild}`,
              }}
              onChange={(value) => {
                newCaregiverPhoneNumber = value;
                if (value?.length > 0) {
                  setNewState((prevState) => {
                    return { ...prevState, isNewCaregiver: true };
                  });
                } else {
                  setNewState((prevState) => {
                    return { ...prevState, isNewCaregiver: false };
                  });
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.addCaregiverFooter}
        >
          <Grid item>
            <Button fullWidth variant="outlined" className={classes.cancelButton} onClick={close}>
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              className={classes.saveButton}
              onClick={() => {
                // set yourself as emergencyContact
                if (newCaregiverPhoneNumber === user.username) {
                  return setState((prevState) => {
                    return {
                      ...prevState,
                      showAddYourselfAsEmergencyContact: true,
                    };
                  });
                }

                if (!newCaregiverEmail || !newCaregiverConfirmationEmail) {
                  return setState((prevState) => {
                    return {
                      ...prevState,
                      caregiverErrorMessage:
                        '"Caregiver’s email address" and "Confirm email address" fields are required',
                    };
                  });
                }

                if (newCaregiverConfirmationEmail !== newCaregiverEmail) {
                  return setState((prevState) => {
                    return {
                      ...prevState,
                      caregiverErrorMessage: '"Caregiver’s email address" and "Confirm email address" should match',
                    };
                  });
                }

                return sendCaregiverInvitation(
                  newCaregiverEmail,
                  device.id,
                  newCaregiverFirstName,
                  newCaregiverLastName,
                  newCaregiverPhoneNumber,
                  newCaregiverRelation,
                ).then((res) => {
                  setState((prevState) => {
                    return { ...prevState, openSetAsEmergencyContact: true };
                  });
                });
              }}
            >
              {t('send_an_invitation')}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Dialog open={state.openSetAsEmergencyContact} fullWidth={false} maxWidth={false}>
        <div className={classes.deleteCaregiverPaper} style={{ width: '85%' }}>
          <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
            <Grid item className={classes.title}>
              {t('invitation_was_sent')}
            </Grid>
            <Grid
              item
              className={classes.closeIcon}
              onClick={() =>
                setState((prevState) => {
                  return { ...prevState, openSetAsEmergencyContact: false };
                })
              }
            >
              X
            </Grid>
          </Grid>
          <div className={classes.userInfoContainer}>{t('set_invited_caregiver_as_emergency')}</div>
          <Grid container item direction="row" justify="flex-end" alignItems="center" className={classes.footer}>
            <Grid item>
              <Button
                fullWidth
                style={{ fontSize: 12 }}
                variant="outlined"
                className={classes.redButton}
                onClick={() => {
                  return updateEmergencyContact(null, newCaregiverPhoneNumber, user, {
                    firstName: newCaregiverFirstName,
                    lastName: newCaregiverLastName,
                    phoneNumber: newCaregiverPhoneNumber,
                    email: newCaregiverEmail,
                    device: {
                      pin: deviceInfo?.pin || '',
                    },
                    newCaregiverRelation,
                  }).then((res) => {
                    if (res === 'Already a caregiver') {
                      setState((prevState) => {
                        return {
                          ...prevState,
                          caregiverErrorMessage: res || 'Something went wrong!',
                          openSetAsEmergencyContact: false,
                        };
                      });
                    } else {
                      newCaregiverEmail = null;
                      newCaregiverConfirmationEmail = null;
                      newCaregiverPhoneNumber = null;
                      newCaregiverFirstName = null;
                      newCaregiverLastName = null;
                      newCaregiverRelation = null;
                      refresh();
                      setState((prevState) => {
                        return { ...prevState, openSetAsEmergencyContact: false };
                      });
                      close();
                    }
                  });
                }}
              >
                {t('set_as_emergency_contact')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.cancelButton}
                onClick={async () => {
                  console.log('user', user);
                  try {
                    await axios.put(
                      `${process.env.REACT_APP_EXPRESS_URL}/api/users/add-caregiver`,
                      JSON.stringify({
                        userId: user.id,
                        caregiver: {
                          email: newCaregiverEmail,
                          firstName: newCaregiverFirstName,
                          lastName: newCaregiverLastName,
                          phoneNumber: newCaregiverPhoneNumber,
                          device: {
                            pin: deviceInfo?.pin || '',
                            deviceId: deviceInfo?._id || '',
                          },
                          relation: newCaregiverRelation,
                          createdAt: moment().unix(),
                          updatedAt: moment().unix(),
                        },
                      }),
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          authorization: localStorage.getItem('auth'),
                        },
                      },
                    );
                    newCaregiverEmail = null;
                    newCaregiverConfirmationEmail = null;
                    newCaregiverPhoneNumber = null;
                    newCaregiverFirstName = null;
                    newCaregiverLastName = null;
                    newCaregiverRelation = null;
                    close();
                  } catch (e) {
                    setState((prevState) => {
                      return {
                        ...prevState,
                        caregiverErrorMessage: e?.response?.data?.message || 'Something went wrong!',
                      };
                    });
                  }

                  refresh().then((result) => {
                    setState((prevState) => {
                      return { ...prevState, openSetAsEmergencyContact: false };
                    });
                  });
                }}
              >
                {t('done')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <Dialog
        className={classes.modal}
        open={state.showAddYourselfAsEmergencyContact}
        fullWidth={false}
        maxWidth={false}
      >
        <AddYourselfAsEmergencyContact
          action={async () => {
            try {
              const { data: res } = await fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/add-caregiver`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  authorization: localStorage.getItem('auth'),
                },
                body: JSON.stringify({
                  userId: user.id,
                  caregiver: {
                    email: newCaregiverEmail,
                    firstName: newCaregiverFirstName,
                    lastName: newCaregiverLastName,
                    phoneNumber: newCaregiverPhoneNumber,
                    device: {
                      pin: deviceInfo?.pin || '',
                      deviceId: deviceInfo?._id || '',
                    },
                    relation: newCaregiverRelation,
                    createdAt: moment().unix(),
                    updatedAt: moment().unix(),
                  },
                }),
              });
              console.log('res===>', res);
            } catch (e) {
              setState((prevState) => {
                return {
                  ...prevState,
                  caregiverErrorMessage: e?.response?.data?.message || 'Something went wrong!',
                };
              });
            }

            updateEmergencyContact(null, newCaregiverPhoneNumber, user).then(() => {
              refresh();
            });
          }}
          closeModal={() =>
            setState((prevState) => {
              return { ...prevState, showAddYourselfAsEmergencyContact: false };
            })
          }
          closeParentModal={close}
        />
      </Dialog>
    </div>
  );
}

export default CaregiverInvitation;

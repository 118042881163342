import React, { useState } from 'react';
import _, { isEmpty, map } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import styles from './main.styles.js';
import Button from '@mui/material/Button';
import CallIcon from '../images/call.png';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import userService from '../user.service.js';

const useStyles = makeStyles(styles);

function CompanionCard() {
  const authData = userService.getAuthData();
  const scrollRef = React.useRef();
  const classes = useStyles();
  const [input, setInput] = useState('');
  const [chatLoader, setChatLoader] = useState(false);
  const [messages, setMessages] = useState([
    {
      message: 'Hello! My name is Can Companion. How can I assist you today?',
      sender: 'bot',
    },
  ]);

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const submitText = async (e) => {
    setChatLoader(true);
    scrollToBottom();

    e.preventDefault();
    if (isEmpty(input)) return;

    setMessages((prev) => {
      return [...prev, { message: input.trim(), sender: 'user' }];
    });
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_EXPRESS_URL}/api/users/companion_assistance`,
        { message: input },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: authData.accessToken,
          },
        },
      );
      setMessages((prev) => [...prev, { message: data?.data, sender: 'bot' }]);
    } catch (error) {
      console.log('error=====>', error);
    } finally {
      setInput('');
      setChatLoader(false);
      scrollToBottom();
    }
  };

  const handlePressEnter = async (e) => {
    if (e.key === 'Enter') {
      submitText(e);
    }
  };

  const canNum = '+13185839953';

  const handleClickPhone = (e) => {
    e.preventDefault();
    window.location.href = `tel:${canNum}`;
  };
  return (
    <>
      <h2>CAN Companion</h2>
      <Grid style={{ width: '100%' }}>
        <div className="chat-card-box">
          <Grid item xs={12} md={9} lg={9} sm={12}>
            <div className="chat-card-inter-box">
              <div style={{ height: '300px' }}>
                <div
                  style={{
                    margin: '8px',
                    padding: '8px',
                    height: '270px',
                    overflow: 'scroll',
                  }}
                >
                  {map(messages, ({ message, sender }, idx) => (
                    <p key={idx} className={sender === 'user' ? 'sender-message' : 'bot-message'}>
                      {message}
                    </p>
                  ))}
                  <div ref={scrollRef} />
                </div>
              </div>
              <div style={{ height: '45px' }}>
                <input
                  className="companion-chat-input"
                  type="text"
                  onChange={(e) => setInput(e.target.value)}
                  placeholder={chatLoader ? 'Generating response...' : 'Enter input here'}
                  value={input}
                  onKeyPress={handlePressEnter}
                />
                {chatLoader ? (
                  <Button variant="outlined">
                    <CircularProgress size={20} />
                  </Button>
                ) : (
                  <Button variant="outlined" onClick={submitText}>
                    Send
                  </Button>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3} lg={3} sm={12}>
            <div style={{ display: 'flex', justifyContent: 'center', background: '#fff' }}>
              <div>
                <img src={CallIcon} alt="CallIcon" className={classes.phoneIcon} onClick={handleClickPhone} />
                <p
                  style={{ textAlign: 'center', color: 'blue', fontWeight: '500', cursor: 'pointer' }}
                  onClick={handleClickPhone}
                >
                  {canNum}
                </p>
                <p style={{ textAlign: 'center' }}>Call CAN Companion</p>
              </div>
            </div>
          </Grid>
        </div>
      </Grid>
    </>
  );
}

export default CompanionCard;

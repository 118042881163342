import { FormControl, OutlinedInput } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { Box, CircularProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';

import styles from './signUp.styles';

import { makeStyles } from '@material-ui/core/styles';

import React, { useMemo } from 'react';
import { t } from 'i18next';

import { useTranslation } from 'react-i18next';

const useOptions = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = useMemo(() => ({
    style: {
      base: {
        color: '#424770',
        letterSpacing: '0.025em',
        border: 'groove !important',
        //   fontFamily: "Source Code Pro, monospace",
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }));

  return options;
};
const useStyles = makeStyles(styles);

const CreditCardInfo = ({
  state,
  signUp,
  setState,
  goToNextStep,
  goToPreviousStep,
  isEdit,
  editCard,
  loading,
  addPaymentFromDashboard,
  cancelAddpaymentForm,
  companionUser,
}) => {
  const { t } = useTranslation();
  const [check, setCheck] = React.useState(false);
  const [error, setError] = React.useState('');
  const [name, setName] = React.useState('');
  const classes = useStyles();

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    console.log(elements.getElement(CardNumberElement));
    const token = await stripe.createToken(elements.getElement(CardNumberElement));

    if (token?.error) {
      setState((prevState) => {
        return {
          ...prevState,
          errorMessage: 'Please check card Expiration or Card number',
        };
      });
    } else {
      if (!isEdit) {
        setState((prevState) => {
          return {
            ...prevState,
            errorMessage: '',
          };
        });
      }
      if (isEdit) {
        return editCard(token.token);
      } else {
        setCheck(true);
        signUp({
          ...state,
          stripeInfo: {
            //   paymentMethod: payload.paymentMethod,
            cardToken: token.token,
          },
        });
      }
    }
  };

  // React.useEffect(() => {
  //   console.log(
  //     "some other flag",
  //     state.errorMessage,
  //     state.errorMessage == undefined
  //   );
  //   if (check && !state.errorMessage) {
  //     if (state?.errorMessage == "") {
  //       return goToNextStep();
  //     }
  //     return;
  //   }
  // }, [state?.errorMessage]);

  return (
    <>
      {!isEdit ? (
        <>
          {companionUser ? (
            <div className={classes.stepText}>{t('step')} 2/2</div>
          ) : (
            <div className={classes.stepText}>{t('step')} 5/5</div>
          )}
          <Typography className={classes.mainText} component="h1" variant="h4">
            {t('credit_card_info')}
          </Typography>
          {!isEdit ? (
            <Typography component="h1" variant="subtitle1">
              {t('credit_card_para')}
            </Typography>
          ) : (
            ''
          )}
          <Typography variant="h5" component="h1"></Typography>
        </>
      ) : (
        ''
      )}
      <form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} className="form-group">
            <TextField
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              className="form-control input-lg stripe-payment-fields"
              required
              fullWidth
              id="card_holder_name"
              label="Card holder name"
              name="card_holder_name"
              InputProps={{
                className: options,
              }}
              // value={state.email}
              // onChange={event => {
              //     const value = event.target.value;
              //     return setState(prevState => {
              //         return {...prevState, email: value };
              //     })
              // }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className="form-group" fullWidth required>
              {/* <InputLabel htmlFor="my-input">Card number</InputLabel> */}
              <OutlinedInput
                id="stripeCardNumberWrapper"
                className="form-control input-lg stripe-payment-fields"
                label="Card number"
                inputComponent={CardNumberElement}
                name="stripeCardNumberWrapper"
                inputProps={{
                  options: options,
                  onReady: () => {
                    console.log('CardNumberElement [ready]');
                  },
                  onChange: (event) => {
                    console.log('CardNumberElement [change]', event);
                  },
                  onBlur: (event) => {
                    console.log('CardNumberElement [blur]', event);
                  },
                  onFocus: (event) => {
                    console.log('CardNumberElement [focus]', event);
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className="form-group" fullWidth required>
              <OutlinedInput
                id="stripeCardExpiryWrapper"
                className="form-control input-lg stripe-payment-fields"
                label="Card Expiry"
                inputComponent={CardExpiryElement}
                name="stripeCardExpiryWrapper"
                inputProps={{
                  options: options,
                  onReady: () => {
                    console.log('CardExpiryElement [ready]');
                  },
                  onChange: (event) => {
                    console.log('CardExpiryElement [change]', event);
                  },
                  onBlur: () => {
                    console.log('CardExpiryElement [blur]');
                  },
                  onFocus: () => {
                    console.log('CardExpiryElement [focus]');
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className="form-group" fullWidth required>
              <OutlinedInput
                id="stripeCardCvcElementWrapper"
                className="form-control input-lg stripe-payment-fields"
                label="Card CVC"
                inputComponent={CardCvcElement}
                name="stripeCardCvcElementWrapper"
                inputProps={{
                  options: options,
                  onReady: () => {
                    console.log('CardCvcElement [ready]');
                  },
                  onChange: (event) => {
                    console.log('CardCvcElement [change]', event);
                  },
                  onBlur: () => {
                    console.log('CardCvcElement [blur]');
                  },
                  onFocus: () => {
                    console.log('CardCvcElement [focus]');
                  },
                }}
              />
            </FormControl>
          </Grid>

          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              {!isEdit ? (
                <Link className={classes.goBackButton} variant="body2" onClick={goToPreviousStep}>
                  {t('go_back')}
                </Link>
              ) : (
                addPaymentFromDashboard && (
                  <>
                    <Link className={classes.goBackButton} variant="body2" onClick={cancelAddpaymentForm}>
                      Cancel
                    </Link>
                  </>
                )
              )}
            </Grid>
            {!loading ? (
              <Button variant="contained" color="primary" className={classes.submit} type="submit">
                {!isEdit ? t('submit') : addPaymentFromDashboard ? 'Add Payment' : 'Update'}
              </Button>
            ) : (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            )}
          </Grid>

          <Typography className={classes.errorMessage} component="h1" variant="subtitle1">
            {!isEdit ? state?.errorMessage || '' : error}
          </Typography>
          {!isEdit ? <Typography style={{ fontSize: '14px', color: 'grey' }}>{t('credit_card_para2')}</Typography> : ''}
        </Grid>
      </form>
    </>
  );
};

export default CreditCardInfo;

import React, { useState } from 'react';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styles from './signUp.styles';
import PanelWithImage from './PanelWithImage';
// import Copyright from '../shared-components/Copyright';
import logo from '../images/logo.png';

const useStyles = makeStyles(styles);

function ForgotPassword(props) {
  const classes = useStyles();
  const urlParams = queryString.parse(props.location.search);
  const accessToken = urlParams.access_token;

  const [state, setState] = useState({
    password: '',
    passwordConfirm: '',
    errorMessage: '',
  });

  function resetPassword() {
    if (state.password !== state.passwordConfirm) {
      setState((prevState) => {
        return { ...prevState, errorMessage: '"New password" and "Confirm new password" should be equal' };
      });
    }

    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/reset-password?access_token=${accessToken}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        newPassword: state.password,
      }),
    })
      .then((res) => {
        if (res.status === 404) {
          setState((prevState) => {
            return { ...prevState, errorMessage: 'Email not found' };
          });
        } else if (res.status === 401) {
          setState((prevState) => {
            return { ...prevState, errorMessage: 'Token to reset password has expired' };
          });
        } else {
          props.history.push('/login');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <PanelWithImage />
      <Grid item className={classes.rightPanelContainer} xs={12} md={6}>
        <div className={classes.rightPanel}>
          <div className={classes.header}>
            <div>
              <img className={classes.logo} src={logo} alt="CAN logo" />
            </div>
            <Typography component="h1" variant="h5">
              Reset password
            </Typography>
          </div>
          <form
            className={classes.form}
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  variant="outlined"
                  required
                  fullWidth
                  id="password"
                  label="New password"
                  name="password"
                  InputProps={{
                    className: classes.input,
                  }}
                  value={state.password}
                  onChange={(event) => {
                    const password = event.target.value;
                    return setState((prevState) => {
                      return { ...prevState, password };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  variant="outlined"
                  required
                  fullWidth
                  id="passwordConfirm"
                  label="Confirm new password"
                  name="passwordConfirm"
                  InputProps={{
                    className: classes.input,
                  }}
                  value={state.passwordConfirm}
                  onChange={(event) => {
                    const passwordConfirm = event.target.value;
                    return setState((prevState) => {
                      return { ...prevState, passwordConfirm };
                    });
                  }}
                />
              </Grid>
              <Grid item className={classes.errorMessageContainer}>
                <Typography className={classes.errorMessage} component="h1" variant="subtitle1">
                  {state.errorMessage}
                </Typography>
              </Grid>
              <Grid item className={`${classes.infoMessage} ${classes.grayText}`}>
                Password must be at least 6 characters long and contain at least 1 uppercase letter
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => resetPassword()}
              >
                Reset
              </Button>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;

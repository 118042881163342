import { isEmpty } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch, Link } from 'react-router-dom';
import './index.css';
import routes from './Router';
import * as serviceWorker from './serviceWorker';
import userService from './user.service.js';

const routing = (
  <Router>
    <div>
      <Switch>
        {routes.map((route, i) => (
          <Route key={i} {...route} />
        ))}
      </Switch>
    </div>
  </Router>
);

const authData = userService.getAuthData();

if (
  !isEmpty(authData.currentUserId) &&
  !isEmpty(authData.accessToken) &&
  authData.currentUserId !== 'null' &&
  authData.accessToken !== 'null'
) {
  fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/userInfo`, {
    method: 'GET',
    headers: {
      authorization: authData.accessToken + '~123123',
    },
  })
    .then((res) => res.json())
    .then(({ data: userData }) => {
      if (!userData.error) {
        userService.updateCurrentUser(userData);
      }

      ReactDOM.render(routing, document.getElementById('root'));
    })
    .catch((err) => {
      console.log(err);
    });
} else {
  ReactDOM.render(routing, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './signUp.styles';
// import logo from '../images/logo.png';

const useStyles = makeStyles(styles);

function PanelWithImage() {
    const classes = useStyles();

    return (
            <Grid
                item
                className={classes.leftPanelContainer}
                md={6}
            >
                <Grid
                    container
                    direction="column"
                    className={classes.leftPanel} 
                    // maxWidth="xs"
                    justify="center"
                    alignItems="center"
                >
                    {/*
                    <div>
                        <img src={logo} alt="CAN logo" />
                    </div>
                    */}
                    {/*
                    <Grid item className={classes.leftPanelText}>
                        Smart, connected, safe
                    </Grid>
                    */}
                    <Grid item className={classes.deviceImage} />
                </Grid>
            </Grid>
    );
}

export default PanelWithImage;

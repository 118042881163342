import React, { useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import colors from '../shared-colors';
import cancoLogo from '../images/canco.jpg';
import CanCoLogo from '../images/canco.png';

const CAN_COMPANION_TC = 'https://drive.google.com/file/d/1vVsahHL2h4AVoZstT-YBRVV5eNA-Lsr5/view?usp=sharing';
const CAN_COMPANION_URL = 'https://www.cancompanion.ai/';

const styles = (theme) => ({
  paper: {
    background: '#FFFFFF',
    // height: '620px',
    padding: '24px 32px 24px 32px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '@media (min-width:600px)': {
      // eslint-disable-line no-useless-computed-key
      width: '440px',
    },
    '@media (max-width:600px)': {
      // eslint-disable-line no-useless-computed-key
      width: '340px',
      padding: '16px',
      backgroundSize: 'contain',
      backgroundPosition: 'center top',
    },
    '@media (max-width:470px)': {
      // eslint-disable-line no-useless-computed-key
      width: 'auto',
    },
  },

  topContainer: {
    marginTop: '300px',
    width: '100%',
    '@media (max-width:600px)': {
      // eslint-disable-line no-useless-computed-key
      marginTop: '270px',
    },
    '@media (max-width:470px)': {
      // eslint-disable-line no-useless-computed-key
      marginTop: '230px',
    },
  },
  mainContainer: {
    // marginTop: '140px',
  },
  mainText: {
    fontSize: '24px',
    fontWeight: 800,
    marginBottom: '10px',
    textAlign: 'center',
    '@media (min-width:470px)': {
      // eslint-disable-line no-useless-computed-key
      width: '300px',
    },
  },
  explanationText: {
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '10px',
    color: colors.grayText,
    '@media (min-width:470px)': {
      // eslint-disable-line no-useless-computed-key
      width: '320px',
    },
  },
  stepCircle: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '6px',
    marginRight: '6px',
  },
  activeStepCircle: {
    background: colors.mainBlue,
  },
  lightCircle: {
    background: colors.blue,
  },

  footer: {
    marginTop: '20px',
  },
  cancelButton: {
    color: colors.grayText,
  },
  saveButton: {
    // marginLeft: '8px',
  },
});

const useStyles = makeStyles(styles);

function CanCompanionTCModal({ closeModal }) {
  const classes = useStyles();
  const steps = ['welcome', 'reports'];
  const images = [cancoLogo, CanCoLogo];
  const [state, setState] = useState({
    step: 0,
  });
  const lastStep = state.step === steps.length - 1;

  function nextStep() {
    if (lastStep) {
      closeModal(false);
    } else {
      setState({ step: (state.step += 1) });
    }
  }

  return (
    <div className={classes.paper}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
        <img
          src={images[state.step]}
          alt="logo"
          style={state.step === 1 ? { width: 'auto', height: '400px' } : { width: '300px', height: '250px' }}
        />
      </div>
      <Grid container item direction="column" justify="flex-start" alignItems="center">
        {steps[state.step] === 'welcome' && (
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.mainContainer}
          >
            <Grid item className={classes.mainText}>
              CAN Companion (Beta)
            </Grid>
            <Grid item className={classes.explanationText} style={{ textAlign: 'left' }}>
              <p style={{ marginBottom: '-5px' }}>
                The voice AI that cares.{' '}
                <a href={CAN_COMPANION_URL} target="_blank" rel="noreferrer">
                  Learn more about what it can do for you.
                </a>
              </p>
              <p style={{ marginBottom: '-5px' }}>
                Free for 3 months (Automatically bills monthly at $20/month after trial. Cancel Any time)
              </p>
              <p>
                By activating CAN Companion, you acknowledge that you have read and agreed to our{' '}
                <a href={CAN_COMPANION_TC} target="_blank" rel="noreferrer">
                  Terms of Use, Disclaimer, and Privacy Policy.
                </a>
              </p>
            </Grid>
          </Grid>
        )}

        {steps[state.step] === 'reports' && (
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.mainContainer}
          >
            <Grid item className={classes.mainText}>
              How to use CAN companion
            </Grid>
            <Grid item className={classes.explanationText}>
              To access CAN Companion from CAN Go Long press on the middle button
            </Grid>
          </Grid>
        )}
        <Grid item className={classes.stepNumberIndicator}>
          {_.map(steps, (step, index) => {
            if (state.step === index) {
              return <div className={`${classes.stepCircle} ${classes.activeStepCircle}`} />;
            }

            return <div className={`${classes.stepCircle} ${classes.lightCircle}`} />;
          })}
        </Grid>
      </Grid>
      <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.footer}>
        <Grid item>
          <Button
            fullWidth
            // variant="outlined"
            className={classes.cancelButton}
            onClick={() => {
              closeModal(true);
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button fullWidth color="primary" variant="contained" className={classes.saveButton} onClick={nextStep}>
            {lastStep ? 'Finish' : 'Activate'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default CanCompanionTCModal;

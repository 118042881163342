import colors from '../shared-colors';

export default (theme) => ({
  paper: {
    background: colors.white,
    padding: '24px 32px 24px 32px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      padding: '14px 16px 14px 16px',
    },
  },
  modal: {
    height: '100%',
    width: '100%',
  },
  title: {
    fontSize: '24px',
    fontWeight: 800,
  },
  closeIcon: {
    cursor: 'pointer',
  },
  userInfoContainer: {
    marginTop: '50px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '20px',
    },
  },
  caneImagePanel: {
    width: '200px',
  },
  deviceInfoPanel: {
    marginLeft: '30px',
    // width: '808px',
  },
  caneImage: {
    width: '200px',
    height: '200px',
    marginTop: '8px',
    marginBottom: '16px',
  },

  deviceInfoPart: {
    marginBottom: '24px',
  },
  deviceInfoPartTitle: {
    width: '396px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  deviceInfoInput: {
    background: colors.white,
    border: '1px solid #C2C8D6',
    boxSizing: 'border-box',
    height: '50px',
    boxShadow: 'inset 0px 2px 2px rgba(108, 131, 145, 0.19)',
    borderRadius: '2px',
    marginTop: '8px',
    width: '396px',
    fontSize: '16px',
    padding: '16px',
    '&:focus': {
      boxShadow: '0 0 3px steelblue',
      outlineWidth: '0px',
    },
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  deviceInfoPartRadio: {
    marginTop: '8px',
  },
  newCaregiverPhoneNumberParent: {
    width: '396px',
    border: '1px solid #C2C8D6',
    boxSizing: 'border-box',
    boxShadow: 'inset 0px 2px 2px rgba(108, 131, 145, 0.19)',
    height: '50px',
    paddingLeft: '20px',
    marginTop: '8px',
    '&:focus-within': {
      boxShadow: '0 0 3px steelblue',
    },
    '@media (max-width:600px)': {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  newCaregiverPhoneNumberChild: {
    border: 'none',
    boxShadow: 'none',
    marginTop: '0px',
    height: '0px',
    '&:focus': {
      outlineWidth: '0px',
      boxShadow: 'none',
    },
  },
  deviceInfoExplanationText: {
    marginTop: '8px',
    fontSize: '14px',
    width: '450px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      width: 'auto',
    },
  },

  caregiverContainer: {
    marginBottom: '24px',
    display: 'flex',

    ['@media (max-width:1050px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'inline-block',
      marginBottom: '10px',
    },

    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'inline-block',
      marginBottom: '10px',
    },
    // marginLeft: '12px',
  },
  infoIcon: {
    width: '15px',
    height: '15px',
    color: colors.lightGray,
    marginLeft: '8px',
    marginBottom: '-2px',
    cursor: 'pointer',
    '&:hover': {
      color: 'lightgray',
    },
  },
  deleteIcon: {
    width: '18px',
    height: '18px',
    color: colors.red,
    marginRight: '5px',
    marginBottom: '-2px',
  },
  caregiverErrorMessage: {
    fontSize: '18px',
    marginBottom: '12px',
    color: colors.red,
  },
  caregiverImage: {
    height: '32px',
    width: '32px',
    borderRadius: '50%',
  },
  caregiverName: {
    width: '200px',
    fontSize: '18px',
    marginLeft: '8px',
    display: 'inline-block',
    marginTop: '5px',
  },
  caregiverPhoneNumber: {
    width: '180px',
    fontSize: '18px',
    display: 'inline-block',
    marginTop: '5px',
  },
  radioButton: {
    width: '210px',
    display: 'inline-block',
  },
  deleteCaregiverButton: {
    color: colors.red,
    cursor: 'pointer',
    fontWeight: 800,
    width: 'auto',
    display: 'inline-block',
  },
  newCaregiverPhoneNumber: {
    width: '350px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  caregiverInfoTextPart: {
    marginTop: '25px',
  },

  deleteCaregiverPaper: {
    background: colors.white,
    padding: '24px 32px 24px 32px',
    width: '460px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  redButton: {
    color: colors.red,
    marginRight: '8px',
    width: '210px',
    height: '40px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      marginBottom: '10px',
    },
  },
  deviceInfoSection: {
    marginTop: '40px',
  },
  deviceInfoSectionTitle: {
    fontSize: '18px',
    fontWeight: 800,
  },
  divider: {
    marginTop: '16px',
    marginBottom: '24px',
  },

  configsContainer: {
    marginTop: '24px',
  },
  configSwitches: {
    width: '150px',
  },
  configCheckboxes: {
    width: '400px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  configSwitchesTitle: {
    marginTop: '4px',
  },

  footer: {
    marginTop: '40px',
  },
  saveAndCancelButtons: {
    width: '166px',
  },
  saveButton: {
    marginLeft: '8px',
  },
  cancelButton: {
    marginLeft: '8px',
  },

  grayText: {
    color: colors.grayText,
  },

  editIcon: {
    width: '18px',
    height: '18px',
    color: colors.mainBlue,
    marginRight: '5px',
    marginBottom: '-2px',
  },
  editCaregiverButton: {
    color: colors.mainBlue,
    cursor: 'pointer',
    fontWeight: 800,
    width: '150px',
    display: 'inline-block',
  },
  googleInput: {
    width: '500px',
    height: '40px',
    marginTop: '10px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '350px',
    },
    ['@media (max-width:400px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '250px',
    },
  },
  configStatusIcons: {
    maxHeight: '20px',
    width: 'auto',
  },
  assignCaregiverButton: {
    color: '#188350',
    cursor: 'pointer',
    fontWeight: 800,
    width: '150px',
    display: 'inline-block',
  },
});

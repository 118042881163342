import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import inputValidation from '../input-validation.service.js';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './signUp.styles';
import PhoneInput from 'react-phone-number-input';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import StripeCardForm from './StripeCardForm';
import FinalMessage from './FinalMessage.jsx';
import axios from 'axios';

const useStyles = makeStyles(styles);
const steps = ['mainInfo', 'creditCardInfo', 'welcome'];

const SignUpWithoutDevice = ({ handleGoBack, props }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = useState({
    step: steps[0],
    phonenumber: '',
    name: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    errorMessage: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const createCard = async (email, token) => {
    try {
      const result = await axios.post(process.env.REACT_APP_EXPRESS_URL + '/api/users/check-card', {
        email,
        token,
        companionUserPayment: true,
      });
      return result;
    } catch (err) {
      setState((prevState) => {
        return {
          ...prevState,
          errorMessage: 'Please check card Expiration or Card number',
        };
      });
    }
  };

  const createUser = async (userInfo) => {
    try {
      const result = await axios.post(process.env.REACT_APP_EXPRESS_URL + '/api/users/companion_signup', {
        phone: userInfo.phonenumber,
        email: userInfo.email,
        password: userInfo.password,
        stripeInfo: userInfo.stripeInfo,
        name: userInfo.name,
      });
      return result;
    } catch (err) {
      setState((prevState) => {
        return {
          ...prevState,
          errorMessage: err.response.data.message || 'Something went wrong!',
        };
      });
    }
  };

  function goToPaymentPage() {
    if (!state.phonenumber || !state.email || !state.password) {
      setState((prevState) => {
        return {
          ...prevState,
          errorMessage: 'Please, fill all required fields',
        };
      });
    } else if (state.password !== state.confirmPassword) {
      return setState((prevState) => {
        return {
          ...prevState,
          errorMessage: 'The password confirmation does not match',
        };
      });
    } else if (inputValidation.checkPasswordValidation(state.password) != null) {
      return setState((prevState) => {
        return {
          ...prevState,
          errorMessage:
            'Password must be of 8-32 characters and must contain at least one capital letter, one small letter, a symbol and a number',
        };
      });
    } else if (state.email.toLowerCase() !== state.confirmEmail.toLowerCase()) {
      return setState((prevState) => {
        return {
          ...prevState,
          errorMessage: 'The Email confirmation does not match',
        };
      });
    } else if (!inputValidation.email(state.email)) {
      return setState((prevState) => {
        return { ...prevState, errorMessage: 'Invalid Email' };
      });
    } else {
      setState((prev) => {
        return { ...prev, step: steps[1], errorMessage: '' };
      });
    }
  }

  const goToPreviousStep = () => {
    setState((prev) => {
      return { ...prev, step: steps[0], errorMessage: '' };
    });
  };

  const signUp = async (userInfo) => {
    try {
      let result = await createCard(userInfo.email, userInfo.stripeInfo.cardToken);

      if (!result) {
        return;
      }
      if (!result?.data?.results?.success) {
        return setState((prevState) => {
          return { ...prevState, errorMessage: 'Credit card info not valid' };
        });
      } else {
        userInfo.stripeInfo = result?.data?.results?.data;
      }

      const user = await createUser(userInfo);
      console.log('user=====>', user);
      if (user.status === 200) {
        setState((prev) => {
          return { ...prev, step: steps[2], errorMessage: '' };
        });
      }
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          errorMessage: error?.response?.data?.message || 'Something went wrong!',
        };
      });
      console.log(error);
    }
  };

  return (
    <>
      {state.step === 'mainInfo' && (
        <form
          className={classes.form}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            goToPaymentPage();
          }}
        >
          <div className={classes.stepText}>{`${t('step')} 1/2`}</div>
          <Typography className={classes.mainText} component="h1" variant="h4">
            {t('enter_info_to_create_account')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel htmlFor="my-input">Phone Number</InputLabel>
                <OutlinedInput
                  id="phone"
                  inputComponent={PhoneInput}
                  name="phone"
                  value={state.phonenumber}
                  autoComplete="phone"
                  inputProps={{
                    placeholder: '(650) 123-1234',
                    defaultCountry: 'US',
                    addInternationalOption: false,
                    limitMaxLength: true,
                    style: {
                      display: 'inline-flex',
                      background: 'white',
                    },
                    numberInputProps: {
                      className: classes.phoneNumberInput,
                    },
                    onChange: (value) => {
                      return setState((prevState) => {
                        return { ...prevState, phonenumber: value };
                      });
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="name"
                label={t('name')}
                name="name"
                autoComplete="name"
                InputProps={{
                  className: classes.input,
                }}
                value={state.name}
                onChange={(event) => {
                  const value = event.target.value;
                  return setState((prevState) => {
                    return { ...prevState, name: value };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={t('email')}
                name="email"
                autoComplete="email"
                InputProps={{
                  className: classes.input,
                }}
                value={state.email}
                onChange={(event) => {
                  const value = event.target.value;
                  return setState((prevState) => {
                    return { ...prevState, email: value };
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={t('confirm_email')}
                name="email"
                InputProps={{
                  className: classes.input,
                }}
                value={state.confirmEmail}
                onChange={(event) => {
                  const value = event.target.value;
                  return setState((prevState) => {
                    return { ...prevState, confirmEmail: value };
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">{t('password')}</InputLabel>
                <OutlinedInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  style={{ background: '#fff' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t('password')}
                  value={state.password}
                  onChange={(event) => {
                    const value = event.target.value;
                    return setState((prevState) => {
                      return { ...prevState, password: value };
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">{t('confirm_password')}</InputLabel>
                <OutlinedInput
                  id="password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  style={{ background: '#fff' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                  value={state.confirmPassword}
                  onChange={(event) => {
                    const value = event.target.value;
                    return setState((prevState) => {
                      return { ...prevState, confirmPassword: value };
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Typography className={classes.errorMessage} component="h1" variant="subtitle1">
            {state.errorMessage}
          </Typography>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Link className={classes.goBackButton} variant="body2" onClick={handleGoBack}>
                {t('go_back')}
              </Link>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                if (state.phonenumber && state.phonenumber.length < 10) {
                  return setState((prevState) => {
                    return {
                      ...prevState,
                      errorMessage: 'Please enter 10 digits phone number',
                    };
                  });
                } else {
                  return goToPaymentPage();
                }
              }}
            >
              {t('next_step')}
            </Button>
          </Grid>
        </form>
      )}
      {state.step === 'creditCardInfo' && (
        <StripeCardForm
          signUp={signUp}
          state={state}
          setState={setState}
          goToPreviousStep={goToPreviousStep}
          companionUser={true}
        />
      )}
      {state.step === 'welcome' && (
        <div>
          <FinalMessage email={state.email} type={'companion'} />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => props.history.push('/login')}
          >
            {t('login_to_account')}
          </Button>
        </div>
      )}
    </>
  );
};

export default SignUpWithoutDevice;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import 'react-phone-number-input/style.css';
import styles from './signUp.styles.js';
import caneFrontPanelImage from '../images/cane_front_panel.png';
import { useTranslation } from 'react-i18next';
// import privacyPolicy from '../Terms_and_conditions/2019-09-28 CAN Mobilities PP v2.pdf';
// import termsOfUse from '../Terms_and_conditions/2019-09-28 CAN Mobilities TOU v2.pdf';

const useStyles = makeStyles(styles);

function CheckPin({ pin, checkPin, onChange, buttonDisabled: buttonDisabled, errorMessage }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const privacyPolicy = 'https://can.co/pages/privacy-policy';
  const termsOfUse = 'https://can.co/pages/terms-of-use';
  const [isTermsAgreed, setTerms] = React.useState(false);
  return (
    <form
      className={classes.form}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        this.checkPIN();
      }}
    >
      <Typography className={classes.mainText} component="h1" variant="h4">
        {/* Let’s activate your CAN Go&trade; */}
        {t('lets_activate_can')}&trade;
      </Typography>
      <Typography component="h1" variant="subtitle1" className={classes.explanationText}>
        {t('can_activation_para')}
      </Typography>
      <Typography component="h1" variant="subtitle1" className={classes.explanationText}>
        {t('can_activation_para2')}
      </Typography>
      <Grid container justify="center">
        <img className={classes.caneFrontPanelImage} src={caneFrontPanelImage} alt="Cane front panel" />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="devicePIN"
            label={t('pin')}
            type="devicePIN"
            id="devicePIN"
            maxLength="6"
            defaultValue={pin}
            onChange={onChange}
            inputProps={{
              maxLength: 6,
            }}
            InputProps={{
              className: `${classes.input} ${classes.inputPIN}`,
            }}
          />
        </Grid>
      </Grid>

      <div className={`${classes.termsAndConditionsFooter} ${classes.grayText}`}>
        <input
          style={{}}
          onChange={(e) => {
            setTerms(e.target.checked);
          }}
          type="checkbox"
        ></input>
        {t('agreement_checkbox_text')}{' '}
        <a href={termsOfUse} target="_blank" rel="noopener noreferrer" className={classes.grayText}>
          {t('terms_of_use')}
        </a>
        &nbsp; {t('and')}{' '}
        <a href={privacyPolicy} target="_blank" rel="noopener noreferrer" className={classes.grayText}>
          {t('privacy_policy')}
        </a>
      </div>
      <Typography className={classes.errorMessage} component="h1" variant="subtitle1">
        {errorMessage}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={buttonDisabled || !isTermsAgreed}
        onClick={() => checkPin()}
      >
        {buttonDisabled || !isTermsAgreed ? t('enter_pin_and_agree') : t('next_step')}
      </Button>
      <Grid container justify="flex-end" className={classes.bottomLinks}>
        <Grid item>
          <Link href="/#/login" variant="body2">
            {t('already_have_account')}
          </Link>{' '}
          <span>or</span>{' '}
          <Link href="/#/companion-sign-up" variant="body2">
            Companion Sign-up
          </Link>
        </Grid>
      </Grid>
      <h5 style={{ marginTop: '-15px', color: '#443c3b' }}>
        {t('need_help')},{' '}
        <a href="" rel="noreferrer">
          {t('user_guide')}
        </a>{' '}
        ,{t('call')} <a href="tel:+18333226225">1 (833) 322-6225</a> or {t('email')}{' '}
        <a href="mailto:hi@can.co">hi@can.co</a>
      </h5>
    </form>
  );
}

export default CheckPin;

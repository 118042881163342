import React, { useState } from 'react';
import { map } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import MedicationTable from './components/MedicationTable.jsx';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const rows = [
  {
    id: 1,
    name: 'Pandol',
    type: '1',
    frequency: '2',
    startDate: '11',
  },
];

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'frequency',
    numeric: false,
    disablePadding: false,
    label: 'Frequency',
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: false,
    label: 'Start Date',
  },
];

function ShowMedications({ refresh, user, closeModal, setShowMedicationTable }) {
  const { t } = useTranslation();
  const [medications, setMedications] = useState([]);
  const [medicationsLoader, setMedicationsLoader] = useState(false);
  const getMedications = React.useCallback(async () => {
    const userId = user?.id || '';
    setMedicationsLoader(true);
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${userId}/medications`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('auth'),
        },
      });
      data = map(data?.results, (item) => ({
        id: item?.id || 0,
        name: item?.name || '',
        type: item?.type || '',
        frequency: item?.frequency || 1,
        startDate: item?.startDate || '',
      }));
      setMedications(data || []);
    } catch (exception) {
      console.log('Exception', exception);
    }
    setMedicationsLoader(false);
  }, [user]);

  React.useEffect(() => {
    getMedications();
  }, [getMedications]);

  const handleDeleteMedications = async (selected) => {
    const userId = user?.id || '';
    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_EXPRESS_URL}/api/users/${userId}/deleteMedications`,
        {
          selected: JSON.stringify(selected),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: localStorage.getItem('auth'),
          },
        },
      );
      toast(data?.results);
      refresh();
      closeModal();
    } catch (exception) {
      console.log('Exception', exception);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      {medicationsLoader ? (
        <div
          style={{ width: '500px', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <CircularProgress style={{ width: '150px', height: '150px', borderRadius: '50%' }} />
        </div>
      ) : (
        <MedicationTable
          rows={medications || []}
          closeModal={closeModal}
          headCells={headCells}
          deleteRows={(numSelected) => handleDeleteMedications(numSelected)}
        />
      )}
      <Grid item>
        <Button fullWidth color="primary" variant="contained" onClick={setShowMedicationTable}>
          {t('add_medication')}
        </Button>
      </Grid>
    </div>
  );
}

export default ShowMedications;

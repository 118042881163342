import React, { useState } from "react";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import inputValidation from "../input-validation.service.js";
import styles from "./signUp.styles.js";

const useStyles = makeStyles(styles);

function CaregiverSignUp({ signUp }) {
  const classes = useStyles();
  let phoneNumber = window.location.href
    .split("phoneNumber=")[1]
    .split("&email")[0];
  console.log("PhoneNumber", phoneNumber);
  let email = window.location.href.split("email=")[1];
  console.log("email", email);
  const [state, setState] = useState({
    phonenumber: phoneNumber,
    email: email,
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    errorMessage: "",
  });

  return (
    <div className={classes.scrollBehavior}>
      <form
        className={classes.form}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          signUp(state);
        }}
      >
        <Typography className={classes.mainText} component="h1" variant="h4">
          Caregiver information
        </Typography>
        <Typography component="h1" variant="subtitle1">
          Enter the general information about yourself to create your account
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              autoComplete="fname"
              name="firstName"
              id="firstName"
              label="First Name"
              autoFocus
              InputProps={{
                className: classes.input,
              }}
              value={state.firstName}
              onChange={(event) =>
                inputValidation.name(event, "firstName", setState)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              InputProps={{
                className: classes.input,
              }}
              value={state.lastName}
              onChange={(event) =>
                inputValidation.name(event, "lastName", setState)
              }
            />
          </Grid>
          {/* <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        InputProps={{
                            className: classes.input,
                        }}
                        value={state.email}
                        onChange={event => {
                            const value = event.target.value;
                            return setState(prevState => {
                                return {...prevState, email: value };
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Confirm Email"
                        name="email"
                        autoComplete="email"
                        InputProps={{
                            className: classes.input,
                        }}
                        value={state.confirmEmail}
                        onChange={event => {
                            const value = event.target.value;
                            return setState(prevState => {
                                return {...prevState, confirmEmail: value };
                            })
                        }}
                    />
                </Grid> */}
          <Grid item xs={12}>
            {/* <FormControl fullWidth required>
                        <InputLabel htmlFor="my-input">Phone Number</InputLabel>
                        <OutlinedInput 
                            id="phone"
                            inputComponent={PhoneInput}
                            name="phone"
                            autoComplete="phone"
                            inputProps={{
                                placeholder: "(650) 123-1234",
                                defaultCountry: "US",
                                limitMaxLength: true,
                                addInternationalOption: false,
                                style: {
                                    display: 'inline-flex',
                                    background: 'white',
                                },
                                numberInputProps: {
                                    className: classes.phoneNumberInput,
                                },
                                onChange: value => {
                                    return setState(prevState => {
                                        return {...prevState, phonenumber: value };
                                    });
                                }
                            }}
                        />
                    </FormControl> */}
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputProps={{
                className: classes.input,
              }}
              value={state.password}
              onChange={(event) => {
                const value = event.target.value;
                return setState((prevState) => {
                  return { ...prevState, password: value };
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              // autoComplete="current-password"
              InputProps={{
                className: classes.input,
              }}
              // value={state.password}
              onChange={(event) => {
                const value = event.target.value;
                return setState((prevState) => {
                  return { ...prevState, confirmPassword: value };
                });
              }}
            />
          </Grid>
        </Grid>
        <Typography
          className={classes.errorMessage}
          component="h1"
          variant="subtitle1"
        >
          {state.errorMessage}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => {
            if (state.password !== state.confirmPassword) {
              return setState((prevState) => {
                return {
                  ...prevState,
                  errorMessage: "Password confirmation does not match",
                };
              });
            } else if (
              inputValidation.checkPasswordValidation(state.password) !== null
            ) {
              return setState((prevState) => {
                return {
                  ...prevState,
                  errorMessage:
                    "Password should be of 8-32 characters and must contain at least one capital letter, one small letter and a symbol",
                };
              });
            } else {
              signUp(state);
            }
          }}
        >
          Sign up
        </Button>
        {/*
            <Grid container justify="flex-end">
                <Grid item>
                    <Link href="/#/login" variant="body2">
                        Already have an account? Sign in
                    </Link>
                </Grid>
            </Grid>
            */}
      </form>
    </div>
  );
}

export default CaregiverSignUp;

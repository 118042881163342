import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ar from './locales/ar.json';
import es from './locales/es.json';

i18n.use(initReactI18next).init({
  resources: {
    en,
    ar,
    es,
  },
  lng: localStorage.getItem('languageCode'),
  // lng: Intl.DateTimeFormat().resolvedOptions().timeZone === 'Asia/Riyadh' ? 'ar' : 'en',
  fallbackLng: 'en',
});

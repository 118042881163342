import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import userService from '../user.service.js';
import { makeStyles } from '@material-ui/core/styles';
import styles from './editUser.styles.js';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CloseIcon from '@material-ui/icons/Close';
import boyImage from '../images/boy.png';
import girlImage from '../images/girl.png';
import ChangePassword from './ChangePassword';
import SaveChanges from '../shared-components/SaveChanges';
import StripeCardForm from '../SignUp_Login/StripeCardForm.jsx';
import EditIcon from '@material-ui/icons/Edit';
import { TextField, Typography } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { Box, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { getSignedImage } from '../utils/index.js';

const useStyles = makeStyles(styles);

const APP_LANGUAGES = [
  { lang: 'English', code: 'en' },
  { lang: 'عربي', code: 'ar' },
  { lang: 'española', code: 'es' },
];

function updateUserInfo(userInfo, user) {
  const authData = userService.getAuthData();
  userInfo.age = moment.duration(moment(Date.now()).diff(moment(userInfo.birthdate))).asYears();
  userInfo.age = parseInt(userInfo.age);
  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/updateUserInfo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: authData.accessToken,
    },
    body: JSON.stringify(userInfo),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('Incorrect email or password');
      }

      _.forEach(userInfo, (value, key) => {
        user.userInfo[key] = value;
      });

      return res.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

function changeEmail(email, user) {
  const authData = userService.getAuthData();

  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/changeEmail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: authData.accessToken,
    },
    body: JSON.stringify({ email }),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('Incorrect email or password');
      }

      user.email = email;

      return res.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

function changePhoneNumber(phoneNumber, user) {
  const authData = userService.getAuthData();

  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/changePhoneNumber`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: authData.accessToken,
    },
    body: JSON.stringify({ phoneNumber }),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('Incorrect email or password');
      }

      user.username = phoneNumber;

      return res.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

function EditUser({ user, closeModal, refresh, profileImage }) {
  const classes = useStyles();
  const [state, setState] = useState({
    showChangePassword: false,
    showSaveChanges: false,
  });
  const [loading, setLoading] = React.useState(false);
  const [startDate, setStartDate] = useState(user?.userInfo.birthdate ? new Date(user.userInfo.birthdate) : new Date());
  const [imageUpload, setImageUpload] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('languageCode') || 'en');
  const [userImage, setUserImage] = useState(user.userInfo && user.userInfo.imageUrl);

  // let userImage = user.userInfo && user.userInfo.imageUrl;
  // const birthdate = user.userInfo.birthdate || new Date();

  const getImage = React.useCallback(async () => {
    const authData = userService.getAuthData();
    try {
      const { data } = await getSignedImage(authData.currentUserId, userImage);
      setUserImage(data?.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    getImage();
  }, [getImage]);

  if (!userImage) {
    userImage = (user.userInfo && user.userInfo.gender) === 'female' ? girlImage : boyImage;
  }

  const newUserInfo = {
    userInfo: state.userInfo || _.clone(user.userInfo) || {},
    email: state.email || user.email,
    username: state.username || user.username,
  };

  function closeChangePassword() {
    setState((prevState) => {
      return { ...newUserInfo, showChangePassword: false };
    });
  }

  function closeSaveChanges() {
    setState((prevState) => {
      return { ...newUserInfo, showSaveChanges: false };
    });
  }

  function checkChanges() {
    if (
      !_.isEqual(user.userInfo, newUserInfo.userInfo) ||
      user.email !== newUserInfo.email ||
      user.username !== newUserInfo.username
    ) {
      setState((prevState) => {
        return { ...newUserInfo, showSaveChanges: true };
      });
    } else {
      closeModal();
    }
  }

  function uploadImage(image) {
    const authData = userService.getAuthData();
    let getUrl = null;
    let postUrl = null;
    let imageUrl = null;
    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${authData.currentUserId}/getSignedImageURL`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: authData.accessToken,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Incorrect email or password');
        }

        return res.json();
      })
      .then(({ data }) => {
        getUrl = data.getURL;
        postUrl = data.postURL;

        console.log('lets take a look', postUrl);
        console.log(data);
        setImageUpload(true);
        // let result = await axios.put(postUrl, )
        return fetch(postUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'image/jpeg',
          },

          body: image,
        });
      })
      .then((res) => {
        imageUrl = res.url.split('?')[0];
        return getSignedImage(authData.currentUserId, imageUrl);
      })
      .then(({ data }) => {
        getUrl = data?.data;
        updateUserInfo({ imageUrl: imageUrl }, user);
      })
      .then((res) => {
        setUserImage(getUrl);
        setImageUpload(false);
        newUserInfo.userInfo.imageUrl = imageUrl;
        setState(newUserInfo);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const updateStripeInfo = async (email, token) => {
    setLoading(true);
    try {
      const result = await axios.post(
        process.env.REACT_APP_EXPRESS_URL + '/api/users/update-Stripe-info',
        {
          email,
          editedToken: token,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: localStorage.getItem('auth'),
          },
        },
      );
      refresh();
      console.log(result);
      return result;
    } catch (exception) {
      console.log('Exception');
    }
  };

  const addStripeInfo = async (email, token) => {
    setLoading(true);
    try {
      const result = await axios.post(
        process.env.REACT_APP_EXPRESS_URL + '/api/users/check-card',
        {
          email,
          token,
          addPaymentFromDashboard: true,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: localStorage.getItem('auth'),
          },
        },
      );
      refresh();
      console.log(result);
      return result;
    } catch (exception) {
      console.log('Exception');
    }
  };

  const editCard = (token) => {
    updateStripeInfo(user.email, token).then((result) => {
      if (result?.status == 204) {
        setMessage('Card info updated');
      } else {
        setMessage('Something went wrong');
      }
      setLoading(false);
    });
  };

  const addPaymentHandler = (token) => {
    addStripeInfo(user.email, token).then((result) => {
      if (result?.status === 204) {
        setAddPayment(false);
        setMessage('Card info added!');
      } else {
        setMessage('Something went wrong');
      }
      setLoading(false);
    });
  };

  function save() {
    const promisees = [];
    promisees.push(updateUserInfo(newUserInfo.userInfo, user));

    if (user.email !== newUserInfo.email) {
      promisees.push(changeEmail(newUserInfo.email, user));
    }

    if (user.username !== newUserInfo.username) {
      promisees.push(changePhoneNumber(newUserInfo.username, user));
    }
    i18next.changeLanguage(selectedLanguage);
    localStorage.setItem('languageCode', selectedLanguage);

    return Promise.all(promisees).then(() => {
      closeModal();
      profileImage(newUserInfo.userInfo.imageUrl);
    });
  }
  const [editPayment, setEditPayment] = React.useState(false);
  const [addPayment, setAddPayment] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const { t } = useTranslation();
  return (
    <div className={classes.paper}>
      <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
        <Grid item className={classes.title}>
          {t('edit_your_profile')}
        </Grid>
        <Grid item className={classes.closeIcon} onClick={checkChanges}>
          <CloseIcon />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={classes.userInfoContainer}
      >
        <Grid item className={classes.userImagePanel}>
          <div className={classes.profileImageText}>{t('profile_image')}</div>
          <div
            style={{
              backgroundImage: `url(${userImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: '50%',
            }}
            className={classes.userImage}
          ></div>
          {!imageUpload ? (
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              component="label"
              className={classes.uploadPhotoButton}
              onClick={() => console.log('IMAGE')}
            >
              {t('upload_a_photo')}
              <input type="file" style={{ display: 'none' }} onChange={(event) => uploadImage(event.target.files[0])} />
            </Button>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>
        <Grid item className={classes.userInfoPanel}>
          <div className={classes.userInfoPart}>
            <div className={classes.userInfoPartTitle}>{t('first_name')}</div>
            <input
              defaultValue={user.userInfo.firstName}
              className={classes.userInfoPartInput}
              onChange={(event) => (newUserInfo.userInfo.firstName = event.target.value)}
            />
          </div>
          <div className={classes.userInfoPart}>
            <div className={classes.userInfoPartTitle}>{t('last_name')}</div>
            <input
              defaultValue={user.userInfo.lastName}
              className={classes.userInfoPartInput}
              onChange={(event) => (newUserInfo.userInfo.lastName = event.target.value)}
            />
          </div>
          <div className={classes.userInfoPart}>
            <div className={classes.userInfoPartTitle}>{t('phone_number')}</div>
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="US"
              // flagUrl="https://flag.pk/flags/4x3/{xx}.svg"
              addInternationalOption={true}
              limitMaxLength={true}
              className={classes.phoneInputParent}
              value={user.username}
              numberInputProps={{
                className: `${classes.userInfoPartInput} ${classes.phoneInputChild}`,
              }}
              onChange={(value) => {
                newUserInfo.username = value;
              }}
            />
          </div>
          <div className={classes.userInfoPart}>
            <div className={classes.userInfoPartTitle}>{t('email')}</div>
            <input
              defaultValue={user.email}
              className={classes.userInfoPartInput}
              onChange={(event) => (newUserInfo.email = event.target.value)}
            />
          </div>
          <Button
            variant="outlined"
            color="primary"
            component="label"
            className={classes.changePasswordButton}
            onClick={() =>
              setState((prevState) => {
                return { ...prevState, showChangePassword: true };
              })
            }
          >
            {t('change_passsword')}
          </Button>
          <div className={classes.userInfoPart}>
            <div className={classes.userInfoPartTitle}>{t('gender')}</div>
            <RadioGroup
              aria-label="gender"
              name="gender"
              row
              className={classes.userInfoPartRadio}
              value={newUserInfo.userInfo.gender}
              onChange={(event) => {
                newUserInfo.userInfo.gender = event.target.value;
                setState(newUserInfo);
              }}
            >
              <FormControlLabel value="male" control={<Radio color="primary" />} label={t('male')} />
              <FormControlLabel value="female" control={<Radio color="primary" />} label={t('female')} />
              <FormControlLabel value="" control={<Radio color="primary" />} label={t('prefer_not_to_answer')} />
            </RadioGroup>
          </div>
          <div className={classes.userInfoPart}>
            <div className={classes.userInfoPartTitle}>{t('dob')}</div>
            <DatePicker
              className={classes.userInfoPartInput}
              selected={startDate}
              onChange={(date) => {
                newUserInfo.userInfo.birthdate = date;
                setState(newUserInfo);
                setStartDate(date);
              }}
            />
          </div>
          <div className={classes.userInfoPart}>
            <div className={classes.userInfoPartTitle}>{t('weight')}</div>
            <input
              defaultValue={user.userInfo.weight}
              className={classes.userInfoPartInput}
              onChange={(event) => (newUserInfo.userInfo.weight = event.target.value)}
            />
          </div>
          <div className={classes.userInfoPart}>
            <div className={classes.userInfoPartTitle}>{t('height')}</div>
            <input
              defaultValue={user.userInfo.height}
              className={classes.userInfoPartInput}
              onChange={(event) => (newUserInfo.userInfo.height = event.target.value)}
            />
          </div>
          <div className={classes.userInfoPart}>
            <div className={classes.userInfoPartTitle}>{t('language')}</div>
            <select
              className={classes.userInfoPartInput}
              style={{ paddingBlock: 0 }}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              value={selectedLanguage}
            >
              {APP_LANGUAGES.map(({ lang, code }) => (
                <option value={code} key={code}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.userInfoPart}>
            {user?.stripeInfo?.card ? (
              <Grid container spacing={1}>
                <Typography variant="h6" className={classes.userInfoPartTitle}>
                  {' '}
                  {t('payment_information')}
                </Typography>
                <Grid item xs={12}>
                  <TextField
                    disabled={true}
                    placeholder="With normal TextField"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        paddingLeft: 0,
                      },
                    }}
                    value={'*********' + user?.stripeInfo?.card?.last4}
                    InputProps={{
                      style: { fontSize: '18px' },
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            height={20}
                            width={40}
                            src={
                              user?.stripeInfo?.card?.brand === 'Visa'
                                ? require('./visaCard.png')
                                : require('./masterCard.jpg')
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <span
                    onClick={() => {
                      setEditPayment(!editPayment);
                    }}
                  >
                    {' '}
                    <EditIcon style={{ color: '#3f51b5' }} />
                  </span>
                </Grid>
              </Grid>
            ) : (
              !addPayment && (
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  onClick={() => setAddPayment(!addPayment)}
                >
                  Add payment method
                </Button>
              )
            )}
          </div>
          <div>
            {editPayment ? (
              <>
                <StripeCardForm loading={loading} editCard={editCard} isEdit={true} />
                <p style={{ color: '#3f51b5' }}>{message}</p>
              </>
            ) : (
              ''
            )}
            {addPayment && (
              <>
                <h3>Add Payment</h3>
                <StripeCardForm
                  loading={loading}
                  editCard={addPaymentHandler}
                  isEdit={true}
                  addPaymentFromDashboard={true}
                  cancelAddpaymentForm={() => setAddPayment(false)}
                />
                <p style={{ color: '#3f51b5' }}>{message}</p>
              </>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        item
        style={{ marginTop: '20px' }}
        direction="row"
        justify="flex-end"
        alignItems="center"
        className={classes.footer}
      >
        <Grid item>
          <Button fullWidth variant="outlined" className={classes.cancelButton} onClick={checkChanges}>
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button fullWidth color="primary" variant="contained" className={classes.saveButton} onClick={save}>
            {t('save')}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        className={classes.modal}
        open={state.showChangePassword}
        // fullWidth={true}
        maxWidth={false}
        // onClose={handleClose}
      >
        <ChangePassword user={user} closeModal={closeChangePassword}></ChangePassword>
      </Dialog>
      <Dialog
        className={classes.modal}
        open={state.showSaveChanges}
        // fullWidth={true}
        maxWidth={false}
        // onClose={handleClose}
      >
        <SaveChanges action={save} closeModal={closeSaveChanges} closeParentModal={closeModal}></SaveChanges>
      </Dialog>
    </div>
  );
}

export default EditUser;

import _ from 'lodash';

const user = {
    accessToken: null,
    rememberMe: false,
    currentUserId: null,
    currentUser: null,
}

const getCurrentUser = () => user.currentUser;
const updateCurrentUser = (obj) => user.currentUser = obj;
const getAuthData = () => ({
    accessToken: user.accessToken,
    rememberMe: user.rememberMe,
    currentUserId: user.currentUserId,
})
const updateAuthData = (obj) => {
    if (obj.accessToken) user.accessToken = obj.accessToken;
    if (obj.currentUserId) user.currentUserId = obj.currentUserId;
    if (obj.rememberMe) user.rememberMe = obj.rememberMe;
}
const updateStorage = (obj) => {
    const storage = obj.rememberMe ? localStorage : sessionStorage;

    if (obj.accessToken) storage['$LoopBack$accessTokenId'] = obj.accessToken;
    if (obj.currentUserId) storage['$LoopBack$currentUserId'] = obj.currentUserId;
    if (obj.rememberMe) storage['$LoopBack$rememberMe'] = obj.rememberMe;
}
const clearUserData = () => {
    const storages = [localStorage, sessionStorage];

    _.forEach(storages, (storage) => {
        storage['$LoopBack$accessTokenId'] = null;
        storage['$LoopBack$currentUserId'] = null;
        storage['$LoopBack$rememberMe'] = null;
    });

    user.accessToken = null;
    user.rememberMe = false;
    user.currentUserId = null;
    user.currentUser = null;
}

// read localStorage or SessionStorage for auth data
user.accessToken = localStorage['$LoopBack$accessTokenId'] || sessionStorage['$LoopBack$accessTokenId'];
user.currentUserId = localStorage['$LoopBack$currentUserId'] || sessionStorage['$LoopBack$currentUserId'];
user.rememberMe = localStorage['$LoopBack$rememberMe'] || sessionStorage['$LoopBack$rememberMe'];


export default {
    getCurrentUser,
    updateCurrentUser,
    getAuthData,
    updateAuthData,
    updateStorage,
    clearUserData,
}

import React, { useState } from 'react';
//comment
import _, { capitalize } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import { Fade } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import Device from './Device';
import styles from './main.styles.js';
import AddDevice from './AddDevice';
import VideoList from './VideoList';
import axios from 'axios';
import AddMedication from './AddMedications';
import ShowMedications from './ShowMedications';
import { useTranslation } from 'react-i18next';
import CompanionCard from './CompanionCard';
const useStyles = makeStyles(styles);
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

function getDaysOld(date) {
  if (!date) {
    return 0;
  }
  // Get the current date
  var now = new Date();

  // Parse the input date string into a JavaScript Date object
  var inputDate = new Date(date);

  // Calculate the time difference in milliseconds
  var timeDifference = now - inputDate;

  // Convert milliseconds to days
  var daysOld = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  console.log(daysOld, date);
  if (daysOld > 365) {
    return 0;
  }

  return daysOld;
}

const updateSpiroTerms = async (userId) => {
  try {
    let results = await axios.put(
      `${process.env.REACT_APP_EXPRESS_URL}/api/users/spiro-terms/${userId}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('auth'),
        },
      },
    );
    console.log(results.data);
    if (results?.data.success) {
      return results.data.data;
    }
  } catch (e) {
    console.log(e);
  }
};

const STRIPE_SUB_STATUS = {
  paid: 'PAID',
  unpaid: 'UNPAID',
  trail: 'TRAIL',
};

function DeviceList({ user, refresh, userPortalSettings }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [spiroModal, setSpiroModal] = React.useState(false);
  const [showMedicationTable, setShowMedicationTable] = React.useState(false);

  const [state, setState] = useState({
    openAddDevice: false,
    myDevices: null,
    caregiverDevices: null,
    openAddMeds: false,
  });
  console.log(
    'CAN YOU PLEASE CHECK HERE',
    !user?.spiroTerms?.agreed,
    user?.spiroTerms?.agreed,
    getDaysOld(user?.spiroTerms?.date || null),
  );
  const myDevices = state.myDevices || [];
  const caregiverDevices = state.caregiverDevices || [];

  let userName = user.username;
  if (user.userInfo && user.userInfo.firstName) {
    userName = `${user.userInfo.firstName} ${user.userInfo.lastName}`;
  }

  function closeModal() {
    setState({ openAddDevice: false });
  }

  let counter = 0;

  _.forEach(user.userTo, (device) => {
    counter += 1;
    let { deviceName } = device;

    if (!deviceName) {
      deviceName = `My Device ${counter}`;
    }

    let mainCaregiver = null;
    const caregiversOfDevice = _.filter(user.caregivers, (caregiver) => {
      if (caregiver.user === user.emergencyContact) {
        mainCaregiver = caregiver;
      }

      return caregiver.device === device.deviceId;
    });

    myDevices.push({
      id: device.deviceId,
      caregivers: caregiversOfDevice,
      mainCaregiver,
      name: deviceName,
      image: device.imageUrl,
    });
  });

  counter = 0;

  _.forEach(user.caregiverTo, (device) => {
    counter += 1;
    let { deviceName } = device;

    if (!deviceName) {
      deviceName = `Device ${counter}`;
    }

    caregiverDevices.push({
      id: device.device,
      user: device.user,
      name: deviceName,
      image: device.imageUrl,
    });
  });

  // can be used later
  // const updateTerms = async () => {
  //   let results = await updateSpiroTerms(user.id, user);
  //   user['spiroTerms'] = { agreed: true, date: results };
  //   refresh();
  // };

  return (
    <>
      <Grid
        container
        item
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.deviceList}
        xs={12}
        sm={12}
        md={12}
        lg={8}
      >
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.deviceListHeader}
        >
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="flex-start"
            className={classes.deviceHeaderTextContainer}
            xs={8}
            lg={6}
            md={6}
          >
            <Grid item className={classes.deviceListGreetings}>
              {t('hello')}, {capitalize(userName)}!
            </Grid>
            <Grid className={classes.deviceListMessage}>{t('welcome_message_description')}</Grid>
          </Grid>
          <Grid
            item
            className={classes.addNewCaneContainer}
            style={{ display: 'flex', justifyContent: 'end' }}
            lg={6}
            md={6}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.addNewCaneButton}
              onClick={() => setState({ openAddDevice: true })}
              title="Add a new Cane"
            >
              <AddIcon className={classes.addIcon} />
              <span className={classes.addNewCaneText}>{t('add_a_new_device')}</span>
            </Button>
            {userPortalSettings?.medicationReminder === 2 && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.addNewCaneButton}
                onClick={() => {
                  setState({ openAddMeds: true });
                  setShowMedicationTable(false);
                }}
                style={{ marginInlineStart: '15px' }}
              >
                <AddIcon className={classes.addIcon} />
                <span className={classes.addNewCaneText}>{t('add_medication')}</span>
              </Button>
            )}
          </Grid>
        </Grid>
        <Dialog
          className={classes.modal}
          open={state.openAddDevice}
          // fullWidth={true}
          maxWidth={false}
          // onClose={handleClose}
        >
          <AddDevice user={user} closeModal={closeModal} refresh={refresh}></AddDevice>
        </Dialog>

        {userPortalSettings?.medicationReminder === 2 && (
          <Dialog className={classes.modal} open={state.openAddMeds} maxWidth={true}>
            {showMedicationTable ? (
              <ShowMedications
                closeModal={closeModal}
                user={user}
                setShowMedicationTable={() => setShowMedicationTable((prev) => !prev)}
                refresh={refresh}
              />
            ) : (
              <AddMedication
                user={user}
                closeModal={closeModal}
                refresh={refresh}
                setShowMedicationTable={() => setShowMedicationTable((prev) => !prev)}
              />
            )}
          </Dialog>
        )}

        {_.map(myDevices, (device) => {
          return (
            <Device
              device={device}
              user={user}
              relation={'my'}
              refresh={refresh}
              userPortalSettings={userPortalSettings}
            />
          );
        })}

        {_.map(caregiverDevices, (device) => {
          return (
            <Device
              device={device}
              user={user}
              relation={'caregiver'}
              refresh={refresh}
              userPortalSettings={userPortalSettings}
            />
          );
        })}

        {(user?.stripeInfo?.can_companion?.subStatus === STRIPE_SUB_STATUS.paid ||
          user?.stripeInfo?.can_companion?.subStatus === STRIPE_SUB_STATUS.trail) && <CompanionCard />}

        <h2
          onClick={() => {
            if (!user?.spiroTerms?.agreed) {
              return setSpiroModal(true);
            }
            console.log('still');
            if (getDaysOld(user?.spiroTerms?.date) > 30) {
              return setSpiroModal(true);
            }
          }}
          style={{ pointer: 'cursor' }}
        >
          {t('can_academy')}
        </h2>
        <VideoList title={t('how_to_use_cane')} category={3} />
        {
          // can be used later
          // <VideoList
          //   isSpiro={true}
          //   termsAgreed={user?.spiroTerms?.agreed}
          //   isOpen={!user?.spiroTerms?.agreed || (user?.spiroTerms?.agreed && getDaysOld(user?.spiroTerms?.date)) > 30}
          //   onAccept={updateTerms}
          //   refresh={refresh}
          //   title="Fall Prevention Exercises"
          //   category={2}
          // />
        }
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={spiroModal}
        onClose={() => setSpiroModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={spiroModal}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              <span
                style={{
                  color: '#2F3C98',
                }}
              >
                SPIRO {t('terms_and_conditions')}
              </span>
            </Typography>
            <Typography style={{ paddingTop: '10px' }} id="transition-modal-description" sx={{ mt: 2 }}>
              {t('spiro_videos_para')}
            </Typography>
            <Button
              onClick={async () => {
                let results = await updateSpiroTerms(user.id, user);
                user['spiroTerms'] = { agreed: true, date: results };
                setSpiroModal(false);
              }}
              style={{ marginTop: '10px', color: '#2F3C98' }}
            >
              {t('agree')}
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default DeviceList;

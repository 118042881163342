import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import userService from '../user.service.js';
import PanelWithImage from './PanelWithImage';
// import Copyright from '../shared-components/Copyright';
import styles from './signUp.styles';
import logo from '../images/logo.png';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { Translation } from 'react-i18next';
class LogIn extends React.Component {
  state = {
    email: '',
    password: '',
    rememberMe: true,
    errorMessage: '',
    showPassword: false,
  };

  login() {
    if (!this.state.email || !this.state.password) {
      return this.setState({
        errorMessage: 'Email and Password are required fields',
      });
    }

    this.setState({ errorMessage: '' });

    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    })
      .then((res) => {
        if (res.status === 401) {
          this.setState({ errorMessage: 'Wrong email or password' });
        } else if (res.status !== 200) {
          this.setState({ errorMessage: 'Something went wrong...' });
        }

        return res.json();
      })
      .then(({ data }) => {
        if (data.user) {
          userService.updateAuthData({
            accessToken: data.id,
            currentUserId: data.userId,
            rememberMe: this.state.rememberMe,
          });

          userService.updateStorage({
            accessToken: data.id,
            currentUserId: data.userId,
            rememberMe: this.state.rememberMe,
          });

          userService.updateCurrentUser(data.user);
          localStorage.setItem('auth', data.id + '~29#215@rfeqwEt0');
          return this.props.history.push('/main');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  enterFunction = (event) => {
    if (event.keyCode === 13) {
      this.login(this.state.email, this.state.password, this.state.rememberMe, this.props.history);
    }
  };
  componentDidMount() {
    document.addEventListener('keydown', this.enterFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.enterFunction, false);
  }

  render() {
    const { classes } = this.props;
    return (
      <Translation>
        {(t) => (
          <Grid container direction="row" justify="space-between" alignItems="center">
            <PanelWithImage />
            <Grid item className={classes.rightPanelContainer} xs={12} md={6}>
              <div className={classes.rightPanel}>
                <div>
                  <img className={classes.logo} src={logo} alt="CAN logo" />
                </div>
                <Typography component="h1" variant="h5" style={{ marginTop: '30px' }}>
                  {t('login')}
                </Typography>
                <form className={classes.form} noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('email')}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    InputProps={{
                      className: classes.input,
                    }}
                    onChange={(event) => this.setState({ email: event.target.value })}
                  />
                  {/* <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                InputProps={{
                  className: classes.input,
                }}
                onChange={(event) =>
                  this.setState({ password: event.target.value })
                }
              /> */}
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">{t('password')}</InputLabel>
                    <OutlinedInput
                      id="password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      style={{ background: '#fff' }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                            edge="end"
                          >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm Password"
                      onChange={(event) => this.setState({ password: event.target.value })}
                    />
                  </FormControl>
                  <div className={classes.checkBoxContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="remember"
                          color="primary"
                          onChange={(event) => this.setState({ rememberMe: event.target.checked })}
                        />
                      }
                      label={t('remember_me')}
                      className={classes.rememberMe}
                    />
                  </div>
                  <div className={classes.errorMessageContainer}>
                    <Typography className={classes.errorMessage} component="h1" variant="subtitle1">
                      {this.state.errorMessage}
                    </Typography>
                  </div>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.login()}
                  >
                    {t('login')}
                  </Button>
                  <Grid container direction="row" justify="space-between" className={classes.bottomLinks}>
                    <Grid item xs>
                      <Link href="/#/forgot-password" variant="body2">
                        {t('forgot_password')}?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/#/sign-up" variant="body2">
                        {t('dont_have_an_account_sign_up')}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
            {/*
                <Box mt={8}>
                    <Copyright />
                </Box>
                */}
          </Grid>
        )}
      </Translation>
    );
  }
}

export default withStyles(styles)(LogIn);

import React from 'react';
import './App.css';
import userService from './user.service.js';
import './utils/i18n';
class App extends React.Component {
  componentDidMount() {
    const authData = userService.getAuthData();
    // redirect to main page if logged in or to login page if not
    if (authData.accessToken && authData.currentUserId) {
      this.props.history.push('/main');
    } else {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div>
        <h1>Home</h1>
      </div>
    );
  }
}

export default App;

import { FormControl, OutlinedInput } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { Box, CircularProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';

import React, { useMemo } from 'react';

const useOptions = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = useMemo(() => ({
    style: {
      base: {
        color: '#424770',
        letterSpacing: '0.025em',
        border: 'groove !important',
        //   fontFamily: "Source Code Pro, monospace",
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }));

  return options;
};

const AddPaymentWithPairing = ({ classes, goBack, handleError, handleSubmitData, loader }) => {
  const [name, setName] = React.useState('');

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const token = await stripe.createToken(elements.getElement(CardNumberElement));

    if (token?.error) {
      handleError('Something Went Wrong!');
    } else {
      handleSubmitData(token);
    }
  };

  return (
    <>
      <form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} className="form-group">
            <TextField
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              className="form-control input-lg stripe-payment-fields"
              required
              fullWidth
              id="card_holder_name"
              label="Card holder name"
              name="card_holder_name"
              InputProps={{
                className: options,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className="form-group" fullWidth required>
              <OutlinedInput
                id="stripeCardNumberWrapper"
                className="form-control input-lg stripe-payment-fields"
                label="Card number"
                inputComponent={CardNumberElement}
                name="stripeCardNumberWrapper"
                inputProps={{
                  options: options,
                  onReady: () => {
                    console.log('CardNumberElement [ready]');
                  },
                  onChange: (event) => {
                    console.log('CardNumberElement [change]', event);
                  },
                  onBlur: (event) => {
                    console.log('CardNumberElement [blur]', event);
                  },
                  onFocus: (event) => {
                    console.log('CardNumberElement [focus]', event);
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className="form-group" fullWidth required>
              <OutlinedInput
                id="stripeCardExpiryWrapper"
                className="form-control input-lg stripe-payment-fields"
                label="Card Expiry"
                inputComponent={CardExpiryElement}
                name="stripeCardExpiryWrapper"
                inputProps={{
                  options: options,
                  onReady: () => {
                    console.log('CardExpiryElement [ready]');
                  },
                  onChange: (event) => {
                    console.log('CardExpiryElement [change]', event);
                  },
                  onBlur: () => {
                    console.log('CardExpiryElement [blur]');
                  },
                  onFocus: () => {
                    console.log('CardExpiryElement [focus]');
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className="form-group" fullWidth required>
              <OutlinedInput
                id="stripeCardCvcElementWrapper"
                className="form-control input-lg stripe-payment-fields"
                label="Card CVC"
                inputComponent={CardCvcElement}
                name="stripeCardCvcElementWrapper"
                inputProps={{
                  options: options,
                  onReady: () => {
                    console.log('CardCvcElement [ready]');
                  },
                  onChange: (event) => {
                    console.log('CardCvcElement [change]', event);
                  },
                  onBlur: () => {
                    console.log('CardCvcElement [blur]');
                  },
                  onFocus: () => {
                    console.log('CardCvcElement [focus]');
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid container item direction="row" justify="flex-end" alignItems="center" className={classes.footer}>
            <Grid item>
              <Button fullWidth variant="outlined" className={classes.cancelButton} onClick={goBack}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={handleSubmit}
                disabled={loader}
              >
                {loader ? 'Submitting...' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddPaymentWithPairing;

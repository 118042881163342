import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import { Grid, Card, CardContent, CircularProgress } from '@material-ui/core';
import Modal from './Modal';

import { Accordion, AccordionSummary, Typography } from '@material-ui/core';
import Video from './Video';
import { useTranslation } from 'react-i18next';
const VideoList = ({ category, title, isSpiro, isOpen, onAccept, refresh, termsAgreed }) => {
  const { t } = useTranslation();
  const [videosList, setVideoList] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(true);
  const [isVideoOpen, setVideoAccordian] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    if (isSpiro) {
      setOpenModal(isOpen);
    }
  }, []);
  React.useEffect(() => {
    (async () => {
      try {
        const videoIds = await axios.get(`${process.env.REACT_APP_SPIRO_URL}/videoData`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('spiroToken')}`,
            'ocp-apim-subscription-key': process.env.REACT_APP_SPIRO_OCM,
          },
        });
        let firstFive;
        if (videoIds.status === 200) {
          if (category === 1) {
            firstFive = [
              videoIds.data.videoIds[0],
              videoIds.data.videoIds[1],
              videoIds.data.videoIds[2],
              videoIds.data.videoIds[3],
              videoIds.data.videoIds[4],
            ];
          } else if (category === 2) {
            firstFive = [
              videoIds.data.videoIds[5],
              videoIds.data.videoIds[6],
              videoIds.data.videoIds[7],
              videoIds.data.videoIds[8],
              videoIds.data.videoIds[9],
            ];
          } else if (category === 3) {
            setVideoList([
              {
                id: 396084845,
                hash: '99197dbc03',
                instructorName: t('adjust_height'),
              },
              {
                id: 729739706,
                hash: 'b7e6e5893d',
                instructorName: t('how_to_use_cane'),
              },
              {
                id: 396086113,
                hash: '56448cb12a',
                instructorName: t('setup_the_travel_charger'),
              },
              {
                id: 396089810,
                hash: '39e33d0c48',
                instructorName: t('setup_the_wall_charger'),
              },
            ]);
          }

          if (category !== 3) {
            const pormisesArray = firstFive.map((id) =>
              axios.get(`${process.env.REACT_APP_SPIRO_URL}/video/` + id, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('spiroToken')}`,
                  'ocp-apim-subscription-key': process.env.REACT_APP_SPIRO_OCM,
                },
              }),
            );
            const promises = await Promise.allSettled(pormisesArray);

            setVideoList(promises.map((result) => result.value.data.videoData));
          }
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <Grid style={{ marginTop: '3%' }}>
            <Accordion
              expanded={isVideoOpen}
              style={{
                backgroundColor: '#FCFAF8',
                color: 'black',
                marginBottom: '20px',
              }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  setVideoAccordian(!isVideoOpen);
                  setOpenModal(isOpen);
                }}
              >
                <Typography>
                  {title} {isVideoOpen ? '▲' : '▼'}
                </Typography>
              </AccordionSummary>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {isSpiro && (
                  <Modal
                    termsAgreed={termsAgreed}
                    onAccept={onAccept}
                    isOpen={openModal}
                    onCancel={() => {
                      setVideoAccordian(false);
                      setOpenModal(false);
                      refresh();
                    }}
                  />
                )}
                {videosList.map((video) => (
                  <Grid style={{ marginBottom: '30px' }} item xs={window.screen.width > 900 ? 6 : 12}>
                    <Card
                      style={{
                        backgroundColor: '#FCFAF8',
                        border: 'none',
                        filter: isOpen && isSpiro ? 'blur(5px)' : '',
                      }}
                    >
                      <Video video={video}></Video>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {video?.instructorName || ''}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {video?.base || ''}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Accordion>
          </Grid>
        </>
      ) : (
        // <CircularProgress />
        ''
      )}
      {/*  */}
    </>
  );
};
export default VideoList;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { HashRouter as Router, Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    text404: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    link: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    }
}));

function Notfound(props) {
    const classes = useStyles();

    function goBack() {
        props.history.goBack();
    }

    return (
        <div className = {classes.text404}>
            <h1>
                404. Not found
            </h1>
            <h2>
                Page not found :(
            </h2>
            <h4>
                Maybe the page you are looking for has been removed, or you typed in the wrong URL
            </h4>
            <div >
                <Router>
                    <Link className={classes.link} onClick={goBack}>
                        GO BACK
                    </Link>
                    <Link className={classes.link} to="/">
                        GO TO HOMEPAGE
                    </Link>
                </Router>
            </div>
        </div>
    );
}

export default Notfound

export default {
    tanShopify: '#EEE2D7', // shopify tan
    // tan: '#BB987F', // corporate tan
    tan: '#E9B699', // redish tan
    lightTan: '#FCFAF8', //design light-tan
    tanText: '#8F7256', // design tan text
    navy: '#BB987F', // corporate navy SOME MISTAKE THIS IS TAN
    mainBlue: '#2F3C98', // design main blue
    blue: '#DDE1FF', // design blue
    lightBlue: '#F9FCFD', // design light-blue ALMOST WHITE 
    black: '#020A0A', // corporate black
    red: '#F46F6F', //design red
    white: '#FFFFFF', // design white
    lightGray: '#DADADA', // design light-gray
    grayText: '#61697D', // design gray text
}

import axios from 'axios';

export const getSignedImage = (userId, imageUrl) => {
  return axios.post(
    `${process.env.REACT_APP_EXPRESS_URL}/api/users/${userId}/getSignedUrl`,
    { url: imageUrl },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('auth'),
      },
    },
  );
};

import _ from 'lodash';

function nameValidation(event, property, setState) {
  const value = event.target.value;
  const name = /^[A-Za-z \-']+$/;

  if (!name.test(value) && value.length) {
    return null;
  }

  return setState((prevState) => {
    const newState = _.clone(prevState);
    newState[property] = value;

    return newState;
  });
}

function testEmail(email) {
  return /^\S+@\S+\.\S+$/.test(email);
}
function checkPasswordValidation(value) {
  const isWhitespace = /^(?=.*\s)/;
  if (isWhitespace.test(value)) {
    return 'Password must not contain Whitespaces.';
  }

  const isContainsUppercase = /^(?=.*[A-Z])/;
  if (!isContainsUppercase.test(value)) {
    return 'Password must have at least one Uppercase Character.';
  }

  const isContainsLowercase = /^(?=.*[a-z])/;
  if (!isContainsLowercase.test(value)) {
    return 'Password must have at least one Lowercase Character.';
  }

  const isContainsNumber = /^(?=.*[0-9])/;
  if (!isContainsNumber.test(value)) {
    return 'Password must contain at least one Digit.';
  }

  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
  if (!isContainsSymbol.test(value)) {
    return 'Password must contain at least one Special Symbol.';
  }

  const isValidLength = /^.{8,32}$/;
  if (!isValidLength.test(value)) {
    return 'Password must be 8-32 Characters Long.';
  }

  return null;
}

export default {
  name: nameValidation,
  email: testEmail,
  checkPasswordValidation,
};

import App from './App';
import Main from './Main/Main';
import Login from './SignUp_Login/Login';
import SignUp from './SignUp_Login/SignUp';
import ResetPassword from './SignUp_Login/ResetPassword';
import ForgotPassword from './SignUp_Login/ForgotPassword';
import EmailVerified from './EmailVerified';
import NotFound from './404';
import DeviceDetail from './DeviceDetail';
import CompanionSignUp from './SignUp_Login/CompanionSignUp';

const routes = [
  {
    path: '/',
    component: App,
    exact: true,
  },
  {
    path: '/main',
    component: Main,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/sign-up',
    component: SignUp,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/email-verified',
    component: EmailVerified,
  },
  {
    path: '/Device-detail/:deviceId',
    component: DeviceDetail,
  },
  {
    path: '/companion-sign-up',
    component: CompanionSignUp,
  },
  {
    path: '',
    component: NotFound,
  },
];

export default routes;

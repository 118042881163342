import React, { useState } from 'react';
import _, { capitalize, isEmpty } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styles from './main.styles.js';
import Dialog from '@material-ui/core/Dialog';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Person2Icon from '@mui/icons-material/Person2';
import EditUser from '../EditUser/EditUser';
import logo from '../images/logo.png';
import boyImage from '../images/boy.png';
import girlImage from '../images/girl.png';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReferalCodeModal from './components/ReferalCodeModal';
import { getSignedImage } from '../utils/index.js';
import userService from '../user.service.js';

const useStyles = makeStyles(styles);

const USER_TYPE = {
  device_user: 'DEVICE_USER',
  companion_user: 'COMPANION_USER',
};

function UserInfo({ user, logout, refresh }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [state, setState] = useState({
    openEditUser: false,
    openReferalModal: false,
    showFullInfo: window.innerWidth > 1280 ? true : false,
    showFullInfoText: 'Show Full Info',
  });
  const [userImage, setUserImage] = useState(user?.userInfo?.imageUrl);
  const [unsignedImage, setUnsignedImage] = useState(user?.userInfo?.imageUrl);

  // let userImage = null;
  // userImage = user?.userInfo?.imageUrl;

  React.useEffect(() => {
    if (!userImage) {
      const userImageTemp = (user.userInfo && user.userInfo.gender) === 'female' ? girlImage : boyImage;
      setUserImage(userImageTemp);
    }
  }, []);

  const getImage = React.useCallback(async () => {
    const authData = userService.getAuthData();
    try {
      const { data } = await getSignedImage(authData.currentUserId, unsignedImage);
      setUserImage(data?.data);
    } catch (error) {
      console.log(error);
    }
  }, [unsignedImage]);

  React.useEffect(() => {
    getImage();
  }, [getImage]);

  function closeModal() {
    getImage();
    setState((prevState) => {
      return { ...prevState, openEditUser: false };
    });
  }

  function toggleShowFullInfo() {
    let showFullInfoText = state.showFullInfo ? 'Show full info' : 'Hide full info';

    setState((prevState) => {
      return {
        ...prevState,
        showFullInfo: !state.showFullInfo,
        showFullInfoText,
      };
    });
  }

  const profileImage = (image) => {
    setUnsignedImage(image);
  };

  const additionalUserInfo = [
    [t('phone_number'), user.username],
    [t('first_name'), user.userInfo && capitalize(user.userInfo.firstName)],
    [t('last_name'), user.userInfo && capitalize(user.userInfo.lastName)],
    [t('gender'), user.userInfo && capitalize(user.userInfo.gender)],
    [t('age'), user.userInfo && user.userInfo.age],
    [t('weight'), user.userInfo && user.userInfo.weight],
    [t('height'), user.userInfo && user.userInfo.height],
    [
      t('membership_will_automatically_renew_on'),
      user?.stripeInfo?.subscription?.current_period_end
        ? moment.unix(user?.stripeInfo?.subscription?.current_period_end).add(1, 'y').format('MM-DD-YYYY')
        : moment(user?.created).add(1, 'y').format('MM-DD-YYYY'),
    ],
  ];

  const companionScreenInfo = [
    [t('phone_number'), user.username],
    [t('name'), capitalize(user.fullName)],
    [
      t('membership_will_automatically_renew_on'),
      user?.stripeInfo?.can_companion?.subscription?.current_period_end
        ? moment
            .unix(user?.stripeInfo?.can_companion?.subscription?.current_period_end)
            .add(1, 'y')
            .format('MM-DD-YYYY')
        : moment(user?.created).add(1, 'y').format('MM-DD-YYYY'),
    ],
  ];

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      className={classes.userInfoContainer}
      xs={12}
      sm={12}
      md={12}
      lg={3}
    >
      <Grid item className={classes.logoContainer}>
        <img src={logo} alt="CAN logo" className={classes.logo} />
      </Grid>
      <Grid item className={classes.userImageContainer}>
        <div
          style={{
            backgroundImage: `url(${userImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: '50%',
          }}
          className={classes.userImage}
        ></div>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.mainUserInfoContainer}
      >
        {user?.userType === USER_TYPE.companion_user ? (
          <Grid item className={classes.userPhoneNumber}>
            {capitalize(user?.fullName || 'User')}
          </Grid>
        ) : (
          <Grid item className={classes.userPhoneNumber}>
            {!isEmpty(user?.userInfo?.firstName)
              ? `${user.userInfo && capitalize(user.userInfo.firstName)} ${user.userInfo && user.userInfo.lastName}`
              : `${user?.fullName}`}
          </Grid>
        )}

        <Grid item className={`${classes.userEmail} ${classes.grayText}`}>
          {user.email}
        </Grid>
        <Grid item className={classes.showFullInfoButton} onClick={toggleShowFullInfo}>
          {state.showFullInfoText}
        </Grid>
      </Grid>
      {state.showFullInfo && (
        <Grid container direction="column" justify="flex-start" alignItems="center">
          <Grid
            container
            item
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.additionalUserInfoContainer}
          >
            {user?.userType === USER_TYPE.companion_user
              ? _.map(companionScreenInfo, (item) => {
                  return (
                    <Grid
                      container
                      item
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className={classes.additionalUserInfoItem}
                      xs={12}
                      sm={5}
                      md={5}
                      lg={12}
                    >
                      <Grid item className={`${classes.additionalUserInfoItemKey} ${classes.grayText}`}>
                        {item[0]}
                      </Grid>
                      <Grid item className={classes.additionalUserInfoItemValue}>
                        {item[1]}
                      </Grid>
                    </Grid>
                  );
                })
              : _.map(additionalUserInfo, (item) => {
                  return (
                    <Grid
                      container
                      item
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className={classes.additionalUserInfoItem}
                      xs={12}
                      sm={5}
                      md={5}
                      lg={12}
                    >
                      <Grid item className={`${classes.additionalUserInfoItemKey} ${classes.grayText}`}>
                        {item[0]}
                      </Grid>
                      <Grid item className={classes.additionalUserInfoItemValue}>
                        {item[1]}
                      </Grid>
                    </Grid>
                  );
                })}
          </Grid>
          {user?.userType !== USER_TYPE.companion_user && (
            <Grid item className={classes.userEditButtonContainer}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.userEditButton}
                onClick={() =>
                  setState((prevState) => {
                    return { ...prevState, openEditUser: true };
                  })
                }
              >
                <Person2Icon className={classes.editIcon} /> {t('profile')}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                className={classes.userEditButton}
                style={{ marginTop: '10px', color: 'green', borderColor: 'green' }}
                onClick={() =>
                  setState((prevState) => {
                    return { ...prevState, openReferalModal: true };
                  })
                }
              >
                {t('referral_code')}
              </Button>
            </Grid>
          )}
          <Grid
            container
            item
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            className={classes.userInfoFooter}
          >
            <Grid item className={classes.supportButtonContainer}>
              <a href="mailto: hi@canmobilities.com" className={`${classes.supportButton} ${classes.grayText}`}>
                <ContactSupportIcon className={classes.textIcons} />
                {t('support')}
              </a>
            </Grid>
            <Grid item className={`${classes.logoutButton} ${classes.grayText}`} onClick={logout}>
              <ExitToAppIcon className={classes.textIcons} />
              {t('logout')}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Dialog
        className={classes.modal}
        open={state.openEditUser}
        // fullWidth={true}
        maxWidth={false}
        // onClose={handleClose}
      >
        <EditUser user={user} refresh={refresh} closeModal={closeModal} profileImage={profileImage}></EditUser>
      </Dialog>
      <Dialog className={classes.modal} open={state.openReferalModal} maxWidth={false}>
        <ReferalCodeModal
          user={user}
          closeModal={() =>
            setState((prevState) => {
              return { ...prevState, openReferalModal: false };
            })
          }
        ></ReferalCodeModal>
      </Dialog>
    </Grid>
  );
}

export default UserInfo;

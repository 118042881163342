import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  paper: {
    background: '#FFFFFF',
    width: '460px',
    padding: '24px 32px 24px 32px',
    '@media (max-width:600px)': {
      // eslint-disable-line no-useless-computed-key
      width: '280px',
      padding: '15px 20px 15px 20px',
    },
    '@media (max-width:380px)': {
      // eslint-disable-line no-useless-computed-key
      width: '250px',
      padding: '10px 15px 10px 15px',
    },
  },

  title: {
    fontSize: '24px',
    fontWeight: 800,
  },
  closeIcon: {
    cursor: 'pointer',
  },

  mainContainer: {
    marginTop: '65px',
    width: '100%',
    '@media (max-width:600px)': {
      // eslint-disable-line no-useless-computed-key
      marginTop: '30px',
    },
  },
  mainText: {
    fontSize: '24px',
    fontWeight: 800,
    marginBottom: '8px',
    textAlign: 'center',
    '@media (max-width:600px)': {
      // eslint-disable-line no-useless-computed-key
      fontSize: '15px',
      fontWeight: 700,
    },
    '@media (max-width:380px)': {
      // eslint-disable-line no-useless-computed-key
      fontSize: '13px',
      fontWeight: 600,
    },
  },
  explanationText: {
    width: '450px',
    fontSize: '16px',
    textAlign: 'center',
    '@media (max-width:600px)': {
      // eslint-disable-line no-useless-computed-key
      fontSize: '12px',
      width: '260px',
    },
    '@media (max-width:380px)': {
      // eslint-disable-line no-useless-computed-key
      fontSize: '10px',
      width: '230px',
    },
  },

  footer: {
    marginTop: '40px',
  },
  saveButton: {
    marginLeft: '8px',
  },

  grayText: {
    color: '#61697D',
  },
  userInfoPanel: {
    width: '100%',
    '@media (max-width:600px)': {
      // eslint-disable-line no-useless-computed-key
      width: '260px',
    },
    '@media (max-width:380px)': {
      // eslint-disable-line no-useless-computed-key
      width: '230px',
    },
  },
});

const useStyles = makeStyles(styles);

function AddYourselfAsEmergencyContact({ action, closeModal, closeParentModal }) {
  const classes = useStyles();
  const { t } = useTranslation();
  function save() {
    return action().then(() => {
      closeModal();

      if (closeParentModal) {
        closeParentModal();
      }
    });
  }

  function exitWithoutSaving() {
    closeModal();

    if (closeParentModal) {
      closeParentModal();
    }
  }

  return (
    <div className={classes.paper}>
      <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
        <Grid item className={classes.title}>
          {t('save_changes')}
        </Grid>
        <Grid item className={classes.closeIcon} onClick={closeModal}>
          <CloseIcon />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.mainContainer}
      >
        <Grid item className={classes.userInfoPanel}>
          <Grid item className={classes.mainText}>
            {t('add_yourself_as_emergency')}
          </Grid>
          <Grid item className={`${classes.explanationText} ${classes.grayText}`}>
            <div>{t('are_you_sure_can_call_emergency')}</div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item direction="row" justify="flex-end" alignItems="center" className={classes.footer}>
        <Grid item>
          <Button fullWidth variant="outlined" className={classes.cancelButton} onClick={exitWithoutSaving}>
            {t('no')}
          </Button>
        </Grid>
        <Grid item>
          <Button fullWidth color="primary" variant="contained" className={classes.saveButton} onClick={save}>
            {t('yes')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddYourselfAsEmergencyContact;

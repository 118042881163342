import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import userService from '../user.service.js';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import styles from './editUser.styles.js';

const useStyles = makeStyles(styles);

function ChangePassword({ user, refresh, closeModal }) {
  const classes = useStyles();
  const [state, setState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    errorMessage: '',
  });

  function change() {
    const authData = userService.getAuthData();

    if (state.errorMessage) {
      setState((prevState) => {
        return { ...prevState, errorMessage: '' };
      });
    }

    if (!state.oldPassword || !state.newPassword || !state.confirmNewPassword) {
      return setState((prevState) => {
        return {
          ...prevState,
          errorMessage: 'Old Password, New password and Confirm New Password are required fields',
        };
      });
    }

    if (state.newPassword !== state.confirmNewPassword) {
      return setState((prevState) => {
        return {
          ...prevState,
          errorMessage: 'Confirm New Password should be equal to New password',
        };
      });
    }

    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: authData.accessToken,
      },
      body: JSON.stringify({
        oldPassword: state.oldPassword,
        newPassword: state.newPassword,
      }),
    })
      .then((res) => {
        if (res.status === 400) {
          return Promise.reject(new Error('Invalid Old Password'));
        } else if (res.status !== 200 && res.status !== 204) {
          return Promise.reject(new Error('Something went wrong...'));
        } else {
          closeModal();
        }
      })
      .catch((err) => {
        setState((prevState) => {
          return {
            ...prevState,
            errorMessage: err.message || 'Something went wrong...',
          };
        });

        console.log(err);
      });
  }

  return (
    <div className={classes.changePasswordPaper}>
      <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
        <Grid item className={classes.title}>
          Change your password
        </Grid>
        <Grid item className={classes.closeIcon} onClick={closeModal}>
          <CloseIcon />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.changePasswordMainContainer}
      >
        <Grid item className={classes.userInfoPart}>
          <div>
            <div className={classes.inputText}>Old Password</div>
            <Link className={classes.forgotPasswordLink} href="/#/forgot-password" variant="body2">
              Forgot your password?
            </Link>
          </div>
          <input
            type="password"
            className={classes.userInfoPartInput}
            onChange={(event) => {
              const value = event.target.value;
              setState((prevState) => {
                return { ...prevState, oldPassword: value };
              });
            }}
          />
        </Grid>
        <Grid item className={classes.userInfoPart}>
          <div>
            <div className={classes.inputText}>New Password</div>
          </div>
          <input
            type="password"
            className={classes.userInfoPartInput}
            onChange={(event) => {
              const value = event.target.value;
              setState((prevState) => {
                return { ...prevState, newPassword: value };
              });
            }}
          />
        </Grid>
        <Grid item className={classes.userInfoPart}>
          <div>
            <div className={classes.inputText}>Confirm New Password</div>
          </div>
          <input
            type="password"
            className={classes.userInfoPartInput}
            onChange={(event) => {
              const value = event.target.value;
              setState((prevState) => {
                return { ...prevState, confirmNewPassword: value };
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.changePasswordErrorMessage}>
        {state.errorMessage}
      </Grid>
      <Grid item className={`${classes.infoMessage} ${classes.grayText}`}>
        Password must be at least 6 characters long and contain at least 1 uppercase letter
      </Grid>
      <Grid container item direction="row" justify="flex-end" alignItems="center" className={classes.footer}>
        <Grid item>
          <Button fullWidth variant="outlined" className={classes.cancelButton} onClick={closeModal}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button fullWidth color="primary" variant="contained" className={classes.saveButton} onClick={change}>
            Change
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default ChangePassword;

import React from "react";

const Modal = ({
  isOpen = true,
  onClose = false,
  onAccept,
  onCancel,
  termsAgreed,
}) => {
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9998,
    display: isOpen ? "block" : "none",
  };
  if (isOpen) {
    return (
      <>
        <div style={overlayStyle} onClick={onClose} />
        <div style={modalStyle}>
          {!termsAgreed ? (
            <h2>Terms and Conditions</h2>
          ) : (
            <h2>Continue Watching</h2>
          )}
          <p>By continuing you agree to Spiro terms and conditions</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button onClick={onCancel}>Cancel</button>
            <button
              style={{ backgroundColor: "#3f51b5", color: "white" }}
              onClick={onAccept}
            >
              Continue
            </button>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default Modal;

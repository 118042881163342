import colors from '../shared-colors';

export default (theme) => ({
  paper: {
    background: colors.white,
    width: '620px',
    padding: '24px 32px 24px 32px',
    ['@media (max-width:800px)']: {
      // eslint-disable-line no-useless-computed-key
      width: 'auto',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 800,
  },
  closeIcon: {
    cursor: 'pointer',
  },
  userInfoContainer: {
    marginTop: '50px',
    ['@media (max-width:800px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '20px',
    },
  },
  userImagePanel: {
    width: '200px',
  },
  userInfoPanel: {
    width: '400px',
    ['@media (max-width:800px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
      marginTop: '15px',
    },
  },
  userImage: {
    width: '200px',
    height: '200px',
    marginTop: '8px',
    marginBottom: '16px',
  },

  userInfoPart: {
    marginBottom: '24px',
  },
  userInfoPartInput: {
    background: colors.white,
    border: '1px solid #C2C8D6',
    boxSizing: 'border-box',
    height: '50px',
    boxShadow: 'inset 0px 2px 2px rgba(108, 131, 145, 0.19)',
    borderRadius: '2px',
    marginTop: '8px',
    width: '400px',
    fontSize: '16px',
    padding: '16px',
    '&:focus': {
      boxShadow: '0 0 3px steelblue',
      outlineWidth: '0px',
    },
    ['@media (max-width:800px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  userInfoPartInputPayment: {
    background: colors.white,
    border: '1px solid #C2C8D6',
    boxSizing: 'border-box',
    height: '50px',
    boxShadow: 'inset 0px 2px 2px rgba(108, 131, 145, 0.19)',
    borderRadius: '2px',
    marginTop: '8px',
    width: '300px',
    fontSize: '16px',
    padding: '16px',
    '&:focus': {
      boxShadow: '0 0 3px steelblue',
      outlineWidth: '0px',
    },
    ['@media (max-width:800px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  userInfoPartRadio: {
    marginTop: '8px',
  },
  changePasswordButton: {
    marginBottom: '24px',
  },
  phoneInputParent: {
    border: '1px solid #C2C8D6',
    boxSizing: 'border-box',
    boxShadow: 'inset 0px 2px 2px rgba(108, 131, 145, 0.19)',
    height: '50px',
    paddingLeft: '20px',
    marginTop: '8px',
    '&:focus-within': {
      boxShadow: '0 0 3px steelblue',
    },
  },
  phoneInputChild: {
    border: 'none',
    boxShadow: 'none',
    marginTop: '0px',
    height: '0px',
    '&:focus': {
      outlineWidth: '0px',
      boxShadow: 'none',
    },
  },

  changePasswordPaper: {
    background: colors.white,
    padding: '24px 32px 24px 32px',
  },
  changePasswordMainContainer: {
    marginTop: '32px',
  },
  infoMessage: {
    marginBottom: '46px',
    marginTop: '32px',
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: '400px',
  },
  changePasswordErrorMessage: {
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: '400px',
    color: colors.red,
  },
  inputText: {
    display: 'inline-block',
    float: 'left',
  },
  forgotPasswordLink: {
    float: 'right',
  },

  saveButton: {
    marginLeft: '8px',
  },

  grayText: {
    color: colors.grayText,
  },

  referalCodeStyle: {
    border: '1px solid black',
    paddingBlock: '30px',
    marginBlock: '20px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '20px',
    cursor: 'pointer',
  },
});

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import userService from '../user.service.js';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import colors from '../shared-colors';
import charginImage from '../images/charging.png';
import caregiverImage from '../images/Onboarding/caregiver.png';
import activityImage from '../images/Onboarding/activity.png';
import reportsImage from '../images/Onboarding/reports.png';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  paper: {
    background: '#FFFFFF',
    // height: '620px',
    padding: '24px 32px 24px 32px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    ['@media (min-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '440px',
    },
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '340px',
      padding: '16px',
      backgroundSize: 'contain',
      backgroundPosition: 'center top',
    },
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      width: 'auto',
    },
  },

  topContainer: {
    marginTop: '300px',
    width: '100%',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '270px',
    },
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '230px',
    },
  },
  mainContainer: {
    // marginTop: '140px',
  },
  mainText: {
    fontSize: '24px',
    fontWeight: 800,
    marginBottom: '10px',
    textAlign: 'center',
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '200px',
    },
  },
  explanationText: {
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '10px',
    color: colors.grayText,
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '220px',
    },
  },
  stepCircle: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '6px',
    marginRight: '6px',
  },
  activeStepCircle: {
    background: colors.mainBlue,
  },
  lightCircle: {
    background: colors.blue,
  },

  footer: {
    marginTop: '20px',
  },
  cancelButton: {
    color: colors.grayText,
  },
  saveButton: {
    // marginLeft: '8px',
  },
});

const useStyles = makeStyles(styles);

function SignUpGuideModal({ closeModal }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const steps = ['welcome', 'caregivers', 'activity', 'reports']; // order of onboarding
  const images = [charginImage, caregiverImage, activityImage, reportsImage];
  const [state, setState] = useState({
    step: 0,
  });
  const lastStep = state.step === steps.length - 1;

  function nextStep() {
    if (lastStep) {
      sessionStorage['signUpGuide'] = true;
      closeModal();
    } else {
      setState({ step: (state.step += 1) });
    }
  }

  return (
    <div
      className={classes.paper}
      style={{
        backgroundImage: `url(${images[state.step]})`,
        paddingInline: '50px',
      }}
    >
      <Grid container item direction="column" justify="flex-start" alignItems="center" className={classes.topContainer}>
        {steps[state.step] === 'welcome' && (
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.mainContainer}
          >
            <Grid item className={classes.mainText}>
              To begin, Make sure your cane is fully charged!
            </Grid>
            <Grid item className={classes.explanationText}>
              Follow these simple steps or watch this{' '}
              <a href="https://player.vimeo.com/video/396086113?h=56448cb12a" rel="noreferrer" target="_blank">
                video
              </a>
              :
              <ul style={{ textAlign: 'start' }}>
                <li>Connect your portable charger to the AC adapter.</li>
                <li>Connect the charger port to your smart cane.</li>
                <li>Charge your smart cane.</li>
              </ul>
            </Grid>
          </Grid>
        )}
        {steps[state.step] === 'caregivers' && (
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.mainContainer}
          >
            <Grid item className={classes.mainText}>
              {t('caregivers_and_emergency_contacts')}
            </Grid>
            <Grid item className={classes.explanationText}>
              Add person who will take care about you, set them as emergency contact to be in touch
            </Grid>
          </Grid>
        )}
        {steps[state.step] === 'activity' && (
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.mainContainer}
          >
            <Grid item className={classes.mainText}>
              Change and reach your activity goal
            </Grid>
            <Grid item className={classes.explanationText}>
              Set the daily activity goal that suits you, and track it every day
            </Grid>
          </Grid>
        )}
        {steps[state.step] === 'reports' && (
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.mainContainer}
          >
            <Grid item className={classes.mainText}>
              Adjust reports settings as you wish
            </Grid>
            <Grid item className={classes.explanationText}>
              Receive reports only at a convenient time and in a convenient way.
            </Grid>
          </Grid>
        )}
        <Grid item className={classes.stepNumberIndicator}>
          {_.map(steps, (step, index) => {
            if (state.step === index) {
              return <div className={`${classes.stepCircle} ${classes.activeStepCircle}`} />;
            }

            return <div className={`${classes.stepCircle} ${classes.lightCircle}`} />;
          })}
        </Grid>
      </Grid>
      <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.footer}>
        <Grid item>
          <Button
            fullWidth
            // variant="outlined"
            className={classes.cancelButton}
            onClick={() => {
              localStorage['notFirstVisit'] = true;
              closeModal();
            }}
          >
            Skip
          </Button>
        </Grid>
        <Grid item>
          <Button fullWidth color="primary" variant="contained" className={classes.saveButton} onClick={nextStep}>
            {lastStep ? 'Finish' : 'Next'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default SignUpGuideModal;

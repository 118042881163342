import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styles from './signUp.styles';
import PanelWithImage from './PanelWithImage';

import Copyright from '../shared-components/Copyright';
import logo from '../images/logo.png';
import { Backdrop } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import { Fade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles(styles);

function ForgotPassword() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = useState({
    email: '',
    errorMessage: '',
    successMessage: '',
    openDialog: false,
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function sendEmail() {
    if (!state.email) {
      return setState((prevState) => {
        return { ...prevState, errorMessage: 'Email is required' };
      });
    }
    handleOpen();
    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: state.email,
      }),
    })
      .then((res) => {
        if (res.status === 404) {
          setState((prevState) => {
            return { ...prevState, errorMessage: 'Email not found' };
          });
        } else {
          setState((prevState) => {
            return {
              ...prevState,
              successMessage: 'Email send',
              errorMessage: '',
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <PanelWithImage />
      <Grid item className={classes.rightPanelContainer} xs={12} md={6}>
        <div className={classes.rightPanel}>
          <div className={classes.header}>
            <div>
              <img className={classes.logo} src={logo} alt="CAN logo" />
            </div>
            <Typography component="h1" variant="h5">
              {t('forgot_password')}
            </Typography>
            <Typography className={classes.successMessage} component="h1" variant="subtitle1">
              {state.successMessage}
            </Typography>
          </div>
          <form
            className={classes.form}
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label={t('email')}
                  name="email"
                  autoComplete="email"
                  InputProps={{
                    className: classes.input,
                  }}
                  value={state.email}
                  onChange={(event) => {
                    const email = event.target.value;
                    return setState((prevState) => {
                      return { ...prevState, email };
                    });
                  }}
                />
              </Grid>
              <Grid item className={classes.errorMessageContainer}>
                <Typography className={classes.errorMessage} component="h1" variant="subtitle1">
                  {state.errorMessage}
                </Typography>
              </Grid>
              <Grid item className={`${classes.infoMessage} ${classes.grayText}`}>
                {t('forgot_password_text')}
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => sendEmail()}
              >
                {t('send_email')}
              </Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open} className={classes.modalWidth}>
                  <Box sx={style}>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                      Password Reset Instruction
                    </Typography>
                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                      Password reset link has been sent to your email
                    </Typography>
                  </Box>
                </Fade>
              </Modal>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;

import React, { useState, useEffect } from 'react';
import _, { isEmpty } from 'lodash';
import userService from '../user.service.js';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import caneFrontPanelImage from '../images/cane_front_panel.png';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import StripeCardForm from '../SignUp_Login/StripeCardForm.jsx';
import AddPaymentWithPairing from './components/AddPaymentWithPairing.jsx';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const promise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const styles = (theme) => ({
  paper: {
    background: '#FFFFFF',
    width: '460px',
    padding: '24px 32px 24px 32px',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      padding: '14px 16px 14px 16px',
      width: 'auto',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 800,
  },
  closeIcon: {
    cursor: 'pointer',
  },

  mainContainer: {
    marginTop: '65px',
    width: '100%',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '20px',
    },
  },
  mainText: {
    fontSize: '24px',
    fontWeight: 800,
    marginBottom: '8px',
    textAlign: 'center',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '18px',
    },
  },
  explanationText: {
    width: '450px',
    fontSize: '16px',
    textAlign: 'center',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      width: 'auto',
    },
  },
  imageContainer: {
    width: '180px',
    marginBottom: '30px',
    marginTop: '30px',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      marginBottom: '15px',
      marginTop: '15px',
    },
    ['@media (max-width:380px)']: {
      width: '100px',
    },
  },
  image: {
    width: '100%',
  },

  pinInput: {
    background: '#FFFFFF',
    border: '1px solid #C2C8D6',
    boxSizing: 'border-box',
    height: '50px',
    boxShadow: 'inset 0px 2px 2px rgba(108, 131, 145, 0.19)',
    borderRadius: '2px',
    marginTop: '8px',
    width: '468px',
    fontSize: '16px',
    padding: '16px',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  userInfoPartRadio: {
    marginTop: '8px',
  },

  errorMessage: {
    marginTop: '8px',
    marginBottom: '8px',
    height: '20px',
  },

  footer: {
    marginTop: '40px',
    ['@media (max-width:650px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '20px',
    },
  },
  saveButton: {
    marginLeft: '8px',
  },

  grayText: {
    color: '#61697D',
  },
  selectedCaregivers: { textAlign: 'end', marginBlock: '15px', color: 'grey' },
});

const useStyles = makeStyles(styles);

function checkPin(pin) {
  return fetch(`${process.env.REACT_APP_API_URL}/api/users/checkPinOrInvitationToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: pin,
    }),
  })
    .then((res) => {
      if (res.status !== 200) {
        console.log('Incorrect PIN or Invitation Token');
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

function AddDevice({ user, refresh, closeModal, companionUser = false }) {
  const classes = useStyles();
  const [state, setState] = useState({
    pin: null,
    errorMessage: '',
  });
  const steps = ['addCane', 'addCaregivers', 'addPayment'];

  const [step, setStep] = useState(steps[0]);
  const { t } = useTranslation();
  const [selectedCaregivers, setSelectedCaregivers] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [isPayment, setIsPayment] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (isEmpty(user?.stripeInfo)) {
      if (isEmpty(user?.stripeInfo?.card)) {
        setIsPayment(true);
      }
    }
  }, [user]);

  function checkPin() {
    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/devices/check-pin/${state.pin}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('auth'),
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Error on pairing device with user');
        }
        return res.json();
      })
      .then((res) => {
        setState((prev) => {
          return { ...prev, errorMessage: '' };
        });
        setStep(steps[1]);
      })
      .catch((err) => {
        setState({
          errorMessage: err.message || 'Error on pairing device with user',
        });
        console.log(err);
      });
  }

  function removeDuplicates(array, key) {
    return array.filter((item, index, self) => index === self.findIndex((t) => t[key] === item[key]));
  }

  useEffect(() => {
    if (user?.userCaregivers) {
      let result = removeDuplicates(user?.userCaregivers, 'email');
      result = removeDuplicates(result, 'phoneNumber');
      setCaregivers(result);
      setSelectedCaregivers(result);
    }
  }, [user]);

  const handleSelectCaregiver = (item) => {
    const { email, phoneNumber } = item;

    setSelectedCaregivers((prev) => {
      const isSelected = prev.some(
        (selectedItem) => selectedItem.email === email || selectedItem.phoneNumber === phoneNumber,
      );
      if (isSelected) {
        return prev.filter((selectedItem) => selectedItem.email !== email || selectedItem.phoneNumber !== phoneNumber);
      } else {
        return [...prev, item];
      }
    });
  };

  const caregiverCheckBoxValue = (item) => {
    const isSelected = selectedCaregivers.some(
      (selectedItem) => selectedItem.email === item.email || selectedItem.phoneNumber === item.phoneNumber,
    );

    return isSelected;
  };

  const handleSubmitData = async (token = null) => {
    setLoader(true);
    try {
      const body = {
        selectedCaregivers,
        devicePin: state.pin,
        token: token ? token.token : null,
        companionUser,
      };
      const { data: result } = await axios.post(
        `${process.env.REACT_APP_EXPRESS_URL}/api/users/${user?.id}/pairDevice`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: localStorage.getItem('auth'),
          },
        },
      );
      console.log('result==>', result);
      refresh();
      closeModal();
    } catch (error) {
      console.log('error===>', error);
      setState((prev) => {
        return { ...prev, errorMessage: 'Something went wrong!' };
      });
    }
    setLoader(false);
  };

  return (
    <div className={classes.paper}>
      {step === steps[0] && (
        <>
          <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
            <Grid item className={classes.title}>
              {t('add_a_new_device')}
            </Grid>
            <Grid item className={classes.closeIcon} onClick={closeModal}>
              <CloseIcon />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.mainContainer}
          >
            <Grid item className={classes.userInfoPanel}>
              <Grid item className={classes.mainText}>
                {t('pair_cane_with_this_account')}
              </Grid>
              <Grid item className={`${classes.explanationText} ${classes.grayText}`}>
                <div>{t('pair_cane_with_this_account_para')}</div>
              </Grid>
              <Grid item container justify="center">
                <img className={classes.imageContainer} src={caneFrontPanelImage} alt="Cane front panel" />
              </Grid>
              <Grid item className={classes.userInfoPart}>
                <div className={classes.pinText}>{t('enter_pin_here')}</div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={t('pin')}
                  type="text"
                  maxLength="6"
                  onChange={(event) => setState({ pin: event.target.value.trim() })}
                  inputProps={{
                    maxLength: 6,
                  }}
                  InputProps={{
                    className: `${classes.inputPIN}`,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item direction="column" justify="flex-start" alignItems="center" className={classes.errorMessage}>
            {state.errorMessage}
          </Grid>
          <Grid container item direction="row" justify="flex-end" alignItems="center" className={classes.footer}>
            <Grid item>
              <Button fullWidth variant="outlined" className={classes.cancelButton} onClick={closeModal}>
                {t('cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={checkPin}
                disabled={!state.pin}
              >
                {t('next')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      {step === steps[1] && (
        <>
          <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
            <Grid item className={classes.title}>
              {t('add_caregivers')}
            </Grid>
            <Grid item className={classes.closeIcon} onClick={closeModal}>
              <CloseIcon />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.mainContainer}
          >
            <Grid item className={classes.userInfoPanel}>
              <Grid item className={classes.mainText}>
                {t('copy_caregivers_from_other_devices')}
              </Grid>
              <Grid item className={`${classes.explanationText} ${classes.grayText}`}>
                <div>{t('add_caregivers_para')}</div>
              </Grid>
              <Grid item container style={{ display: 'grid' }}>
                <span className={classes.selectedCaregivers}>
                  {t('selected')}: {selectedCaregivers.length}
                </span>
                {!isEmpty(caregivers) ? (
                  caregivers.map((item, idx) => (
                    <FormControlLabel
                      key={idx}
                      control={
                        <Checkbox
                          checked={caregiverCheckBoxValue(item)}
                          onChange={() => handleSelectCaregiver(item)}
                          name="users"
                          color="primary"
                        />
                      }
                      label={`${item.email} (${item?.phoneNumber})`}
                    />
                  ))
                ) : (
                  <h4 style={{ textAlign: 'center' }}>{t('no_caregivers_found')}!</h4>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item direction="column" justify="flex-start" alignItems="center" className={classes.errorMessage}>
            {state.errorMessage}
          </Grid>
          <Grid container item direction="row" justify="flex-end" alignItems="center" className={classes.footer}>
            <Grid item>
              <Button fullWidth variant="outlined" className={classes.cancelButton} onClick={() => setStep(steps[0])}>
                {t('back')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={() => setStep(steps[2])}
              >
                {t('next')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      {step === steps[2] && (
        <>
          <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.header}>
            <Grid item className={classes.title}>
              {t('add_payment')}
            </Grid>
            <Grid item className={classes.closeIcon} onClick={closeModal}>
              <CloseIcon />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.mainContainer}
          >
            <Grid item className={classes.userInfoPanel}>
              {isPayment ? (
                <Grid item className={classes.mainText}>
                  {t('add_payment_method_for_subscription')}
                </Grid>
              ) : (
                <Grid item className={classes.mainText}>
                  {t('payment_already_added')}!!
                </Grid>
              )}

              {isPayment ? (
                <Grid item container style={{ display: 'grid' }}>
                  <Elements stripe={promise}>
                    <AddPaymentWithPairing
                      classes={classes}
                      goBack={() => setStep(steps[1])}
                      handleError={(error) =>
                        setState((prev) => {
                          return { ...prev, errorMessage: error };
                        })
                      }
                      handleSubmitData={handleSubmitData}
                      loader={loader}
                    />
                  </Elements>
                </Grid>
              ) : (
                <Grid container item direction="row" justify="flex-end" alignItems="center" className={classes.footer}>
                  <Grid item>
                    <Button
                      fullWidth
                      variant="outlined"
                      className={classes.cancelButton}
                      onClick={() => setStep(steps[1])}
                    >
                      {t('back')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      className={classes.saveButton}
                      onClick={handleSubmitData}
                      disabled={loader}
                    >
                      {loader ? t('submiting') : t('submit')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item direction="column" justify="flex-start" alignItems="center" className={classes.errorMessage}>
            {state.errorMessage}
          </Grid>
        </>
      )}
    </div>
  );
}

export default AddDevice;

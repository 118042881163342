import React, { useState } from 'react';
//comment
import _, { capitalize, isEmpty, map } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import styles from './main.styles.js';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CallIcon from '../images/call.png';
import Button from '@mui/material/Button';
import userService from '../user.service';
import CircularProgress from '@mui/material/CircularProgress';
import './styles.css';
import AddIcon from '@material-ui/icons/Add';
import { Dialog } from '@material-ui/core';
import AddDevice from './AddDevice.jsx';

const useStyles = makeStyles(styles);

function CompanionScreen({ user, refresh }) {
  const authData = userService.getAuthData();
  const scrollRef = React.useRef();
  const [message, setMessage] = useState('');
  const [chatLoader, setChatLoader] = useState(false);
  const [openAddDevice, setOpenAddDevice] = useState(false);
  const [messages, setMessages] = useState([
    {
      message: 'Hello! My name is Can Companion. How can I assist you today?',
      sender: 'bot',
    },
  ]);

  const { t } = useTranslation();
  const classes = useStyles();

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const canNum = '+13185839953';

  const handleClickPhone = (e) => {
    e.preventDefault();
    window.location.href = `tel:${canNum}`;
  };

  const getAIResponse = async () => {
    setChatLoader(true);
    scrollToBottom();
    if (isEmpty(message)) return;
    setMessages((prev) => [...prev, { message: message, sender: 'user' }]);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_EXPRESS_URL}/api/users/companion_assistance`,
        { message },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: authData.accessToken,
          },
        },
      );
      setMessages((prev) => [...prev, { message: data?.data, sender: 'bot' }]);
    } catch (error) {
      console.log('error=====>', error);
    } finally {
      setMessage('');
      setChatLoader(false);
      scrollToBottom();
    }
  };

  const handlePressEnter = async (e) => {
    if (e.key === 'Enter') {
      getAIResponse();
    }
  };

  const closeModal = () => {
    setOpenAddDevice(false);
  };

  return (
    <>
      <Grid
        container
        item
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.deviceList}
        xs={12}
        sm={12}
        md={12}
        lg={8}
      >
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.deviceListHeader}
        >
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="flex-start"
            className={classes.deviceHeaderTextContainer}
            xs={12}
            lg={12}
            md={12}
          >
            <Grid container xs={12} lg={12} md={12} direction="row">
              <Grid xs={12} lg={9} md={9}>
                <Grid item className={classes.deviceListGreetings}>
                  {t('hello')}, {capitalize(user?.fullName)}!
                </Grid>
                <Grid className={classes.deviceListMessage}>Here you can call or chat with Can Companion</Grid>
              </Grid>
              <Grid xs={12} lg={3} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addNewCaneButton}
                  onClick={() => setOpenAddDevice((prev) => !prev)}
                >
                  <AddIcon className={classes.addIcon} />
                  <span className={classes.addNewCaneText}>{' Add a new cane'}</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={`${classes.deviceList} ${classes.phoneDiv}`} xs={12} sm={12} md={12} lg={12}>
            <div>
              <img src={CallIcon} alt="CallIcon" className={classes.phoneIcon} onClick={handleClickPhone} />
              <p
                style={{ textAlign: 'center', color: 'blue', fontWeight: '500', cursor: 'pointer' }}
                onClick={handleClickPhone}
              >
                {canNum}
              </p>
              <p style={{ textAlign: 'center' }}>Call CAN Companion</p>
            </div>
          </Grid>

          <div className="chatboxdiv">
            <div className="chatbox">
              <div className="chatbox-inner">
                <div className="chat-messages">
                  {map(messages, ({ message, sender }, idx) => {
                    return (
                      <div key={idx}>
                        <p className={sender === 'user' ? 'sender-message' : 'bot-message'}>{message}</p>
                      </div>
                    );
                  })}
                  <div ref={scrollRef} />
                </div>
              </div>

              <div className="input-div">
                <input
                  type="text"
                  className="chat-input"
                  placeholder={chatLoader ? 'Generating response...' : 'Enter input here'}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  onKeyPress={handlePressEnter}
                  value={message}
                />
                {chatLoader ? (
                  <Button variant="outlined" disabled={chatLoader}>
                    <CircularProgress size={20} />
                  </Button>
                ) : (
                  <Button onClick={getAIResponse} variant="outlined" disabled={chatLoader}>
                    Send
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog className={classes.modal} open={openAddDevice} maxWidth={false}>
        <AddDevice user={user} closeModal={closeModal} refresh={refresh} companionUser={true}></AddDevice>
      </Dialog>
    </>
  );
}

export default CompanionScreen;

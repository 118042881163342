import { useRef, useEffect } from "react";
import ReactPlayer from "react-player";
const Video = ({ video }) => {
  const videoEl = useRef(null);

  useEffect(() => {
    if (videoEl) {
      console.log(videoEl.current.getCurrentTime());
    }
  }, [videoEl]);

  return (
    <ReactPlayer
      width="100%"
      ref={videoEl}
      controls
      url={`https://vimeo.com/${video?.id}/${video?.hash}`}
    />
  );
};

export default Video;

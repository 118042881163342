import React, { useEffect, useState } from 'react';
import _, { isEmpty } from 'lodash';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import StripeCardForm from './StripeCardForm';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  InputAdornment,
  IconButton,
  FormLabel,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from '@material-ui/core/Select';
// import userService from '../user.service';
import styles from './signUp.styles';
import PanelWithImage from './PanelWithImage';
import FinalMessage from './FinalMessage';
import CheckPin from './CheckPin';
import CaregiverSignUp from './CaregiverSignUp';
import AddYourselfAsEmergencyContact from '../shared-components/AddYourselfAsEmergencyContact';
import inputValidation from '../input-validation.service.js';
import logo from '../images/logo.png';
import axios from 'axios';
import SignUpGuideModal from './SignUpGuideModal';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import SignUpSelect from './SignUpSelect';
import SignUpWithoutDevice from './SignUpWithoutDevice';

const useStyles = makeStyles(styles);

function SignUp(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const urlParams = queryString.parse(props.location.searchπ);
  let signUpType = 'user';
  let invitationToken = null;
  let caregiverFirstName = null;
  let caregiverLastName = null;
  let caregiverPhoneNumber = null;
  let caregiverEmail = null;

  const isPROD = process.env.REACT_APP_Amplify_env === 'PRODUCTION';
  console.log('process.env.Amplify_env:', `${process.env.REACT_APP_Amplify_env}`);
  console.log('isPROD:', isPROD);

  if (urlParams.type === 'caregiver') {
    signUpType = 'caregiver';
    invitationToken = urlParams.token;
    caregiverFirstName = urlParams.firstName;
    caregiverLastName = urlParams.lastName;
    caregiverPhoneNumber = urlParams.phoneNumber;
    caregiverEmail = urlParams.email;
  }

  const steps = {
    user: ['pin', 'mainInfo', 'emergencyContact', 'additionalInfo', 'creditCardInfo', 'congratulation'],
    caregiver: ['mainInfo', 'congratulation'],
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [state, setState] = useState({
    phonenumber: caregiverPhoneNumber || '',
    caneReason: '',
    email: caregiverEmail || '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    firstName: caregiverFirstName || '',
    lastName: caregiverLastName || '',
    pin: '',
    errorMessage: '',
    emergencyContactPhoneNumber: '',
    emergencyContactFirstName: '',
    emergencyContactLastName: '',
    emergencyContactEmail: '',
    userInfoAge: '',
    userInfoWeight: '',
    userInfoHeight: '',
    userInfoGender: '',
    showAddYourselfAsEmergencyContact: false,
    step: 0,
    signUpType: signUpType,
    buttonDisabled: true,
  });
  const [guideModal, setGuideModal] = useState(true);

  useEffect(() => {
    if (!isEmpty(sessionStorage['signUpGuide'])) {
      setGuideModal(false);
    }
  }, []);

  const createCard = async (email, token, devicePin) => {
    try {
      const result = await axios.post(process.env.REACT_APP_EXPRESS_URL + '/api/users/check-card', {
        email,
        token,
        devicePin,
      });
      return result;
    } catch (err) {
      setState((prevState) => {
        return {
          ...prevState,
          errorMessage: 'Please check card Expiration or Card number',
        };
      });
    }
  };
  async function signUp(userInfo) {
    if (signUpType === 'user') {
      let result = await createCard(userInfo.email, userInfo.stripeInfo.cardToken, userInfo.pin);

      if (!result) {
        return;
      }
      if (!result?.data?.results?.success) {
        return setState((prevState) => {
          return { ...prevState, errorMessage: 'Credit card info not valid' };
        });
      } else {
        userInfo.stripeInfo = result?.data?.results?.data;
      }
    }

    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...(signUpType == 'user' && {
          stripeInfo: userInfo.stripeInfo,
        }),
        canReason: userInfo.caneReason,
        username: userInfo.phonenumber,
        email: userInfo.email,
        password: userInfo.password,
        devicePIN: userInfo.pin,
        invitationToken,
        emergencyContact: userInfo.emergencyContactPhoneNumber,
        invitedCaregiverInfo: userInfo?.emergencyContactEmail
          ? {
              firstName: userInfo.emergencyContactFirstName,
              lastName: userInfo.emergencyContactLastName,
              email: userInfo.emergencyContactEmail,
              phoneNumber: userInfo.emergencyContactPhoneNumber,
            }
          : {},
        emergencyContactInfo: {
          firstName: userInfo.emergencyContactFirstName,
          lastName: userInfo.emergencyContactLastName,
          email: userInfo?.emergencyContactEmail ? userInfo?.emergencyContactEmail : null,
          phoneNumber: userInfo.emergencyContactPhoneNumber,
          device: {
            pin: userInfo.pin,
          },
        },
        userCaregivers: userInfo?.emergencyContactEmail
          ? [
              {
                firstName: userInfo.emergencyContactFirstName,
                lastName: userInfo.emergencyContactLastName,
                email: userInfo.emergencyContactEmail,
                phoneNumber: userInfo.emergencyContactPhoneNumber,
                device: {
                  pin: userInfo.pin,
                },
              },
            ]
          : [],
        userInfo: {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          gender: userInfo.userInfoGender,
          weight: userInfo.userInfoWeight,
          height: userInfo.userInfoHeight,
          age: userInfo.userInfoAge,
        },
        //
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error && data.error.statusCode === 500 && data.message === 'Device already belong to other user') {
          setState((prevState) => {
            return { ...prevState, errorMessage: data.error.message };
          });
        } else if (
          data.error &&
          data.error.statusCode === 422 &&
          (data.error.message.indexOf('Email already exists') !== -1 ||
            data.error.message.indexOf('User already exists') !== -1)
        ) {
          setState((prevState) => {
            return {
              ...prevState,
              errorMessage: 'This email or phone number is associated with an account. Please sign in.',
            };
          });
        } else if (data.error) {
          setState((prevState) => {
            return { ...prevState, errorMessage: 'Something went wrong...' };
          });
        } else {
          if (urlParams.type !== 'caregiver') {
            fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/login`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: state.email,
                password: state.password,
              }),
            })
              .then((res) => res.json())
              .then(({ data }) => {
                if (data.user && userInfo?.emergencyContactEmail) {
                  // userService.updateAuthData({
                  //     accessToken: data.id,
                  //     currentUserId: data.userId,
                  //     // rememberMe: this.state.rememberMe,
                  // });

                  // userService.updateStorage({
                  //     accessToken: data.id,
                  //     currentUserId: data.userId,
                  //     // rememberMe: this.state.rememberMe,
                  // });

                  // userService.updateCurrentUser(data.user);

                  fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${data.userId}/sendCaregiverInvitation`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      authorization: data.id + '~11201021',
                    },
                    body: JSON.stringify({
                      email: state.emergencyContactEmail,
                      deviceId: data.user.userTo[0].deviceId,
                      firstName: state.emergencyContactFirstName || '',
                      lastName: state.emergencyContactLastName || '',
                      phoneNumber: state.emergencyContactPhoneNumber,
                    }),
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (signUpType == 'user') {
            const userData = data.data;

            fetch(
              `${process.env.REACT_APP_EXPRESS_URL}/api/devices/${userData.userTo[0].deviceId || ''}/assignPhoneNumber`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            ).catch(console.log);

            fetch(
              `${process.env.REACT_APP_EXPRESS_URL}/api/devices/${userData.userTo[0].deviceId || ''}/welcomeMessage`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            ).catch(console.log);

            goToNextStep();
          } else {
            alert('registration complete');
            props.history.push('/login');
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const updateStripeInfo = async (email) => {
    console.log('Email', email);
    try {
      const result = await axios.post(process.env.REACT_APP_EXPRESS_URL + '/api/users/update-Stripe-info', {
        email,
      });
      return result;
      console.log(result);
    } catch (exception) {
      console.log('Exception');
    }
  };

  function checkPin() {
    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/devices/check-pin/${state.pin}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          setState((prevState) => {
            return {
              ...prevState,
              errorMessage: 'Incorrect PIN or Invitation Token',
            };
          });
        } else {
          goToNextStep();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function goToEmergencyContactForm() {
    if (!state.phonenumber || !state.email || !state.password) {
      setState((prevState) => {
        return {
          ...prevState,
          errorMessage: 'Please, fill all required fields',
        };
      });
    } else if (state.password !== state.confirmPassword) {
      return setState((prevState) => {
        return {
          ...prevState,
          errorMessage: 'The password confirmation does not match',
        };
      });
    } else if (inputValidation.checkPasswordValidation(state.password) != null) {
      return setState((prevState) => {
        return {
          ...prevState,
          errorMessage:
            'Password must be of 8-32 characters and must contain at least one capital letter, one small letter, a symbol and a number',
        };
      });
    } else if (state.email.toLowerCase() !== state.confirmEmail.toLowerCase()) {
      return setState((prevState) => {
        return {
          ...prevState,
          errorMessage: 'The Email confirmation does not match',
        };
      });
    } else if (!inputValidation.email(state.email)) {
      return setState((prevState) => {
        return { ...prevState, errorMessage: 'Invalid Email' };
      });
    } else {
      goToNextStep();
    }
  }

  function goToNextStep() {
    setState((prevState) => {
      // go one step forward if this step is not last
      const step = prevState.step === steps[state.signUpType].length - 1 ? prevState.step : (prevState.step += 1);
      return { ...prevState, errorMessage: '', step };
    });
  }

  function goToPreviousStep() {
    setState((prevState) => {
      // go one step back if this step is not first
      const step = prevState.step === 0 ? prevState.step : (prevState.step -= 1);
      return { ...prevState, errorMessage: '', step };
    });
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [signUpSelect, setSignUpSelect] = useState(null);

  const handleSelectSignUp = (selectedValue) => {
    setSignUpSelect(selectedValue);
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    setSignUpSelect(null);
  };

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <PanelWithImage />
      <Grid item className={classes.rightPanelContainer} xs={12} md={6}>
        <div className={classes.rightPanel}>
          <div className={classes.header}>
            <div>
              <img className={classes.logo} src={logo} alt="CAN logo" />
            </div>
          </div>
          {/* Device pin form */}
          {steps[state.signUpType][state.step] === 'pin' && state.signUpType === 'user' && (
            <div>
              <div className={classes.stepText}>{`${t('step')} ${state.step + 1}/${
                steps[state.signUpType].length - 1
              }`}</div>
              <CheckPin
                checkPin={checkPin}
                pin={state.pin}
                buttonDisabled={state.buttonDisabled}
                onChange={(event) => {
                  const value = event.target.value;
                  var _buttonDisabled = true;
                  if (value.trim().length !== 0) {
                    _buttonDisabled = false;
                  }

                  setState((prevState) => {
                    return {
                      ...prevState,
                      pin: value,
                      buttonDisabled: _buttonDisabled,
                    };
                  });
                }}
                errorMessage={state.errorMessage}
              />
            </div>
          )}
          {/* Main sign up form*/}
          {steps[state.signUpType][state.step] === 'mainInfo' && state.signUpType === 'user' && (
            <>
              <h2>
                <Link href="/login">{t('already_have_account')}</Link>
              </h2>
              <form
                className={classes.form}
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  goToEmergencyContactForm();
                }}
              >
                <div className={classes.stepText}>{`${t('step')} ${state.step + 1}/${
                  steps[state.signUpType].length - 1
                }`}</div>
                <Typography className={classes.mainText} component="h1" variant="h4">
                  {t('enter_info_to_create_account')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth required>
                      <InputLabel htmlFor="my-input">Phone Number</InputLabel>
                      <OutlinedInput
                        id="phone"
                        inputComponent={PhoneInput}
                        name="phone"
                        value={state.phonenumber}
                        autoComplete="phone"
                        inputProps={{
                          placeholder: '(650) 123-1234',
                          defaultCountry: 'US',
                          // flagUrl: "https://flag.pk/flags/4x3/{xx}.svg",
                          addInternationalOption: false,
                          limitMaxLength: true,
                          style: {
                            display: 'inline-flex',
                            background: 'white',
                          },
                          numberInputProps: {
                            className: classes.phoneNumberInput,
                          },
                          onChange: (value) => {
                            return setState((prevState) => {
                              return { ...prevState, phonenumber: value };
                            });
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label={t('email')}
                      name="email"
                      autoComplete="email"
                      InputProps={{
                        className: classes.input,
                      }}
                      value={state.email}
                      onChange={(event) => {
                        const value = event.target.value;
                        return setState((prevState) => {
                          return { ...prevState, email: value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label={t('confirm_email')}
                      name="email"
                      InputProps={{
                        className: classes.input,
                      }}
                      value={state.confirmEmail}
                      onChange={(event) => {
                        const value = event.target.value;
                        return setState((prevState) => {
                          return { ...prevState, confirmEmail: value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label={t('reason_of_can')}
                      required
                      fullWidth
                      id="caneReason"
                      select
                      name="caneReason"
                      style={{ textAlign: 'left' }}
                      InputProps={{
                        className: classes.input,
                      }}
                      value={state.caneReason}
                      onChange={(event) => {
                        const value = event.target.value;
                        return setState((prevState) => {
                          return { ...prevState, caneReason: value };
                        });
                      }}
                    >
                      <MenuItem value={'Fall Prevention'}>Fall Prevention</MenuItem>
                      <MenuItem value={'Manage Chronic Condition'}>Manage Chronic Condition</MenuItem>
                      <MenuItem value={'Recovery or Rehab'}>Recovery or Rehab</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">{t('password')}</InputLabel>
                      <OutlinedInput
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        style={{ background: '#fff' }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label={t('password')}
                        value={state.password}
                        onChange={(event) => {
                          const value = event.target.value;
                          return setState((prevState) => {
                            return { ...prevState, password: value };
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">{t('confirm_password')}</InputLabel>
                      <OutlinedInput
                        id="password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        style={{ background: '#fff' }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowConfirmPassword((prev) => !prev)}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Confirm Password"
                        value={state.confirmPassword}
                        onChange={(event) => {
                          const value = event.target.value;
                          return setState((prevState) => {
                            return { ...prevState, confirmPassword: value };
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Typography className={classes.errorMessage} component="h1" variant="subtitle1">
                  {state.errorMessage}
                </Typography>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item>
                    <Link className={classes.goBackButton} variant="body2" onClick={goToPreviousStep}>
                      {t('go_back')}
                    </Link>
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => {
                      if (state.phonenumber && state.phonenumber.length < 10) {
                        return setState((prevState) => {
                          return {
                            ...prevState,
                            errorMessage: 'Please enter 10 digits phone number',
                          };
                        });
                      } else {
                        return goToEmergencyContactForm();
                      }
                    }}
                  >
                    {t('next_step')}
                  </Button>
                </Grid>
              </form>
            </>
          )}
          {/* Caregiver sign-up*/}
          {steps[state.signUpType][state.step] === 'mainInfo' && state.signUpType === 'caregiver' && (
            <CaregiverSignUp signUp={signUp} />
          )}
          {/* Add Emergency Contact form */}
          {steps[state.signUpType][state.step] === 'emergencyContact' && state.signUpType === 'user' && (
            <form
              className={classes.form}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                goToNextStep();
              }}
            >
              <div className={classes.stepText}>{`${t('step')} ${state.step + 1}/${
                steps[state.signUpType].length - 1
              }`}</div>
              <Typography className={classes.mainText} component="h1" variant="h4">
                {t('setup_emergency_contact')}
              </Typography>
              <Typography component="h1" variant="subtitle1" className={classes.emergencyContactExplanationText}>
                {t('setup_emergency_contact_para')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="emergencyContactFirstName"
                    id="emergencyContactFirstName"
                    label={t('first_name')}
                    autoFocus
                    InputProps={{
                      className: classes.input,
                    }}
                    value={state.emergencyContactFirstName}
                    onChange={(event) => inputValidation.name(event, 'emergencyContactFirstName', setState)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="emergencyContactLastName"
                    label={t('last_name')}
                    name="emergencyContactLastName"
                    InputProps={{
                      className: classes.input,
                    }}
                    value={state.emergencyContactLastName}
                    onChange={(event) => inputValidation.name(event, 'emergencyContactLastName', setState)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel htmlFor="my-input">Phone Number</InputLabel>
                    <OutlinedInput
                      id="phone"
                      inputComponent={PhoneInput}
                      name="phone"
                      value={state.emergencyContactPhoneNumber}
                      autoComplete="phone"
                      inputProps={{
                        placeholder: '(650) 123-1234',
                        defaultCountry: 'US',
                        // flagUrl: "https://flag.pk/flags/4x3/{xx}.svg",
                        limitMaxLength: true,
                        addInternationalOption: false,
                        style: {
                          display: 'inline-flex',
                          background: 'white',
                        },
                        numberInputProps: {
                          className: classes.phoneNumberInput,
                        },
                        onChange: (value) => {
                          return setState((prevState) => {
                            return {
                              ...prevState,
                              emergencyContactPhoneNumber: value,
                            };
                          });
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="emergencyContactEmail"
                    label={t('email')}
                    name="emergencyContactEmail"
                    autoComplete="email"
                    InputProps={{
                      className: classes.input,
                    }}
                    value={state.emergencyContactEmail}
                    onChange={(event) => {
                      const value = event.target.value;
                      return setState((prevState) => {
                        return { ...prevState, emergencyContactEmail: value };
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Typography className={classes.errorMessage} component="h1" variant="subtitle1">
                {state.errorMessage}
              </Typography>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                  <Link className={classes.goBackButton} variant="body2" onClick={goToPreviousStep}>
                    {t('go_back')}
                  </Link>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => {
                    if (state.phonenumber === state.emergencyContactPhoneNumber) {
                      return setState((prevState) => {
                        return {
                          ...prevState,
                          showAddYourselfAsEmergencyContact: true,
                        };
                      });
                    }

                    return goToNextStep();
                  }}
                >
                  {t('next_step')}
                </Button>
              </Grid>
              <div className={`${classes.termsAndConditionsFooter} ${classes.grayText}`}>{t('notify_user_para')}</div>
              <Dialog
                className={classes.modal}
                open={state.showAddYourselfAsEmergencyContact}
                fullWidth={false}
                maxWidth={false}
              >
                <AddYourselfAsEmergencyContact
                  action={() => {
                    goToNextStep();

                    return Promise.resolve();
                  }}
                  closeModal={() =>
                    setState((prevState) => {
                      return {
                        ...prevState,
                        showAddYourselfAsEmergencyContact: false,
                      };
                    })
                  }
                />
              </Dialog>
            </form>
          )}
          {/* User Info form*/}
          {steps[state.signUpType][state.step] === 'additionalInfo' && state.signUpType === 'user' && (
            <form
              className={classes.form}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                signUp(state);
              }}
            >
              <div className={classes.stepText}>{`${t('step')} ${state.step + 1}/${
                steps[state.signUpType].length - 1
              }`}</div>
              <Typography className={classes.mainText} component="h1" variant="h4">
                {t('enter_can_user_info')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    id="firstName"
                    label={t('first_name')}
                    autoFocus
                    InputProps={{
                      className: classes.input,
                    }}
                    value={state.firstName}
                    onChange={(event) => inputValidation.name(event, 'firstName', setState)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    id="lastName"
                    label={t('last_name')}
                    name="lastName"
                    autoComplete="lname"
                    InputProps={{
                      className: classes.input,
                    }}
                    value={state.lastName}
                    onChange={(event) => inputValidation.name(event, 'lastName', setState)}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'grid' }}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{ textAlign: 'left', fontSize: '17px', marginBlock: '10px' }}
                  >
                    {t('gender')}
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={state.userInfoGender}
                    onChange={(event) => {
                      const value = event.target.value;
                      return setState((prevState) => {
                        return { ...prevState, userInfoGender: value };
                      });
                    }}
                  >
                    <FormControlLabel value="male" control={<Radio />} label={t('male')} />
                    <FormControlLabel value="female" control={<Radio />} label={t('female')} />
                    <FormControlLabel value="other" control={<Radio />} label={t('others')} />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.fromControlWidth} ${classes.input}`}
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">{t('height')}</InputLabel>
                    <Select
                      native
                      fullWidth
                      value={state.userInfoHeight}
                      onChange={(event) => {
                        const value = event.target.value;
                        return setState((prevState) => {
                          return { ...prevState, userInfoHeight: value };
                        });
                      }}
                      inputProps={{
                        name: 'height',
                      }}
                    >
                      <option value="" />
                      {_.map([3, 4, 5, 6], (feet) => {
                        return _.range(0, 12).map((value) => (
                          <option key={value} value={`${feet}' ${value}"`}>{`${feet}' ${value}"`}</option>
                        ));
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={`${classes.formControl} ${classes.fromControlWidth} ${classes.input}`}
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">{t('weight')}</InputLabel>
                    <Select
                      native
                      fullWidth
                      value={state.userInfoWeight}
                      onChange={(event) => {
                        const value = event.target.value;
                        return setState((prevState) => {
                          return { ...prevState, userInfoWeight: value };
                        });
                      }}
                      inputProps={{
                        name: 'weight',
                      }}
                    >
                      <option value="" />
                      {_.range(50, 500).map((value) => (
                        <option key={value} value={`${value} lb`}>
                          {value} lb
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="age"
                    label={t('age')}
                    name="age"
                    autoComplete="age"
                    InputProps={{
                      className: classes.input,
                    }}
                    value={state.userInfoAge}
                    onChange={(event) => {
                      const onlyNumbers = event.target.value.replace(/[^+0-9]/g, '');
                      return setState((prevState) => {
                        return { ...prevState, userInfoAge: onlyNumbers };
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Typography className={classes.errorMessage} component="h1" variant="subtitle1">
                {state.errorMessage}
              </Typography>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                  <Link className={classes.goBackButton} variant="body2" onClick={goToPreviousStep}>
                    {t('go_back')}
                  </Link>
                </Grid>
                <Button variant="contained" color="primary" className={classes.submit} onClick={goToNextStep}>
                  {t('next_step')}
                </Button>
              </Grid>
            </form>
          )}

          {steps[state.signUpType][state.step] === 'creditCardInfo' && (
            <div>
              <StripeCardForm
                signUp={signUp}
                state={state}
                setState={setState}
                goToPreviousStep={goToPreviousStep}
                goToNextStep={goToNextStep}
              />
            </div>
          )}
          {/* Congratulation message */}

          {steps[state.signUpType][state.step] === 'congratulation' && signUpType == 'user' && (
            <div>
              <FinalMessage email={state.email} />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => props.history.push('/login')}
              >
                {t('login_to_account')}
              </Button>
            </div>
          )}
        </div>
      </Grid>

      {/* <Grid item className={classes.rightPanelContainer} xs={12} md={6}>
          <div className={classes.rightPanel}>
            <div className={classes.header}>
              <div>
                <img className={classes.logo} src={logo} alt="CAN logo" />
              </div>
            </div>
            <div>
              <SignUpWithoutDevice handleGoBack={handleGoBack} props={props} />
            </div>
          </div>
        </Grid> */}

      <Dialog className={classes.modal} open={guideModal && !isPROD} maxWidth={false}>
        <SignUpGuideModal closeModal={() => setGuideModal(false)} />
      </Dialog>
    </Grid>
  );
}

export default SignUp;

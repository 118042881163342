import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './editDevice.styles.js';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import AddYourselfAsEmergencyContact from '../shared-components/AddYourselfAsEmergencyContact';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// a comment
const useStyles = makeStyles(styles);

function EditCaregivers({ close, caregiverToEdit, handleEditCaregivers }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [caregiver, setCaregiver] = useState(null);

  const relations = [
    t('father'),
    t('mother'),
    t('sister'),
    t('brother'),
    t('spouse'),
    t('son'),
    t('daughter'),
    t('friend'),
    t('professional_caregiver'),
  ];

  useEffect(() => {
    if (caregiverToEdit) {
      setCaregiver(caregiverToEdit);
    }
  }, [caregiverToEdit]);

  return (
    <div>
      <div className={classes.newCaregiver}>
        <h3>{t('edit_caregiver')}</h3>
        <div
          className={`${classes.deviceInfoExplanationText} ${classes.grayText}`}
          style={{ marginTop: '-10px', marginBottom: '15px' }}
        >
          {t('edit_caregiver_para')}
        </div>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Grid item className={classes.deviceInfoPart}>
            <div className={classes.deviceInfoPartTitle}>{t('caregiver_first_name')}</div>
            <input
              className={classes.deviceInfoInput}
              defaultValue={caregiver?.firstName || ''}
              onChange={(event) =>
                setCaregiver((prev) => {
                  return {
                    ...prev,
                    firstName: event.target.value.trim(),
                  };
                })
              }
            />
          </Grid>
          <Grid item className={classes.deviceInfoPart}>
            <div className={classes.deviceInfoPartTitle}>{t('caregiver_last_name')}</div>
            <input
              className={classes.deviceInfoInput}
              defaultValue={caregiver?.lastName || ''}
              onChange={(event) =>
                setCaregiver((prev) => {
                  return {
                    ...prev,
                    lastName: event.target.value.trim(),
                  };
                })
              }
            />
          </Grid>
        </Grid>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Grid item className={classes.deviceInfoPart}>
            <div className={classes.deviceInfoPartTitle}>{t('caregiver_email')}</div>
            <input className={classes.deviceInfoInput} defaultValue={caregiver?.email || ''} disabled={true} />
          </Grid>
          <Grid item className={classes.deviceInfoPart}>
            <div className={classes.deviceInfoPartTitle}>{t('confirm_email')}</div>
            <input className={classes.deviceInfoInput} defaultValue={caregiver?.email || ''} disabled={true} />
          </Grid>
        </Grid>
        <Grid container item direction="row" justify="space-between" alignItems="center">
          <Grid item className={classes.deviceInfoPart}>
            <div>{t('relation_to_caregiver')}</div>
            <select
              className={classes.deviceInfoInput}
              onChange={(event) =>
                setCaregiver((prev) => {
                  return {
                    ...prev,
                    relation: event.target.value.trim(),
                  };
                })
              }
              value={caregiver?.relation || ''}
            >
              <option value="">-- {t('select_relation')} --</option>
              {relations.map((relation) => (
                <option value={relation}>{relation}</option>
              ))}
            </select>
          </Grid>
          <Grid item className={classes.deviceInfoPart}>
            <div className={classes.deviceInfoPartTitle}>{t('caregiver_phone_number')}</div>

            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="US"
              addInternationalOption={true}
              limitMaxLength={true}
              className={classes.newCaregiverPhoneNumberParent}
              value={caregiver?.phoneNumber || ''}
              numberInputProps={{
                className: `${classes.deviceInfoInput} ${classes.newCaregiverPhoneNumberChild}`,
              }}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.addCaregiverFooter}
        >
          <Grid item>
            <Button fullWidth variant="outlined" className={classes.cancelButton} onClick={close}>
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              className={classes.saveButton}
              style={{ marginInlineStart: '20px' }}
              onClick={() => handleEditCaregivers(caregiver)}
            >
              {t('update')}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default EditCaregivers;

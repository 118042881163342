import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import userService from '../user.service.js';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    paper: {
        background: '#FFFFFF',
        width: '460px',
        padding: '24px 32px 24px 32px',
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
            padding: '14px 16px 14px 16px',
            width: 'auto',
        },
    },
    title: {
        fontSize: '24px',
        fontWeight: 800,
    },
    closeIcon: {
        cursor: 'pointer',
    },

    mainContainer: {
        marginTop: '65px',
        width: '100%',
    },
    mainText: {
        fontSize: '24px',
        fontWeight: 800,
        marginBottom: '8px',
        textAlign: 'center',
    },
    explanationText: {
        width: '450px',
        fontSize: '16px',
        textAlign: 'center',
    },
    imageContainer: {
        width: '250px',
        marginBottom: '30px',
        marginTop: '30px',
    },
    image: {
        width: '100%',
    },

    pinInput: {
        background: '#FFFFFF',
        border:'1px solid #C2C8D6',
        boxSizing: 'border-box',
        height: '50px',
        boxShadow: 'inset 0px 2px 2px rgba(108, 131, 145, 0.19)',
        borderRadius: '2px',
        marginTop: '8px',
        width: '468px',
        fontSize: '16px',
        padding: '16px',
    },
    userInfoPartRadio: {
        marginTop: '8px',
    },

    errorMessage: {
        marginTop: '8px',
        marginBottom: '8px',
        height: '20px',
    },

    footer: {
        marginTop: '40px',
    },
    saveButton: {
        marginLeft: '8px',
    },
    cancelAdditionalText: {
        ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
            display: 'none',
        },
    },

    grayText: {
        color: '#61697D',
    },
});

const useStyles = makeStyles(styles);


function SaveChanges({ action, closeModal, closeParentModal }) {
    const classes = useStyles();
    const [state, setState] = useState({
        errorMessage: '',
    });

    function save() {
        return action()
            .then(() => {
                closeModal();
                closeParentModal();
            });
    }

    function exitWithoutSaving() {
        closeModal();
        closeParentModal();
    }

    return (
        <div className={classes.paper}>
            <Grid
                container
                item
                direction='row'
                justify='space-between'
                alignItems='center'
                className={classes.header}
            >
                <Grid   
                    item 
                    className={classes.title}
                >
                    Save Changes
                </Grid>
                <Grid 
                    item 
                    className={classes.closeIcon}
                    onClick={closeModal}
                >
                    <CloseIcon />
                </Grid>
            </Grid>
            <Grid
                container
                item 
                direction='column'
                justify='flex-start'
                alignItems='center'
                className={classes.mainContainer}
            >
                <Grid
                    item
                    className={classes.userInfoPanel}
                >
                    <Grid item className={classes.mainText}>
                        Do you want to save your changes?
                    </Grid>
                    {/*
                    <Grid item className={`${classes.explanationText} ${classes.grayText}`}>
                        <div>
                            Press the flashlight button for 10 seconds on your smartcane to show the cane PIN number 
                            or check your email for the letter with the PIN number.
                        </div>
                    </Grid>
                    */}
                </Grid>
            </Grid>
            <Grid
                item 
                direction='column'
                justify='flex-start'
                alignItems='center'
                className={classes.errorMessage}
            >
                {state.errorMessage}
            </Grid>
            <Grid
                container
                item
                direction='row'
                justify='flex-end'
                alignItems='center'
                className={classes.footer}
            >
                <Grid item>
                    <Button
                        fullWidth
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={exitWithoutSaving}
                    >
                        Cancel <span className={classes.cancelAdditionalText}> without saving</span>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        className={classes.saveButton}
                        onClick={save}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default SaveChanges
